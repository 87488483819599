<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <top-bars title=""></top-bars>
    <v-main>
      <v-container>
        <v-row no-gutters class="align-center justify-start">
          <v-col cols="12" class="d-flex flex-column align-center">
            <v-row no-gutters class="align-center justify-center">
              <v-col cols="12" class="d-flex align-center justify-center">
                <v-img
                  :src="require('@/assets/lockopen.png')"
                  class="lock"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row no-gutters class="align-center justify-center">
          <v-col cols="12" class="d-flex flex-column align-center">
            <div class="chgTxt mx-4">
              <h3 class="text-center fs-24 lh-36 fw-700 black--text">
                {{$t('account_changepassword_title')}}
              </h3>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex flex-column align-center">
            <div class="text-section mx-4">
            </div>
          </v-col>
        </v-row>

        <!-- Form section -->
        <div class="form-wrapper pa-4">
          <v-form v-model="valid" ref="form">
            <!-- old Password -->
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="oldpassword"
                  :placeholder="$t('account_changepassword_currentpsw_pholder')"
                  :rules="oldpasswordRules"
                  @input="checkPasswordRequirements"
                  outlined
                  class="fs-15 fw-400 rounded-xl"
                  :type="showPassword ? 'text' : 'password'"
                >
                  <!-- Prepend Icon -->
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-lock</v-icon>
                  </template>

                  <!-- Append Icon -->
                  <template v-slot:append>
                    <v-icon @click="togglePasswordVisibility">
                      {{ showPassword ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- New Password -->
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="newpassword"
                  :placeholder="$t('account_changepassword_newpsw_pholder')"
                  :rules="newpasswordRules"
                  outlined
                  class="fs-15 fw-400 rounded-xl"
                  @input="checkPasswordRequirements"
                  @focus="showRequirements = true"
                  :type="showPassword ? 'text' : 'password'"
                >
                  <!-- Prepend Icon -->
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-lock</v-icon>
                  </template>

                  <!-- Append Icon -->
                  <template v-slot:append>
                    <v-icon @click="togglePasswordVisibility">
                      {{ showPassword ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- Password Requirements -->
            <v-row no-gutters :style="{opacity: showRequirements?1:0}">
              <v-col cols="12">
                <p  class="mt-1">
                  <v-icon left :color="meetrequirement1 ? 'green' : '#171A1F'"
                    >mdi-check-circle</v-icon
                  >
                  {{$t('account_changepassword_met1')}}
                </p>
              </v-col>

              <v-col cols="12">
                <p  class="mt-1">
                  <v-icon left :color="meetrequirement2 ? 'green' : '#171A1F'"
                    >mdi-check-circle</v-icon
                  >
                  {{$t('account_changepassword_met2')}}
                </p>
              </v-col>

              <v-col cols="12">
                <p  class="mt-1">
                  <v-icon left :color="meetrequirement3 ? 'green' : '#171A1F'"
                    >mdi-check-circle</v-icon
                  >
                  {{$t('account_changepassword_met3')}}
                </p>
              </v-col>
            </v-row>

            <!-- confirm Button -->
            <v-row no-gutters class="mt-8 mb-4">
              <v-col cols="12">
                <v-btn
                  class="py-6 body-1 w100 white--text rounded-xxl"
                  color="#4b82ed"
                  @click="submit"
                  :loading="loading"
                  :disabled="!allRequirementsMet"
                >
                  {{$t('account_changepassword_confirm_btn')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import TopBars from "@/components/TopBars.vue";
import authApi from "@/api/auth";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: { TopBars },
  data() {
    return {
      valid: false,
      oldpassword: "",
      newpassword: "",
      showPassword: false,
      meetrequirement1: false,
      meetrequirement2: false,
      meetrequirement3: false,
      meetrequirement4: false,
      showRequirements: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    oldpasswordRules() {
   return [
     (v) => !!v || this.$t('account_changepassword_oldpsw_warn')
   ];
 },
 newpasswordRules() {
   return [
     (v) => !!v || this.$t('account_changepassword_newpsw_warn')
   ];
 },
    allRequirementsMet() {
      return (
        this.meetrequirement1 && this.meetrequirement2 && this.meetrequirement3
      );
    },
  },
  methods: {
    ...mapActions({
      logOut: "user/logOut",
    }),
    ...mapMutations(["setSnackbar"]),

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    checkPasswordRequirements() {
      const minLength = 8;
      this.meetrequirement1 = this.newpassword.length >= minLength;
      this.meetrequirement2 = /[0-9]/.test(this.newpassword);
      this.meetrequirement3 =
        /[a-z]/.test(this.newpassword) && /[A-Z]/.test(this.newpassword);
      this.meetrequirement4 = this.oldpassword.length >= minLength;
    },
    async submit() {
      if (!this.user) {
        this.$router.push({ path: "/login" });
        return;
      }

      if (this.allRequirementsMet) {
        let data = {
          old_pwd: this.oldpassword,
          new_pwd: this.newpassword,
        };
        this.loading = true;

        let res = await authApi.changepsw(data);
        this.loading = false;
        if (res) {
          console.log(res);
          const msg = res.data.msg;
          this.setSnackbar({
            snackbarSuccess: true,
            snackbar: true,
            snackbarMsg: msg,
          });
          this.signout();
        }
      }
    },
    async signout() {
      let res = await this.logOut();
      if (res) {
        this.$router.push("/home");
        this.$router.push("/login");
      }
    },
  },
};
</script>


<style scoped lang="scss">
.lock {
  height: 136px;
  width: 136px;
}

.chgTxt {
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10px;
  color: #171a1fff;
}

.form-wrapper {
  background: #f2f3f4ff;
  height: 50vh;
}

p {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.v-text-field__slot {
  border-bottom: 2px solid #4b82ed !important;
}

.v-text-field .v-text-field__prepend {
  margin-right: 0;
}

.v-text-field .v-text-field__append {
  margin-left: 0;
}

.v-text-field__slot {
  border-bottom: 2px solid #4b82ed !important;
}
</style>
