<template>
  <div class="privacy">
    <top-bars :light="false"></top-bars>
    <div class="main">
      <div class="top">
        <div class="left">
          <p class="font1 mb-2">{{$t('policy_privacy_privacy_title')}}</p>
          <p class="font2">{{$t('policy_privacy_policy_title')}}</p>
        </div>
        <div class="right">
          <v-img width="170" :src="require('@/assets/lock1.png')"></v-img>
          <v-img
            width="85"
            :src="require('@/assets/lock2.png')"
            style="position: absolute; top: 48px; left: 42px"
          ></v-img>
        </div>
      </div>
      <div class="content pa-4">
        <div id="box-show" v-html="content"></div>
        <div>
          Sharing Your Information We only share information with your
          consent,tocomply with laws, to provide you with services,to protect
          your rights, or to fulfill business obligations. Data Security We have
          implemented appropriate technicaland organizational security measures
          designedto protect the security of any personalnformation we
          process.However.please alsorememper that we cannot auarantee that
          theinternet itselfis 100% secure. Your Privacy Rights n some regions,
          such as the EuropeanEconomic Area(EEA),you have rights thatallow you
          greater access to and control oveyour personal information. You may
          review,change, or terminate your account at any time Changes to This
          Privacy Policy We may update this privacy notice from time totime in
          order to reflect,for example,chan~our practices or for other
          operational, lerequlatory reasons.Sharing Your Information We only
          share information with your consent,tocomply with laws, to provide you
          with services,to protect your rights, or to fulfill business
          obligations. Data Security We have implemented appropriate
          technicaland organizational security measures designedto protect the
          security of any personalnformation we process.However.please
          alsorememper that we cannot auarantee that theinternet itselfis 100%
          secure. Your Privacy Rights n some regions, such as the
          EuropeanEconomic Area(EEA),you have rights thatallow you greater
          access to and control oveyour personal information. You may
          review,change, or terminate your account at any time Changes to This
          Privacy Policy We may update this privacy notice from time totime in
          order to reflect,for example,chan~our practices or for other
          operational, lerequlatory reasons.Sharing Your Information We only
          share information with your consent,tocomply with laws, to provide you
          with services,to protect your rights, or to fulfill business
          obligations. Data Security We have implemented appropriate
          technicaland organizational security measures designedto protect the
          security of any personalnformation we process.However.please
          alsorememper that we cannot auarantee that theinternet itselfis 100%
          secure. Your Privacy Rights n some regions, such as the
          EuropeanEconomic Area(EEA),you have rights thatallow you greater
          access to and control oveyour personal information. You may
          review,change, or terminate your account at any time Changes to This
          Privacy Policy We may update this privacy notice from time totime in
          order to reflect,for example,chan~our practices or for other
          operational, lerequlatory reasons. Your Privacy Rights n some regions,
          such as the EuropeanEconomic Area(EEA),you have rights thatallow you
          greater access to and control oveyour personal information. You may
          review,change, or terminate your account at any time Changes to This
          Privacy Policy We may update this privacy notice from time totime in
          order to reflect,for example,chan~our practices or for other
          operational, lerequlatory reasons.Sharing Your Information We only
          share information with your consent,tocomply with laws, to provide you
          with services,to protect your rights, or to fulfill business
          obligations. Data Security We have implemented appropriate
          technicaland organizational security measures designedto protect the
          security of any personalnformation we process.However.please
          alsorememper that we cannot auarantee that theinternet itselfis 100%
          secure. Your Privacy Rights n some regions, such as the
          EuropeanEconomic Area(EEA),you have rights thatallow you greater
          access to and control oveyour personal information. You may
          review,change, or terminate your account at any time Changes to This
          Privacy Policy We may update this privacy notice from time totime in
          order to reflect,for example,chan~our practices or for other
          operational, lerequlatory reasons.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBars from "../../components/TopBars.vue";
import settingApi from "@/api/setting";
export default {
  components: { TopBars },
  data() {
    return {
      loading: false,
      content: "",
    };
  },
  async mounted() {
    this.loading = true;
    let res = await settingApi.textSingle({ limit: 2, skip: 0 });
    this.loading = false;
    if (res) {
      res.data.map((item) => {
        if (item._id == "privacy") {
          this.content = item.content;
            this.$nextTick(() => {
            this.checkImg()
          })
        }
      });
    }
    this.$nextTick(() => {
      let height = document.getElementsByClassName("content")[0].clientHeight;
      console.log(height);
      document.getElementsByClassName("main")[0].style.height =
        height + 250 + "px";
    });
  },
  methods: {
    checkImg() {
      this.objImgs = document.getElementById('box-show').getElementsByTagName('img')
      if (this.objImgs) {
        for (let i = 0; i < this.objImgs.length; i++) {
          this.getImgInfo(this.objImgs, this.objImgs[i].src, i)
        }
      }
    },
    getImgInfo(objImgs, url, i) {
      let image = new Image();
      let w;
      image.src = url;
      image.onload = function () {
        w = image.width
        if (window.innerWidth <= w) {
          objImgs[i].style.width = '100%'
        }
        image.onload = null
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy {
  .main {
    position: relative;
    .top {
      background-color: $primary;
      padding-top: 10px;
      height: 200px;
      position: absolute;
      top: 0;
      width: 100%;
      .left {
        font-size: 20px;
        border-top: 1px solid #1639a0ff;
        padding-top: 8px;
        width: 175px;
        padding-left: 40px;
        .font1 {
          color: #fff;
          font-weight: bold;
        }
        .font2 {
          font-family: Epilogue;
          color: #1639a0ff;
        }
      }
      .right {
        position: absolute;
        right: 0;
        top: -44px;
        z-index: 99;
        padding-right: 20px;
      }
    }
    .content {
      position: absolute;
      top: 113px;
      right: 0;
      width: calc(100% - 44px);
      background-color: $background;
      z-index: 99;
      min-height: 60vh;
      .bigfont {
        font-size: 80px;
        color: $primary;
        font-weight: 400;
      }
      .italic {
        font-style: italic;
        border-left: 4px solid $primary;
        padding: 13px 10px;
      }
      .imgbox {
        border-radius: 8px;
        overflow: hidden;
      }
    }
    .homeicon {
      text-align: center;
      background-color: $primary;
      width: 62px;
      color: #fff;
      padding: 10px 0 7px;
      border-top-left-radius: 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      a {
        color: #fff;
      }
    }
  }
}

</style>
