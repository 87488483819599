<template>
  <v-btn
    fab
    class="fab"
    :style="{ bottom, right }"
    @click="onFabClick"
  >
    <i class="fas fa-headset fa-2x fa-fw primary--text fs-24"></i>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      cs: "",
    };
  },
  props: {
    bottom: {
      type: String,
      default: '65px',
    },
    right: {
      type: String,
      default: '16px',
    }
  },
  computed: {
    ...mapGetters({
      settingGlobal: "settingGlobal",
    }),
  },
  watch: {
    settingGlobal(newValue) {
      if (newValue.cs && newValue.cs.link) {
        this.cs = newValue.cs.link;
        console.log('Updated cs link:', this.cs);
      } else {
        console.warn('Customer service link not available');
      }
    }
  },
/*  mounted() {
    if (this.settingGlobal && this.settingGlobal.cs && this.settingGlobal.cs.link) {
      this.cs = this.settingGlobal.cs.link;
      console.log('Customer service link:', this.cs);
    } else {
      console.warn('Customer service link not available');
    }
  },*/
  methods: {
    onFabClick() {
      if (this.cs) {
         window.open(this.cs, '_blank');
      } else {
        console.warn('err');
      }
    },
  },
};
</script>


<style scoped>
.fab {
  position: fixed;
  z-index: 998;
  background-color: white;
  /* border: 1px solid grey;  */
}
</style>
