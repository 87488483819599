<template>
  <div class="offerhelp">
    <profile-card></profile-card>
    <chart ref="childChart"></chart>

    <div class="matchreq mt-5 spbtm" v-if="isMatch == 2">
      <div class="cardprimary primary">
        <p class="mb-5">
          <v-icon class="mr-1" size="18" color="#fff"
            >mdi-checkbox-marked-outline</v-icon
          >
          {{$t('offerhelp_pending_info')}}
        </p>
        <v-divider color="#e0e0e0"></v-divider>
        <div>
          <p class="d-flex mt-5">
            <span>{{$t('offerhelp_prin_txt')}} {{ $formatUS(orderDetail.amount,2) }}</span>
            <v-spacer></v-spacer>
            <span
              >{{$t('offerhelp_dr_txt')}}
              {{
                orderDetail?.rate
                  ? (orderDetail.rate * 100).toFixed(2) + "%"
                  : ""
              }}</span
            >
          </p>
          <p class="d-flex mt-1">
            <span>
              Total interest
              {{ orderDetail?.er && !isNaN(Number(orderDetail.er)) ? $formatUS(orderDetail.er) : "" }}
            </span>
            <v-spacer></v-spacer>
            <span></span>
          </p>
          <p class="d-flex mt-1">
            <span>{{$t('offerhelp_conf_txt')}}</span>
            <v-spacer></v-spacer>
            <span>{{ $DateUS(confirmDate) }}</span>
          </p>
          <p class="d-flex mt-1">
            <span>Order Ref.</span>
            <v-spacer></v-spacer>
            <span class="text-ellipsis" style="width: 200px">{{ getId }} </span>
            <v-icon
              class="ml-1"
              size="16"
              color="#fff"
              @click="copyText($event, getId)"
              >mdi-content-copy</v-icon
            >
          </p>

          <p
          class="text-center mb-0 mt-4">
          <v-btn
            :loading="cancelLoading"
            outlined
            block
            color="#fff"
            @click="toggleCancelMatch"
          >
            {{$t('offerhelp_cancelmm_btn')}}
          </v-btn>
          </p>
        </div>
      </div>
    </div>

    <div class="bottomBox spbtm" v-if="isMatch == 1">
      <div class="text">
        <p class="d-flex body-2">
          <span>{{$t('offerhelp_trms_txt')}}</span>
          <v-spacer></v-spacer>
          <span>{{ inteDurationText }}</span>
        </p>
        <p class="d-flex fs-12">
          <span>{{$t('offerhelp_min_txt')}} ${{ settingGlobal?.inte?.min ?? "" }}</span>

          <v-spacer></v-spacer>
          <span>
            {{$t('offerhelp_dintrate')}}:
            {{
              settingGlobal?.inte?.rate
                ? (settingGlobal.inte.rate * 100).toFixed(2) + "%"
                : ""
            }}
          </span>
        </p>
      </div>
      <v-select
        :disabled="!!user?.wallet?.chain"
        size="small"
        filled
        class="filledSelect noborder mt-5"
        v-model="select"
        :items="items"
        label=""
        item-text="state"
        item-value="abbr"
        @change="selectChange"
      ></v-select>
      <v-text-field
        class="filledInput"
        label=""
        v-model="inputValue"
        :placeholder="$t('offerhelp_amnt_field')"
        hide-details="auto"
      ></v-text-field>
      <p class="annotation mt-1" :class="inputValue % 10 == 0 ? '' : 'errorf'">
        {{$t('offerhelp_amnt_help')}}
      </p>
      <v-btn :loading="loading" class="filledBtn" size="small" @click="submit">
        {{$t('offerhelp_confirm_btn')}}
      </v-btn>
    </div>
    <!-- page loading -->
    <div class="pageloadingBtm" v-show="pageloading" :style="{ to: '340px' }">
      <v-progress-circular indeterminate color="#4069E5"></v-progress-circular>
    </div>

    <!-- Match -->
    <div class="match" v-if="showCancelOverlay" @click="toggleCancelMatch">
      <div class="cancelbox" @click.stop.prevent>
        <div class="cardprimary cancelOverlay">
          <v-container fluid fill-height>
            <v-row align="center" justify="center" class="text-center">
              <v-col cols="12" class="d-flex justify-end ptr">
                <v-icon color="black" size="24" @click="toggleCancelMatch">
                  mdi-close
                </v-icon>
              </v-col>

              <v-col cols="12">
                <p style="color: #000">
                  {{$t('offerhelp_canceldg_title')}}
                </p>
              </v-col>

              <v-col cols="8" class="mb-0 mt-4">
                <v-btn
                  :loading="cancelLoading"
                  outlined
                  class="rounded-xxl"
                  block
                  @click="cancleMatch"
                >
                  {{$t('offerhelp_dgyes_btn')}}
                </v-btn>
              </v-col>

              <v-col cols="8" class="mb-0 mt-1">
                <v-btn
                  :loading="cancelLoading"
                  class="rounded-xxl"
                  color="primary"
                  block
                  @click="toggleCancelMatch"
                >
                  {{$t('offerhelp_dgno_btn')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <!--<bottom-bars type="light"></bottom-bars>-->
    </div>
    <bottom-bars type="white" v-if="isMatch == 2"></bottom-bars>
  </div>
</template>

<script>
import ProfileCard from "@/components/ProfileCard.vue";
import TopBars from "@/components/TopBars.vue";
import Chart from "@/components/OfferHelp/Chart.vue";
import orderApi from "@/api/order";
import { mapMutations, mapGetters, mapActions } from "vuex";
import BottomBars from "../../components/BottomBars.vue";
import { copyText } from "@/utils/index.js";
export default {
  components: { TopBars, ProfileCard, Chart, BottomBars },
  data() {
    return {
      orderType: "deposit",
      loading: false,
      inputValue: "",
      minOffer: 100,
      duration: "ten",
      select: "TRC-20",
      items: [
        { state: "USDT-TRC20", abbr: "TRC-20" },
        { state: "USDT-ERC20", abbr: "ERC-20" },
      ],
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value % 10 == 0) || "",
      ],
      orderDetail: null,
      isMatch: 0,
      matchCancel: false,
      showCancelOverlay: false,
      cancelLoading: false,
      getId: "",
      confirmDate: "",
      pageloading: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      settingGlobal: "settingGlobal",
    }),
    isAmountValid() {
      return this.inputValue && this.inputValue % 10 === 0;
    },

    inteDurationText() {
    const duration = this.settingGlobal?.inte?.duration;
    if (duration) {
      const durationText = duration > 1 ? this.$t('offerhelp_days_txt') : this.$t('offerhelp_day_txt');
      return `${duration} ${durationText}`;
    }
    return "";
  },

  },
  watch: {
    async user(n,old) {
      this.select = this.user?.wallet?.chain || this.items[0].abbr;
      if (!old && n._id) {
        await this.checkOrderStatus();
      } else {
        this.pageloading = false;
        this.isMatch = 1;
      }
    },
  },
  async mounted() {
    this.select = this.user?.wallet?.chain || this.items[0].abbr;
  
    this.$nextTick(() => {
      if (this.user) {
         this.checkOrderStatus();
      }
    });
  },

  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions({
      addWallet: "user/addWallet",
    }),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    selectChange(v) {
      console.log(v);
    },
    async submit() {
      if (this.inputValue < this.settingGlobal.inte.min) {
        this.notiFy(false, this.$t('offerhelp_amnt_warn') +
        " " +
        this.settingGlobal.inte.min);
        return;
      }
      if (!this.user) {
        this.$router.push({ path: "/login" });
        return;
      }
      let data = {
        chain: this.select,
        amount: this.inputValue,
      };
      this.loading = true;
      try {
        let res = null;
        if (!this.user.wallet || !this.user.wallet.chain) {
          let ress = await this.addWallet({ chain: this.select });
          if (ress) {
            res = await orderApi.depositOrder(data);
            this.$refs.childChart.getData();
          }
        } else {
          res = await orderApi.depositOrder(data);
          this.$refs.childChart.getData();
        }

        if (res) {
          console.log("deposit respond:", res);
          this.getId = res.data._id;
          let apiStatus = res.data.status;
          this.confirmDate = res.data.create_time;
          await this.checkOrderStatus(true);

        }
      } finally {
        this.loading = false;
      }
    },
    async checkOrderStatus(pageloading) {
      this.pageloading = !pageloading;
      console.log('000000.',this.pageloading)
      let resOD = await orderApi.orderOngoing();
      this.pageloading = false;
      if (resOD && resOD?.data) {
        this.orderDetail = resOD.data;
        console.log("Order details:", this.orderDetail);
        if (this.orderDetail.status === "pending" || this.orderDetail.status === "passed") {
          this.isMatch = 2;
          this.getId = this.orderDetail._id;
          this.inputValue = this.orderDetail.amount;
          let apiStatus = this.orderDetail.status;
          this.confirmDate = this.orderDetail.create_time;
        } else if (this.orderDetail.status === "matched") {

          this.$router.push({
            path: "deposit",
            query: { ...this.orderDetail },
          });
          //  this.$router.push({ path: "deposit" });
        } else {
          this.isMatch = 1;
          console.log("No order found or status does not match.");
        }
      } else {
        this.isMatch = 1;
      }
    },
    toggleCancelMatch() {
      this.showCancelOverlay = !this.showCancelOverlay;
      console.log('matchmaking can be cancel..');
    },
    async cancleMatch() {
      try {
        let res = await orderApi.depositRevoke({ _id: this.orderDetail._id });
        if (res) {
          this.$refs.childChart.getData();
          this.isMatch = 1;
          this.inputValue = "";
          this.notiFy(true, this.$t('offerhelp_order_cancel_toast'));
        }
      } finally {
        this.showCancelOverlay = false;
      }
    },
    copyText(e, text) {
      copyText(e, text);
    },
  },
};
</script>

<style lang="scss" scoped>
.offerhelp {
  background-color: $background;
  min-height: calc(100vh + 50px);
  .bottomBox {
    padding: 20px 0;
    p {
      &:nth-child(1) {
        border-bottom: 1px solid #1d2128ff;
        padding-bottom: 2px;
        margin-bottom: 5px;
      }
    }
  }
  .match {
    width: 100vw;
    height: 100vh;
    background-color: #171a1fd1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    p {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
    }
    .text {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      line-height: 30px;
      .cancel {
        color: $grey-icon-color;
        margin-top: 100px;
      }
    }
    .cancelbox,
    .matchreq {
      width: 85%;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      .cancelOverlay {
        background-color: #fff;
      }
      p {
        padding-bottom: 5px;
      }
    }
  }
}
</style>
