import store from "@/store";
import Clipboard from "clipboard";


export function tansParams(params) {
  let result = '';
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result;
}

export function copyText(event, text) {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  
  clipboard.on('success', () => {
    store.commit('setSnackbar', {
      snackbarSuccess: true,
      snackbarMsg: 'Copied successfully',
      snackbar: true
    });
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    store.commit('setSnackbar', {
      snackbarSuccess: false,
      snackbarMsg: 'Copied fail',
      snackbar: true
    });
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
export function getRandomInt(min, max) {
  return (Math.random() * (max - min + 1) + min).toFixed(0);
}

export function getLocalTime(i,time) {
  console.log(i,time)
5

  if (typeof i !== 'number') return;
  var d = new Date(time);
  var len = d.getTime();
  var offset = d.getTimezoneOffset() * 60000;
  var utcTime = len + offset;
   return new Date(utcTime + 3600000 * i);
  

}
