import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#4069E5',
        background: '#F2F3F4',
        greyBackground: '#f3f4f6',
        greyIcon:'#9095A0',
        secondary: '#eeeeee',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        barBackground: '#f5f5f5',
        countd_skyblue: '#87CEEB',
        font: {
          family: 'Inter, sans-serif',
        },
      },
    },
  },
});
