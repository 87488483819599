import { post } from '@/utils/request.js';

const apiUrl = '/user';

const addWallet = async (data) => post({
    url: apiUrl, data: {
        cmd: 'add_wallet',
        params: data
    }
});

const userWallet = async () => post({
    url: apiUrl, data: {
        cmd: 'user_wallet'
    }
});

const updateProfile = async (data) => post({
    url: apiUrl, data: {
        cmd: 'update_profile',
        params: data
    }
});

const messageQuery = async (data) => post({
    url: apiUrl, data: {
        cmd: 'message_query',
        params: data
    }
});

const messageRead = async (data) => post({
    url: apiUrl, data: {
        cmd: 'message_read',
        params: data
    }
});
const messageDel = async (data) => post({
    url: apiUrl, data: {
        cmd: 'message_del',
        params: data
    }
});
const messageUnreadCount = async () => post({
    url: apiUrl, data: {
        cmd: 'message_unread_count'
    }
});

const interestRanking = async () => post({
    url: apiUrl, data: {
        cmd: 'interest_ranking'
    }
});
const balanceDetails = async (data) => post({
    url: apiUrl, data: {
        cmd: 'balance_details',
        params: data
    }
});

const changeTz = async (data) => post({
    url: apiUrl, data: {
        cmd: 'change_tz',
        params: data
    }
});

const changeLang = async (data) => post({
    url: apiUrl, data: {
        cmd: 'change_lang',
        params: data
    }
});

const totalCommission = async () => post({
    url: apiUrl, data: {
        cmd: 'total_commission'
    }
});


const statCommission = async () => post({
    url: apiUrl, data: {
        cmd: 'stat_commission'
    }
});

export default { addWallet, userWallet, updateProfile,messageQuery,messageRead,messageDel, messageUnreadCount,interestRanking,balanceDetails,changeTz,changeLang,totalCommission,statCommission};
