<template>
  <div class="intro">
    <top-bars :title="$t('policy_intro_intro_title')"></top-bars>
    <div class="main pa-4">
      <div  id="box-show" class="content mgt-10" v-html="content"></div>
      <div
          class="pageloadingBtm"
          v-show="loading"
          :style="{
          bottom: '60%',
        }"
      >
        <v-progress-circular
            indeterminate
            color="#4069E5"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TopBars from "../../components/TopBars.vue";
import settingApi from "@/api/setting";
import Nodata from "../../components/Nodata.vue";

export default {
  components: {TopBars, Nodata},
  data() {
    return {
      show: false,
      content: "",
      loading: false,
      objImgs: undefined,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  async mounted() {
    this.loading = true;
    let res = await settingApi.textSingle({limit: 2, skip: 0});
    this.loading = false;
    if (res) {
      res.data.map((item) => {
        if (item._id == "intro") {
          this.content = item.content;
          this.$nextTick(() => {
            this.checkImg()
          })
        }
      });
    }
  },
  methods: {
    checkImg() {
      this.objImgs = document.getElementById('box-show').getElementsByTagName('img')
      if (this.objImgs) {
        for (let i = 0; i < this.objImgs.length; i++) {
          this.getImgInfo(this.objImgs, this.objImgs[i].src, i)
        }
      }
    },
    getImgInfo(objImgs, url, i) {
      let image = new Image();
      let w;
      image.src = url;
      image.onload = function () {
        w = image.width
        if (window.innerWidth <= w) {
          objImgs[i].style.width = '100%'
        }
        image.onload = null
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.intro {
  .main {
    p {
      line-height: 22px;
    }
  }
}
</style>
