<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-main>
      <transition :name="transitionName" mode="in-out" appear>
        <router-view class="commonVuetify global-router"></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import TopBars from "@/components/TopBars.vue";
export default {
  components: { TopBars },
  data() {
    return {
      transitionName: "fade",
    };
  },
  watch: {
    $route(to, from) {
      if (!from.meta.pageNum) {
        this.transitionName = "fade";
      } else {
        this.transitionName =
          to.meta.pageNum > from.meta.pageNum
            ? "left"
            : to.meta.pageNum < from.meta.pageNum
            ? "right"
            : "fade";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
