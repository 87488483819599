import Vue from 'vue';
import Router from 'vue-router';
import layout from '@/components/Layout.vue';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import resetPsw from '@/views/ResetPsw.vue';
import emailVerifyRpsw from '@/views/EmailVerifyRpsw.vue';
import verifyEmail from '@/views/VerifyEmail.vue';
import createPassword from '@/views/CreatePassword.vue';

import offerHelp from '@/views/OfferHelp/OfferHelp.vue';
import offerHelpIndex from '@/views/OfferHelp/Index.vue';
import deposit from '@/views/OfferHelp/Deposit.vue';
import upload from '@/views/OfferHelp/Upload.vue';
import offerHelpRecord from '@/views/OfferHelp/Record.vue';

import getHelp from '@/views/GetHelp/GetHelp.vue';
import getHelpIndex from '@/views/GetHelp/Index.vue';
import getHelpRecord from '@/views/GetHelp/Records.vue';

import account from '@/views/Account/AccountPage.vue';
import accountIndex from '@/views/Account/Index.vue';
import address from '@/views/Account/Address.vue';
import statement from '@/views/Account/Statement.vue';
import invite from '@/views/Account/Invites.vue';
import notification from '@/views/Account/Notification.vue';
import changepassword from '@/views/Account/ChangePassword.vue';
import download from '@/views/Account/Download.vue';
import policyindex from '@/views/Policy/Index.vue';
import faq from '@/views/Policy/FAQ.vue';
import intro from '@/views/Policy/Intro.vue';
import privacy from '@/views/Policy/Privacy.vue';
console.log(process.env.VUE_APP_TYPE)

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'layout',
      component: layout,
      redirect: 'home',
      children: [

        {
          path: '/home',
          name: 'home',
          component: Home,
          meta: {
            pageNum: 1,
          }
        },
        {
          path: '/offerhelp/record',
          name: 'offerHelpRecord',
          component: offerHelpRecord,
          meta: {
            pageNum: 5
          }
        },
        {
          path: '/gethelp/record',
          name: 'gethelprecord',
          component: getHelpRecord,
          meta: {
            pageNum: 3
          }
        },
      ],
    },
    {
      path: '/offerhelp',
      name: 'offerhelp',
      component: offerHelp,
      redirect: '/offerhelp/index',
      children: [
        {
          path: 'index',
          name: 'offerhelpindex',
          component: offerHelpIndex,
          meta: {
            pageNum: 2
          }
        },
        {
          path: 'deposit',
          name: 'deposit',
          component: deposit,
          meta: {
            pageNum: 3
          }
        },
        {
          path: 'upload',
          name: 'upload',
          component: upload,
          meta: {
            pageNum: 4
          }
        },
      ]
    },
    {
      path: '/gethelp',
      name: 'gethelp',
      component: getHelp,
      redirect: '/gethelp/help',
      children: [
        {
          path: 'help',
          name: 'gethelpindex',
          component: getHelpIndex,
          meta: {
            pageNum: 2
          }
        }
      ]
    },
    {
      path: '/account',
      name: 'account',
      component: account,
      redirect: '/account/index',
      children: [
        {
          path: 'index',
          name: 'accoumtindex',
          component: accountIndex,
          meta: {
            pageNum: 2
          }
        },
        {
          path: 'address',
          name: 'address',
          component: address,
          meta: {
            pageNum: 4
          }
        },
        {
          path: 'statement',
          name: 'statement',
          component: statement,
          meta: {
            pageNum: 3
          }
        },
        {
          path: 'invite',
          name: 'invite',
          component: invite,
          meta: {
            pageNum: 3
          }
        },
        {
          path: 'notification',
          name: 'notification',
          component: notification,
          meta: {
            pageNum: 4
          }
        },
        {
          path: 'changepassword',
          name: 'changepassword',
          component: changepassword,
          meta: {
            pageNum: 4
          }
        },
        {
          path: 'download',
          name: 'download',
          component: download,
          meta: {
            pageNum: 3
          }
        },
      ]
    },
    {
      path: '/policy',
      name: 'policy',
      component: policyindex,
      children: [
        {
          path: 'intro',
          name: 'intro',
          component: intro,
          meta: {
            pageNum: 4
          }
        },
        {
          path: 'faq',
          name: 'faq',
          component: faq,
          meta: {
            pageNum: 4
          }
        }, {

          path: 'privacy',
          name: 'privacy',
          component: privacy,
          meta: {
            pageNum: 4
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        pageNum: 2,
        white: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        pageNum: 3,
        white: true
      }
    },
    {
      path: '/reset',
      name: 'resetPsw',
      component: resetPsw,

      meta: {
        pageNum: 3,
        white: true
      }
    },
    {
      path: '/reset/checkmail',
      name: 'emailVerifyRpsw',
      component: emailVerifyRpsw,

      meta: {
        pageNum: 4,
        white: true
      }
    },
    {
      path: '/register/checkmail',
      name: 'verifyEmail',
      component: verifyEmail,

      meta: {
        pageNum: 4,
        white: true
      }
    },
    {
      path: '/reset/createNewPassword',
      name: 'createPassword',
      component: createPassword,
      meta: {
        pageNum: 5,
        white: true
      }
    }
  ],
});

router.beforeEach((to, from, next) => {
  if(to.path == "/login" && from.path == '/'){
    if(document.cookie){
      router.replace("/home")
    }
  }
  if(from.name == "offerHelpRecord" && to.name == 'deposit'){
    router.replace("/offerhelp/index")
  }

  if (from.name) {
    router.app.previousRoute = from;
  }
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  next();
});

export default router;
