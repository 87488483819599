<template>
  <div class="gethelp pa-4">
    <profile-card></profile-card>
    <div class="bottomBox pt-1">
      <v-form v-model="valid" ref="form">
        <v-select
          size="small"
          filled
          class="filledSelect noborder mt-5"
          v-model="select"
          :items="items"
          label=""
          item-text="state"
          item-value="abbr"
          :disabled="user && user.wallet !== null"
        ></v-select>
        <p class="mt-4 mb-2">{{$t('gethelp_help_gethelp_usdt_address')}}</p>
        <v-textarea
          auto-grow
          color="primary"
          rows="2"
          class="filledInput2 pt-0"
          label=""
          v-model="address"
          :rules="rules"
          :disabled="!!(user && user?.wallet?.addr)"
          :placeholder="$t('gethelp_help_gethelp_placeholder_address')"
        ></v-textarea>
        
        <v-text-field
          class="filledInput"
          label=""
          v-model="inputValue"
          hide-details="auto"
          :placeholder="$t('gethelp_help_gethelp_enter_amount')"
        ></v-text-field>
        <p
          class="annotation mt-1"
          :class="{
            errorf: !isAmountValid && inputValue,
            hidden: isAmountValid || !inputValue,
          }"
        >
          {{$t('gethelp_help_gethelp_annotation')}}
        </p>
        <v-btn
          :loading="loading"
          class="filledBtn"
          size="small"
          @click="submit"
        >
          {{$t('gethelp_help_gethelp_button_gethelp')}}
        </v-btn>
        <div class="spcr"></div>
      </v-form>
    </div>
    <!-- MatchDg -->
    <div class="match" v-if="showMatchingOverlay" @click="toUrl">
      <div class="cancelbox">
        <div class="cardprimary cancelOverlay">
          <v-container fluid fill-height>
            <v-row align="center" justify="center" class="text-center">
              <v-col cols="12">
                <p style="color: #000">
                  Please hold on as we complete the matchmaking process.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <!--<bottom-bars type="light"></bottom-bars>-->
    </div>
  </div>

</template>

<script>
import ProfileCard from "@/components/ProfileCard.vue";
import TopBars from "@/components/TopBars.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import orderApi from "@/api/order";
import userApi from "@/api/user";

export default {
  components: { TopBars, ProfileCard },
  data() {
    return {
      queryObj:null,
      valid: false,
      rules: [(v) => !!v || this.$t('gethelp_help_gethelp_wallet_required')],
      chain: "USDT-TRC20",
      loading: false,
      showMatchingOverlay: false,
      inputValue: "",
      minOffer: 100,
      address: "",
      duration: "fifteen",
      select: "TRC-20",
      items: [
        { state: "USDT-TRC20", abbr: "TRC-20" },
        { state: "USDT-ERC20", abbr: "ERC-20" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    isAmountValid() {
      return this.inputValue && this.inputValue % 10 === 0;
    },
  },
  mounted() {
    if (this.user && this.user.wallet) {
      this.select = this.user.wallet.chain;
      this.address = this.user.wallet.addr || "";
    }
    if (this.select === "TRC-20") {
      this.rules[1] = (v) =>
        /^T.{33}$/.test(v) ||
        this.$t('gethelp_help_gethelp_wallet_t_invalid');
    } else {
      this.rules[1] = (v) =>
        /^0x.{40}$/.test(v) ||
        this.$t('gethelp_help_gethelp_wallet_0x_invalid');
    }
    /*this.$nextTick(() => {
      if (this.user) {
         this.checkOrderStatus();
      }
    });*/
  },
  watch: {
    async user(n,old) {
        if (this.user && this.user.wallet) {
          this.select = this.user.wallet.chain;
          this.address = this.user.wallet.addr || "";
        }
    },
    select() {
      if (!this.user.wallet?.addr) {
        this.address = "";
      }
      if (this.select === "TRC-20") {
        this.rules[1] = (v) =>
          /^T.{33}$/.test(v) ||
          this.$t('gethelp_help_gethelp_wallet_t_invalid');
      } else {
        this.rules[1] = (v) =>
          /^0x.{40}$/.test(v) ||
          this.$t('gethelp_help_gethelp_wallet_0x_invalid');
      }
    },
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions({
      addWallet: "user/addWallet",
    }),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    toUrl(){
        this.showMatchingOverlay = false;

            this.$router.push({ path: "record", query: { ...this.queryObj } });
    },
    async submit() {
      if (!this.user) {
        this.$router.push({ path: "/login" });
        return;
      }
      if (!this.select) {
        this.notiFy(true, this.$t('gethelp_help_gethelp_notify_select_chain'));
        return;
      }

      const isAddressValid =
        this.select === "TRC-20"
          ? /^T.{33}$/.test(this.address)
          : /^0x.{40}$/.test(this.address);

      if (!this.address || !isAddressValid) {
        this.notiFy(false, this.$t('gethelp_help_gethelp_notify_valid_address', { chain: this.select }));
        return;
      }

      if (!this.inputValue || this.inputValue < this.minOffer || isNaN(this.inputValue) || !this.isAmountValid) {
        this.notiFy(false, this.$t('gethelp_help_gethelp_notify_amount_too_small', { minOffer: this.minOffer }));
        return;
      }


      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      try {
        let data = null;
        if (this.user.wallet) {
          if (this.user.wallet.chain && !this.user.wallet.addr) {
            data = {
              addr: this.address,
            };
          }
          if (this.user.wallet.addr && !this.user.wallet.chain) {
            data = {
              chain: this.select,
            };
          }
        } else {
          data = {
            chain: this.select,
            addr: this.address,
          };
        }
        let dataqu = {
          amount: this.inputValue,
        };

        if (data) {
          let walletResponse = await this.addWallet(data);

          if (walletResponse) {
             walletResponse.data.error 
            ? this.notiFy(false, walletResponse.data.error) 
            : this.notiFy(true, walletResponse.data.msg);
            let res = await orderApi.withdrawOrder(dataqu);
            if (res) {
              this.queryObj  = null
            if(res.data.error){
            this.notiFy(false, res.data.error);

            } else {
            this.showMatchingOverlay = true;
            this.queryObj = res.data
            setTimeout(() => {
            this.showMatchingOverlay = false;
            this.$router.push({ path: "record", query: { ...res.data } });
            }, 3000);
            

            }
          }
          }
        } else {
          let res = await orderApi.withdrawOrder(dataqu);
          if (res) {
            if(res.data.error){
            this.notiFy(false, res.data.error);

            } else {
            this.showMatchingOverlay = true;
            setTimeout(() => {
              this.showMatchingOverlay = false;
              this.$router.push({ path: "record", query: { ...res.data } });
            }, 3000);


            }
          }
        }
      } catch (error) {
        this.notiFy(false, this.$t('gethelp_help_gethelp_notify_order_error'));
      } finally {
        this.loading = false;
      }
    },
    /*async checkOrderStatus(pageloading) {
      let resOD = await orderApi.orderOngoing();
      if (resOD && resOD?.data) {
        this.orderDetail = resOD.data;
        console.log("Order details:", this.orderDetail);
        if (this.orderDetail.status === "pending") {
          this.showMatchingOverlay = true;
        }
      } else {
        this.showMatchingOverlay = false;
      }
    },*/
  },
};
</script>


<style lang="scss" scoped>
.gethelp {
  background-color: $background;
  min-height: calc(100vh + 50px);
  .bottomBox {
    padding: 20px 0;
    p {
      &:nth-child(1) {
        border-bottom: 1px solid #1d2128ff;
        padding-bottom: 2px;
        margin-bottom: 5px;
      }
    }
  }

    .match {
    width: 100vw;
    height: 100vh;
    background-color: #171a1fd1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    p {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .text {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      line-height: 30px;
      .cancel {
        color: $grey-icon-color;
        margin-top: 100px;
      }
    }
    .cancelbox,
    .matchreq {
      width: 85%;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      .cancelOverlay {
        background-color: #fff;
      }
      p {
        padding-bottom: 5px;
      }
    }
  }
}
</style>
