import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackbar: {
      snackbarSuccess: true,
      snackbarMsg: 'Successful',
      snackbar: false
    },
    settingGlobal: {},
    invitationCode: null,
     //language: [],
    language: 'en', //default language set to english
    refreshEvent: [],
    fabPosition: {
      bottom: '16px',
      right: '16px',
    }
  },
  getters: {
    snackbar(state) {
      return state.snackbar;
    },
    settingGlobal(state) {
      return state.settingGlobal;
    },
    language(state) {
      return state.language;
    },
    refreshEvent(state) {
      return state.refreshEvent;
    },
    fabPosition(state) {
      return state.fabPosition;
    },
    invitationCode(state) {
     return state.invitationCode;
   },
  },
  mutations: {
    setSnackbar(state, value) {
      let obj = {
        ...value,
        key: Math.random()
      };
      state.snackbar = obj;
    },
    setSettingGlobal(state, value) {
      let obj = {
        ...value.basic,
        cs: value.cs,
        iosLink: value.app.ios || '',
        androidLink: value.app.android || '',
      };
      console.log('Setting global:', obj);
      state.settingGlobal = obj;
    },
    setInvitationCode(state, code) {
     state.invitationCode = code;
   },
    setLanguage(state, value) {
      state.language = value;
      localStorage.setItem("inveslocale", value);
    },
    setRefreshEvent(state, data) {
      state.refreshEvent = data;
      console.log("refreshEvent:", data);
    },
    setFabPosition(state, position) {
      state.fabPosition = position;
    }
  },
  actions: {},
  modules: {
    user
  }
});
