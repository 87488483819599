<template>
  <div class="ptre" :style="{ height: dataList.length ? 'auto' : '100%' }">
    <div class="nomore" :style="{ height: dataList.length ? 'auto' : '100%' }">
      <span
        :style="{ opacity: loading || scroll ? 0 : 1 }"
        v-show="dataList.length > 0"
      >
        {{$t('account_notification_nomore')}}
      </span>
      <div
        v-show="!loading && dataList.length == 0"
        :style="{ marginTop: dataList.length ? 'auto' : '40%', transform: dataList.length ? '0' : 'translateY(-50%)' }"
      >
        <v-img
          v-if="isPathInList"
          class="nodataimg"
          width="80"
          :src="require('@/assets/nodata1.png')"
        />

        <v-img
          v-else
          class="nodataimg"
          width="80"
          :src="require('@/assets/nodata1.jpg')"
        />

        <p>{{$t('account_notification_noentry')}}</p>
      </div>
    </div>
    <div
      class="pageloadingBtm"
      v-show="loading"
      :style="{ bottom: dataList.length == 0 ? '60%' : (bottom||30)+'px' }"
    >
      <v-progress-circular indeterminate :color="color|| '#4069E5'"></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dataList", "loading", "scroll","color","bottom"],
  computed: {
    isPathInList() {
      const pathList = ["notification"];
      const currentPath = this.$route.name;
      return pathList.includes(currentPath);
    }
  }
};
</script>

<style lang="scss" scoped>
.nomore {
  color: #a7adb7ff;
  text-align: center;
  padding: 15px 0 18px;
  font-size: 14px !important;
  position: relative;
}

.nomorediv {
  height: 60px;
  color: #a7adb7ff;
  text-align: center;
  font-size: 14px !important;
}

.nodataimg {
  margin: auto;
}

.nodataimgbox {
  text-align: center;

  p {
    margin-top: 10px;
    color: rgba($color: #fff, $alpha: 0.7);
    font-size: 14px;
  }
}

.pageloading {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}

.pageloadingBtm {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 85px;
  width: 100%;
  text-align: center;
  background-color: transparent;
}
</style>
