<template>
  <div class="countDown text-center">
    <!-- Conditionally render progress based on the linear prop -->
    <v-progress-circular
      v-if="!linear"
      :size="210"
      :rotate="-90"
      :width="20"
      :value="progress"
      :color="$vuetify.theme.themes.light.primary"
    ></v-progress-circular>
    <v-progress-linear
      v-else
      :value="progress"
      height="10"
      class = "rounded-xxl"
      :color="$vuetify.theme.themes.light.countd_skyblue"
    ></v-progress-linear>
    <div class="timebox no-wrap">
      <p class="fs-16" v-if="countDownH!='00'">{{ countDownH }}h {{ countDownM }}m {{ countDownS }}s left</p>
      <p class="fs-16" v-if="countDownH=='00' && countDownM!='00'">{{ countDownM }}m {{ countDownS }}s left</p>
      <p class="fs-16" v-if="countDownH=='00' && countDownM=='00'"> {{ countDownS }}s left</p>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
export default {
  props: {
    status:{
      type:String,
      require:false,
      default:''
    },
    endTime: {
      type: Array,
      required: false,
      default:[],
    },
    linear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      progress: 0,
      countDownH: "00",
      countDownM: "00",
      countDownS: "00",
    };
  },
  mounted() {
  
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      const totalTime = 72 * 60 * 60; // total time in seconds
      let remaining = totalTime;

      if (this.endTime.length) {
        remaining =
          (new Date(this.endTime[1]).getTime() - new Date(this.endTime[0]).getTime()) / 1000;
      }
      if(remaining<=0){
         this.countDownH = '00';
        this.countDownM = '00';
        this.countDownS = '00';
         this.progress = 100
        return
      }

      const updateTime = () => {
        const h = String(Math.floor(remaining / 3600)).padStart(2, "0");
        const m = String(Math.floor((remaining % 3600) / 60)).padStart(2, "0");
        const s = String(Math.floor((remaining % 3600) % 60)).padStart(2, "0");
        this.countDownH = h;
        this.countDownM = m;
        this.countDownS = s;
        this.progress = ((totalTime - remaining) / totalTime) * 100;
      };

  
      updateTime();
      if(this.status == 'paid'){
         return
        }


      this.refreshData = setInterval(() => {
        if(this.status == 'paid'){
          clearInterval(this.refreshData);
        }
        remaining--;
        updateTime();

        if (remaining <= 0) {
    
          clearInterval(this.refreshData);
          this.countDownH = "00";
          this.countDownM = "00";
          this.countDownS = "00";
          this.progress = 100;
              store.commit('setSnackbar', {
        snackbarSuccess: false,
        snackbarMsg: 'Order has been closed',
        snackbar: true,
      });
    

        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.countDown {
  position: relative;
  .timebox {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -130%);
    span {
      margin: 0 10px;
    }
  }
}
</style>
