<template>
  <v-snackbar top :multi-line="true" dark v-model="snackbars" :timeout="1500">
    <div class="text-center">
      <div>
        <v-icon :color="snackbar.snackbarSuccess ? 'green' : 'red'" size="40">{{
          snackbar.snackbarSuccess ? "mdi-check-bold" : "mdi-close-thick"
        }}</v-icon>
      </div>

      <h4 class="ml-2 body-1">{{ snackbar.snackbarMsg }}</h4>
    </div>
  </v-snackbar>
</template>
   
  <script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    snackbars: false,
  }),
  computed: {
    ...mapGetters({
      snackbar: "snackbar",
    }),
  },
  watch: {
    snackbar: {
      handler() {        
        this.snackbars = this.snackbar.snackbar;
      },
      deep: true,
    },
  },
  methods: {
    showSuccessSnackbar() {
      let snackbar = {
        snackbarSuccess: false,
        snackbarMsg: "",
        snackbar: true,
      };
      this.setSnackbar(snackbar);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-snack__wrapper) {
  min-width: auto;
  margin-top: 70px;
  background-color: #333333c7;
  margin-right: 8px !important;
}
</style>