<template>
  <div class="enroute" :id="id"></div>
</template>
  
  <script>
import * as echarts from "echarts";

export default {
    props:['color','radius','center','id'],
  data() {
    return {
      chart: null,
      options: {
        tooltip: {
          trigger: "item",
        },
        title: {
          text: "En route",
          show:false,
          textStyle: {
            fontSize: 18,
          },
        },
        grid: {
          top: 100,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor:'transparent'
        },
        series: [
          {
            name: "En route",
            type: "pie",            
            startAngle: 90,
            label: {
              show: false,
            },           
            data: [
              { value: 70, name: "Awaiting" },
              { value: 30, name: "Balance" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
     this.options.series[0].color = this.color || ["#C1D4FE", "#3D7BF6"];
     this.options.series[0].radius = this.radius;
     this.options.series[0].center = this.center;
    this.initChart();
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id));
      this.chart.setOption(this.options);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.enroute {
  width: 100%;
  height: 250px;
}
</style>
  