var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes.light.background })},[_c('top-bars',{attrs:{"title":_vm.$t('offerhelp_offerhelp_title'),"showBack":false}}),_c('v-main',[_c('div',{staticClass:"record pa-4"},[_c('div',{staticClass:"tagslidegroup mb-4"},[_c('v-slide-group',{model:{value:(_vm.tagactive),callback:function ($$v) {_vm.tagactive=$$v},expression:"tagactive"}},_vm._l((_vm.tagAry),function(item,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-btn',{class:active ? 'active' : '',attrs:{"depressed":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true)})}),1)],1),_c('div',{attrs:{"id":"scrolldiv"}},[_c('div',_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,class:[
              'cardprimary mb-4 pa-3',
              (item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid')
                ? (index % 2 === 0 ? 'bluebg' : 'whitebg')
                : 'greybg'
            ]},[_c('ul',[_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t('offerhelp_prin_txt'))+" "+_vm._s(_vm.$formatUS(item.amount,2)))]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.$t('offerhelp_dr_txt'))+":"+_vm._s(item.rate * 100)+"%")])],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v("Total interest "+_vm._s(item?.er && !isNaN(Number(item?.er)) ? _vm.$formatUS(item?.er) : ""))]),_c('v-spacer'),_c('span')],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t('offerhelp_ord_ref'))+".")]),_c('v-spacer'),_c('span',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item._id)+" ")]),(item._id)?_c('v-icon',{staticClass:"ml-1",attrs:{"size":"16","color":(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                            ? (index % 2 === 0 ? '#fff' : '#ccc') 
                            : '#ccc'},on:{"click":function($event){return _vm.copyText($event, item._id)}}},[_vm._v(" mdi-content-copy ")]):_vm._e()],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t('offerhelp_record_init_timestamp')))]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(item.create_time))])],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t('offerhelp_record_final_timestamp')))]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(item.close_time))])],1),(['paid', 'settled', 'revoked'].includes(item.status) && item.w_info && item.w_info.length)?_c('v-divider',{staticClass:"my-1",style:({borderColor: item.status === 'revoked' ? '#ccc' : '#fff', borderWidth: '1px'})}):_vm._e(),(['paid', 'settled', 'revoked'].includes(item.status) && item.w_info && item.w_info.length)?_c('li',{staticClass:"mb-2"},_vm._l((item.w_info),function(info,wIndex){return _c('div',{key:wIndex,staticClass:"suborder"},[_c('ul',[_c('li',{staticClass:"d-flex"},[(item.w_info && item.w_info.length > 1)?_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16","color":(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                                ? (index % 2 === 0 ? '#fff' : '#ccc') 
                                : '#ccc'}},[_vm._v(" mdi-radiobox-marked ")]):_vm._e(),_c('span',[(item.w_info && item.w_info.length > 1)?_c('span',[_vm._v(" "+_vm._s(_vm.getOrdinalSuffix(wIndex + 1))+" ")]):_vm._e(),_vm._v(" payee info ")])],1),_c('li',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-1"},[_vm._v("ID")]),_c('span',[_vm._v(_vm._s(info.w_uid))]),_c('v-spacer'),_c('span',[_vm._v("Amount "+_vm._s(_vm.$formatUS(info.amount,2)))])],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v("Email")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(info.w_email))]),(info.w_email)?_c('v-icon',{staticClass:"ml-1",attrs:{"size":"16","color":(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                                  ? (index % 2 === 0 ? '#fff' : '#ccc') 
                                  : '#ccc'},on:{"click":function($event){return _vm.copyText($event, info.w_email)}}},[_vm._v(" mdi-content-copy ")]):_vm._e()],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v("Network")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(item.chain))])],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v("Pairing session")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.$DateUS(info.match_time)))])],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v("Address")]),_c('v-spacer'),_c('span',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(info.w_addr))]),(info.w_addr)?_c('v-icon',{staticClass:"ml-1",attrs:{"size":"16","color":(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                                  ? (index % 2 === 0 ? '#fff' : '#ccc') 
                                  : '#ccc'},on:{"click":function($event){return _vm.copyText($event, info.w_addr)}}},[_vm._v(" mdi-content-copy ")]):_vm._e()],1),_c('li',{staticClass:"d-flex"},[_c('span',[_vm._v("Order ref.")]),_c('v-spacer'),_c('span',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(info.w_id))]),(info.w_id)?_c('v-icon',{staticClass:"ml-1",attrs:{"size":"16","color":(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                                  ? (index % 2 === 0 ? '#fff' : '#ccc') 
                                  : '#ccc'},on:{"click":function($event){return _vm.copyText($event, info.w_id)}}},[_vm._v(" mdi-content-copy ")]):_vm._e()],1),(info.img)?_c('li',{staticClass:"imgwrap d-flex align-center justify-center"},[_c('div',{staticClass:"card w100 mt-5",on:{"click":function($event){return _vm.zoomImg(info)}}},[_c('div',{staticClass:"text-center uploadbox"},[_c('div',{staticClass:"imgwrap d-flex align-center justify-center"},[_c('auth-img',{attrs:{"max-width":"190px","max-height":"208px","src":info.img}})],1)])])]):_vm._e(),_c('li',[_c('v-btn',{class:['rounded-xxl my-4 w100 pa-4', (item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') ? (index % 2 === 0 ? 'blbg white--text' : 'whtbg primary--text') : 'whtbg primary--text' ],attrs:{"size":"small"}},[_vm._v(" "+_vm._s((info.d_status === 'paid' || info.img) ? 'Verified' : _vm.$t('gethelp_record_gethelp_record_confirm_button'))+" ")])],1)])])}),0):_vm._e()],1)])}),0),_c('nodata',{attrs:{"loading":_vm.loading,"scroll":_vm.scroll,"dataList":_vm.dataList,"bottom":"80"}}),(_vm.showZoomOverlay)?_c('div',{staticClass:"zoom",on:{"click":_vm.toggleCloseOverlay}},[_c('div',{staticClass:"cancelbox",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"cardprimary cancelOverlay"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"text-center",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.zmdImg)?_c('div',[_c('auth-img',{style:({ maxHeight: '80vh', borderRadius: '5px' }),attrs:{"src":_vm.zmdImg,"height":"auto"}})],1):_vm._e()])],1)],1)],1)])]):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }