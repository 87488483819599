import Vue from 'vue';
import { Locale } from 'vant'
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify'
import store from './store'
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/styles/vuetifyReset.scss';
import '@/styles/common.scss';
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US', enUS);


import { i18n } from './i18n/index'


Vue.config.productionTip = false;

// Define the global methods
Vue.prototype.$moveBack = function () {
  if (this.$router) {
    this.$router.go(-1);
  } else {
    console.warn('Router not available');
  }
}

Vue.prototype.$formatUS = function (number, ft) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: ft || 0,
    maximumFractionDigits: ft || 0
  }).format(number);
}


Vue.prototype.$DateUS = function (date) {
  if(!date) return
  return new Intl.DateTimeFormat("en-US", {
    year: 'numeric', 
    month: 'short',  
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit', 
    hour12: false 
  }).format(new Date(date))
}

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
