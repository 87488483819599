import authApi from '@/api/auth';
import SocketPlugin from "@/plugins/socket";
import userApi from '@/api/user';

export default {
  namespaced: true,
  state: {
    user: null,
    socket: null,
    userOffer: null,
    invitationCode: null,
    language: null,
    cookies:[]
  },
  mutations: {
    setCookies(state,cookies){
      state.cookies = cookies;
    },

    setUser(state, user) {
      state.user = user;
    },
    setSocket(state, data) {
      state.socket = data;
    },
    setUserOffer(state, userOffer) {
      state.userOffer = userOffer;
    },
    setInvitationCode(state, code) {
      state.invitationCode = code; // ic mutated
    },
    setLanguage(state, language) {
      state.language = language;
    },
   
  },
  getters: {
    user(state) {
      return state.user;
    },
    socket(state) {
      return state.socket;
    },
    userOffer(state) {
      return state.userOffer;
    },
    invitationCode(state) {
      return state.invitationCode; // ic getter
    },
    language(state) {
      return state.language; // language getter
    },
  },
  actions: {
async login(context, userInfo) {
  //const email = userInfo.email ? userInfo.email.trim() : '';
  const email = userInfo.email 
  ? userInfo.email.trim().replace(/^([^@]*)/, (match) => match.toLowerCase()) 
  : '';
  const password = userInfo.password;
  const _type = userInfo._type;

  try {
    let res = await authApi.login({ email, password });

    if (res && res.data) {
      if (!res.data.error) {
        let user = res.data;
        user.headCount = 0;
        user.balance = user.balance ? user.balance.toFixed(2) : '0.00';

        if (JSON.stringify(user.levels) !== '{}') {
          for (let key in user.levels) {
            user.headCount += user.levels[key];
          }
        }

        context.commit('setUser', user);
        context.commit('setInvitationCode', null);
         

        let cok = await authApi.getCookiesApi();
        if (cok) {
        
          context.commit('setSocket', SocketPlugin.init(cok.data.session));
          context.commit('setCookies',cok.data.session)
        }
        await context.dispatch('getInfo');

        return res.data;
      } else {
        // Commit to the root store
        context.commit('setSnackbar', {
          snackbarSuccess: false,
          snackbarMsg: res.data.error,
          snackbar: true,
        }, { root: true });
      }
    } else {
      console.log("Unexpected error occurred");
    }
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
},

  async register(context, userInfo) {
      try {
        let res = await authApi.register(userInfo);
        if (res && res.data) {
          if (!res.data.error){
          let user = res.data.info;
          context.commit('setUser', user);
          context.commit('setInvitationCode', null);

          let cok = await authApi.getCookiesApi();
          if (cok) {
            context.commit('setSocket', SocketPlugin.init(cok.data.session));
            context.commit('setCookies',cok.data.session)
          }

          return res; 
          } else {
            // Commit to the root store
            context.commit('setSnackbar', {
              snackbarSuccess: false,
              snackbarMsg: res.data.error,
              snackbar: true,
            }, { root: true });
            //
          }
          if (res.data.msg){
            // Commit to the root store
            context.commit('setSnackbar', {
              snackbarSuccess: true,
              snackbarMsg: res.data.msg,
              snackbar: true,
            }, { root: true });
          }
        }// res && res.data.error end
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async getInfo(context) {
      let res = await authApi.userInfo();
      let resct = await userApi.totalCommission();
      let scres = await userApi.statCommission();
      if (res) {
        let user = res.data;
        user.headCount = 0;
        if (user) {
          user.balance = user.balance ? user.balance.toFixed(2) : '0.00';
          if (JSON.stringify(user.levels) !== '{}') {
            for (let key in user.levels) {
              user.headCount += user.levels[key]
            }
          }
          if (resct) user.t_commission = resct.data.t_commission || 0;
       
          if (scres){
            user.this_week = scres.data.this_w || {};
            user.last_week = scres.data.last_w || {};
            console.log("statCommission", scres);
          }

          context.commit('setUser', user);
          context.commit('setInvitationCode', null);
          if (!context.state.socket) {
            let cok = await authApi.getCookiesApi();
            if (cok) {
              context.commit('setSocket', SocketPlugin.init(cok.data.session));
              context.commit('setCookies',cok.data.session)
           
            }
          }
          return res.data;
        }
      }
    },
    async logOut(context) {
      let res = await authApi.logout();
      if (res) {
        context.commit('setUser', null);
        context.commit('setSocket', null);
        context.commit('setInvitationCode', null);
        return res.data;
      }
    },
    async addWallet(context, userWallet) {
      let res = await userApi.addWallet(userWallet);
      if (res) {
        await context.dispatch('getInfo');
      }
      return res;
    }
  }
};
