import { post } from '@/utils/request.js';

const apiUrl = '/order';

const depositOrder = async (data) => post({
    url: apiUrl, data: {
        cmd: 'deposit_order',
        params: data
    }
});
const withdrawOrder = async (data) => post({
    url: apiUrl, data: {
        cmd: 'withdraw_order',
        params: data
    }
});

const queryOrders = async (data) => post({
    url: apiUrl, data: {
        cmd: 'query_orders',
        params: data
    }
});

const orderDetail = async (data) => post({
    url: apiUrl, data: {
        cmd: 'order_detail',
        params: data
    }
});
const orderExpired = async () => post({
    url: apiUrl, data: {
        cmd: 'order_expired'

    }
});

const orderOngoing = async () => post({
    url: apiUrl, data: {
        cmd: 'order_ongoing'

    }
});
const statDeposit = async (data) => post({
    url: apiUrl, data: {
        cmd: 'stat_deposit',
        params: data

    }
});
const depositRevoke = async (data) => post({
    url: apiUrl, data: {
        cmd: 'deposit_revoke',
        params: data

    }
});
const withdrawConfirm = async (data) => post({
    url: apiUrl, data: {
        cmd: 'withdraw_confirm',
        params: data

    }
});
const orderCanConfirm = async (data) => post({
    url: apiUrl, data: {
        cmd: 'order_can_confirm',
        params: data

    }
});
export default { depositOrder, queryOrders, orderDetail, orderExpired, orderOngoing, statDeposit,withdrawOrder,depositRevoke,withdrawConfirm,orderCanConfirm }
