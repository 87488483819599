<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <top-bars :title="$t('reset_reset_psw_title')"></top-bars>
    <v-main>
      <v-container>
        <v-row no-gutters class="align-center justify-center">
          <v-col cols="12" class="d-flex flex-column align-center">
            <div class="circle mb-4 mt-5">
              <v-row no-gutters class="align-center justify-center">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <div class="combined-section">
                    <v-img
                      width="70"
                      :src="require('@/assets/dotlock.png')"
                      class="lock white-icon"
                      contain
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>


        <!-- Form section -->
        <div class="form-wrapper pa-4">
          <v-form v-model="valid" ref="form">
            <!-- New Password -->
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :placeholder="$t('account_index_password')"
                  class="fs-15 fw-400"
                  @input="checkPasswordRequirements"
                  @focus="showRequirements = true"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Password Requirements -->
            <v-row no-gutters>
              <v-col cols="12">
                <p v-show="showRequirements" class="mt-1">
                  <v-icon
                    left
                    size="20"
                    :color="meetrequirement1 ? 'green' : ''"
                    >mdi-check-circle</v-icon
                  >
                  {{$t('reset_res_met1')}}
                </p>
              </v-col>

              <v-col cols="12">
                <p v-show="showRequirements" class="mt-1">
                  <v-icon
                    left
                    size="20"
                    :color="meetrequirement2 ? 'green' : ''"
                    >mdi-check-circle</v-icon
                  >
                  {{$t('reset_res_met2')}}
                </p>
              </v-col>

              <v-col cols="12">
                <p v-show="showRequirements" class="mt-1">
                  <v-icon
                    size="20"
                    left
                    :color="meetrequirement3 ? 'green' : ''"
                    >mdi-check-circle</v-icon
                  >
                  {{$t('reset_res_met3')}}
                </p>
              </v-col>
            </v-row>

            <div class="spcr"></div>
            <!-- Reset Button -->
            <v-row no-gutters class="mt-8 mb-4">
              <v-col cols="12">
                <v-btn
                  large
                  class="py-6 body-1 w100 white--text rounded-xxl"
                  height="44px"
                  color="#4b82ed"
                  @click="submit"
                  :loading="loading"
                  :disabled="!allRequirementsMet"
                >
                Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopBars from "../components/TopBars.vue";
import { mapMutations, mapActions } from "vuex";
import authApi from "@/api/auth";
export default {
  components: { TopBars },
  data() {
    return {
      valid: false,
      password: "",
      passwordRules: [(v) => !!v || this.$t('reset_res_psw_required')],
      meetrequirement1: false,
      meetrequirement2: false,
      meetrequirement3: false,
      showRequirements: false,
      loading: false,
    };
  },
  computed: {
    allRequirementsMet() {
      return (
        this.meetrequirement1 && this.meetrequirement2 && this.meetrequirement3
      );
    },
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    checkPasswordRequirements() {
      const minLength = 8;
      this.meetrequirement1 = this.password.length >= minLength;
      this.meetrequirement2 = /[0-9]/.test(this.password);
      this.meetrequirement3 =
        /[a-z]/.test(this.password) && /[A-Z]/.test(this.password);
    },
    async submit() {
      if (this.allRequirementsMet) {
        this.loading = true;
        try {
          let res = await authApi.resetpsw({
            password: this.password,
            vc: this.$route.query.vc,
            email: this.$route.query.email,
          });
          if (res) {
            this.setSnackbar({
              snackbarSuccess: true,
              snackbarMsg: "Password reset successful, please log in",
              snackbar: true,
            });
            sessionStorage.removeItem('user');
            this.$router.push("/login");
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #3d6beb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lock {
  height: 110px;
  width: 110px;
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10px;
  color: #171a1fff;
}

.form-wrapper {
  background: #f2f3f4ff;
  height: 50vh;
}

p {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: $main-font-color;
  .v-icon.v-icon {
    color: $main-font-color;
  }
}

.v-text-field__slot {
  border-bottom: 2px solid #4b82ed !important;
}
</style>
