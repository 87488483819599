<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <top-bars :title="$t('offerhelp_offerhelp_title')" :showBack="showBack" :toHome="toHome">
      <router-link :to="user ? 'record' : '/login'">
        <v-icon>mdi-dots-vertical</v-icon>
      </router-link>
    </top-bars>
    <v-main>
      <transition :name="transitionName" mode="in-out" appear>
        <router-view class="pa-4 commonVuetify global-router"></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import TopBars from "@/components/TopBars.vue";
import { mapGetters } from "vuex";

export default {
  components: { TopBars },
  data() {
    return {
      showBack: true,
      transitionName: "fade",
      toHome: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  async mounted() {
    if (this.$route.name == "offerHelpRecord") {
      this.showBack = false;
    }
    if (this.$route.name == "offerhelpindex") {
      this.toHome = true;
    }
  },
  watch: {
    $route(to, from) {
      if (to.name == "offerHelpRecord") {
        this.showBack = false;
      } else {
        this.showBack = true;
      }
      if (to.name == "offerhelpindex") {
        this.toHome = true;
      }
      if (!from.meta.pageNum) {
        this.transitionName = "fade";
      } else {
        this.transitionName =
          to.meta.pageNum > from.meta.pageNum
            ? "left"
            : to.meta.pageNum < from.meta.pageNum
            ? "right"
            : "fade";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
