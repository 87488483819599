<template>
  <v-container class="notification-container pa-0" fluid>
    <top-bars :title="$t('account_notification_noti_title')" :light="false"></top-bars>

    <!-- Slider for Unread and Reviewed -->
    <div class="tagdiv px-4 pt-4">
      <ul>
        <li
          v-for="(item, index) in list"
          :key="index"
          class="text-center tab-item"
          :class="{ 'active-tab': tab === index }"
          @click="tab = index"
        >
          <span>
            {{ item.name }}
          </span>
        </li>
      </ul>
    </div>

    <!-- Swipe Left to Delete Reminder -->
    <v-alert v-if="showSwipeReminder" class="swipe-reminder fs-14 lh-22 fw-400">
      <div class="text-wrapper">Swipe left to delete an item.</div>
    </v-alert>

    <!-- Notifications List -->
    <div id="scrolldiv" class="px-4">
      <div
        v-for="(notification, index) in notifications"
        :key="notification._id"
        ref="notification"
        class="notification-item mb-4"
      >
        <v-card
          class="notifyCard"
          @click="toggleCollapse(notification, index)"
          @touchstart="startSwipe($event, notification._id)"
          @touchmove="moveSwipe($event, notification._id)"
          @touchend="endSwipe(notification._id)"
        >
          <v-card-text>
            <v-row>
              <v-col>
                <h3 class="fs-16 lh-26 fw-700 black--text">
                  {{ notification.title }}
                </h3>
                <span class="fs-14 lh-22 fw-400 pt-4" v-html="getDescription(notification)">
                </span>

              </v-col>
            </v-row>
            <v-row class="d-flex align-center mt-0">
              <v-col v-show="notification.content.length > 120">
                <span class="fs-14 lh-26 fw-700 black--text chevronIcon">
                  {{ !notification.open ? "Read more" : "Read less" }}
                  <v-icon size="13" class="fw-700">{{
                    !notification.open ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                </span>
              </v-col>
              <v-col class="text-right">
                <small class="fs-14 lh-22 fw-400">{{
                  notification.create_time
                }}</small>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <nodata
        :dataList="notifications"
        :loading="loading"
        :scroll="scroll"
        color="#fff"
      ></nodata>
    </div>
  </v-container>
</template>

<script>
import TopBars from "@/components/TopBars.vue";
import Nodata from "../../components/Nodata.vue";
import userApi from "@/api/user.js";
import { mapGetters, mapMutations } from "vuex";
import Bus from '@/utils/bus'
export default {
  components: { TopBars, Nodata },
  data() {
    return {
      tab: 0, // 0 for Unread, 1 for Reviewed
      list: [
        { name: this.$t('account_notification_tab1'), key: "unread" },
        { name: this.$t('account_notification_tab2'), key: "read" },
      ],
      showSwipeReminder: false,
      dataList: [],
      skip: 0,
      limit: 6,
      loading: false,
      scroll: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    notifications() {
      return !this.tab
        ? this.dataList.filter((item) => !item.isRead) || []
        : this.dataList;
    },
  },
  mounted() {
  
    setTimeout(() => {
      this.showSwipeReminder = false;
    }, 1500);

    let self = this;
    self.getList();
    const element = document.getElementById("scrolldiv");
    element.addEventListener("scroll", function () {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        console.log("滚动到底部");
        if (self.scroll) {
          self.getList();
        }
      }
    });
  },
  watch: {
    tab() {
      this.skip = 0;
      this.loading = false;
      this.scroll = false;
      this.dataList = [];
      this.getList();
    },
    notifications() {
      // console.log(this.notifications);
    },
  },
  methods: {
  ...mapMutations(["setSnackbar"]),
    async getList(num) {
      let data = {
        extra: {
          _type: this.list[this.tab].key,
        },
        skip: this.notifications.length - (num || 0),
        limit: this.limit,
      };
      this.loading = true;
      let res = await userApi.messageQuery(data);
      this.loading = false;
      if (res) {
        let datas = res.data.map((item) => {
          item.create_time = this.$DateUS(item.create_time)
          return item
        })
        this.dataList = this.dataList
          ? this.dataList.concat(datas)
          : res.data;
        if (res.data.length == this.limit) {
          this.skip += 1;
          this.scroll = true;
        } else {
          this.scroll = false;
        }
      }
    },
    async toggleCollapse(item, index) {
      let updatedNotification = { ...item };
      updatedNotification.open = !updatedNotification.open;
      if (updatedNotification.open && !this.tab) {
        let res = await userApi.messageRead({ _id: item._id });
        if(res){
          Bus.$emit("unreadmsg",'010')
        }
      }
      let num = 0;
      this.dataList = this.dataList.map((not, ind) => {
        if (not.open && !this.tab) {
          not.isRead = true;
          num++;
          if (num) {
            this.getList(1);
          }
        }

        if (not._id == updatedNotification._id) {
          not.open = !not.open;
        } else {
          not.open = false;
        }
        return not;
      });
    },

    getDescription(notification) {     
      return !notification.open
        ? (notification.content.length>120?notification.content.slice(0, 120) + "...":notification.content)
        : notification.content;
    },
    startSwipe(event, id) {
      const notification = this.notifications.find((n) => n._id === id);
      notification.swipeStartX = event.touches[0].clientX;
    },
    moveSwipe(event, id) {
      const notification = this.notifications.find((n) => n._id === id);
      const diffX = event.touches[0].clientX - notification.swipeStartX;
      if (diffX < 0) {
        this.$refs.notification[
          this.notifications.indexOf(notification)
        ].style.transform = `translateX(${diffX}px)`;
      }
    },
    async endSwipe(id) {
      const notification = this.notifications.find((n) => n._id === id);
      const card =
        this.$refs.notification[this.notifications.indexOf(notification)];
      if (
        parseInt(card.style.transform.replace("translateX(", ""), 10) < -100
      ) {
        let res = await userApi.messageDel({ _id: id });
        if (res) {
          Bus.$emit("unreadmsg",'010')
          this.dataList = this.dataList.filter((n) => n._id !== id);
          if (this.dataList.length == this.limit - 1) {
            this.getList();
          }
        }
      } else {
        card.style.transform = "translateX(0)";
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.notification-container {
  background-color: #4069e5;
  height: 100vh;
  overflow-y: auto;
  
}

.tabs-col {
  padding: 0;
}

.active-line {
  background-color: white;
  height: 3px;
  width: 30%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.notifyCard {
  background-color: #f5f5f5;
  
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.notification-item {
  transition: transform 0.3s ease;
  margin-bottom: 10px;
  .fs-14,.fs-16{
    color: $main-font-color;
  }
  
}

.swipe-reminder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 999;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.text-wrapper {
  margin-top: 100%;
}

.v-icon {
  vertical-align: middle;
  margin-left: 5px;
}
.chevronIcon {
  i {
    border: 1px solid $main-font-color;
    border-radius: 100%;
    color: $main-font-color;
  }
}
.tagdiv {
  ul {
    display: flex;
    border-bottom: 2px solid #5381d0;
    margin-bottom: 20px;
    li {
      font-size: 16px;
      display: inline-block;
      margin-right: 10px;
      color: #bcc1ca;
      padding: 7px 10px;
      position: relative;
      &.active-tab {
        color: #fff;
        &::before {
          position: absolute;
          display: inline-block;
          content: "";
          width: 60%;
          height: 3px;
          background-color: #fff;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
#scrolldiv {
  height: calc(100vh - 150px);
}
</style>
