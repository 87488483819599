<template>
  <v-app-bar
    :style="{ top: appBarPosition }"
    :class="{ 'app-bar-hidden': hideAppBar }"
    :color="
      light
        ? $vuetify.theme.themes.light.background
        : $vuetify.theme.themes.light.primary
    "
    flat
    height="50"
  >
    <div
      class="d-flex align-center flex-row w100 headerPageTop"
      :class="light ? 'lightType' : 'primaryType'"
    >
      <span v-show="showBack">
        <v-icon
          class="lefticon"
          size="26"
          :color="light ? $vuetify.theme.themes.light.greyIcon : '#fff'"
          @click="moveBack"
        >
          mdi-arrow-top-left
        </v-icon>
      </span>
      <div class="flex-grow-1 d-flex align-center text-center">
        <span class="title" style="flex: 1">{{ title }}</span>
      </div>
      <div class="text-right" style="min-width: 24px" v-show="showBack">
        <slot></slot>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#181A1E",
    },
    title: {
      type: String,
      default: "",
    },
    light: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    backClick: {
      type: Boolean,
      default: false,
    },
    toHome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hideAppBar: false,
      lastScrollTop: 0,
    };
  },
  computed: {
    appBarPosition() {
      return this.hideAppBar ? "-50px" : "0";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    moveBack() {
      if (this.backClick) {
        this.$emit("backFun");
      } else if (this.toHome) {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    handleScroll() {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > 10) {
        this.hideAppBar = true;
      } else {
        this.hideAppBar = false;
      }

      this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.primaryType .title {
  color: #fff;
}

.app-bar-hidden {
  transition: opacity 0.3s ease-in-out, top 0.2s ease-in-out;
  opacity: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
}
</style>
