<template>
    <v-img ref="img" :src="newSrc" />
</template>
<script>
export default {
    props:{
        src:{
            type: String,
            default:''
        }
    },
    watch:{
        src:{
            handler(n){
                if(n){
                    this.getImage(n);
                }
            },
            immediate:true
        }
    },
    data(){
        return{
            newSrc:''
        }
    },

    methods:{
        getImage(url){
            let that = this;
            that.$nextTick(()=>{
                let request = new XMLHttpRequest();
                request.responseType = 'blob';
                request.open('get', url, true);
                // 添加自定义请求头
                request.setRequestHeader('Authorization', this.$store.state.user.cookies[0]); 
                request.setRequestHeader('svc', 'app'); 
                request.onreadystatechange = e => {
                    if (request.readyState == XMLHttpRequest.DONE && request.status == 200) { 
                        that.newSrc = URL.createObjectURL(request.response);
                        that.$refs.img.onload = () => {
                            that.newSrc && URL.revokeObjectURL(that.newSrc);
                        } 
                    }
                };
                request.send(null);
            });
        }
    }
}
</script>
