<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <!-- App Bar for Logged-In Users -->
    <v-app-bar
      v-if="user"
      app
      :style="{ top: appBarPosition }"
      :class="{ 'app-bar-hidden': hideAppBar }"
      :color="$vuetify.theme.themes.light.background"
      flat
      height="50"
    >
      <!-- <div class="switchlan">
        <span
          @click="changeLang('en')"
          :class="language == 'en' ? 'active' : ''"
          >en</span
        >
        <span
          @click="changeLang('zh-CN')"
          :class="language == 'zh-CN' ? 'active' : ''"
          >中</span
        >
      </div>-->
      <v-toolbar-title class="lh-30 fw-700 fs-22 ts-01 blue-text brand-name">{{
        settingGlobal.title
      }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!--<router-link :to="{ name: 'notification' }" class="notification-icon">
        <v-icon class="blue-text">mdi-bell</v-icon>
        <div class="notification-dot" v-if="unRead"></div>
      </router-link>-->
      <!-- Notification Icon -->
      <router-link :to="{ name: 'notification' }">
        <v-btn icon class="mcbt">
          <v-icon>mdi-bell</v-icon>
          <span
            class="num white--text"
            v-if="unRead"
            :style="{ width: unRead > 99 ? '25px' : '20px' }"
          >
            {{ unRead > 99 ? "+99" : unRead }}
          </span>
        </v-btn>
      </router-link>

    </v-app-bar>
    <!-- App Bar for Guests -->
    <v-app-bar
      v-else
      app
      :style="{ top: appBarPosition }"
      :class="{ 'app-bar-hidden': hideAppBar }"
      :color="$vuetify.theme.themes.light.primary"
      flat
      height="50"
    >

      <v-toolbar-title class="lh-30 fw-700 fs-22 ts-01 white--text brand-name">{{
        settingGlobal.title
      }}</v-toolbar-title>
       <!--<div class="switchlan">
        <span
          @click="changeLang('en')"
          :class="language == 'en' ? 'active' : ''"
          >en</span
        >
        <span
          @click="changeLang('zh-CN')"
          :class="language == 'zh-CN' ? 'active' : ''"
          >中</span
        >
      </div>-->

      <v-spacer></v-spacer>
      <router-link :to="ic ? { path: '/login', query: { ic } } : '/login'">
        <v-btn text class="login-btn white--text fw-700"> {{$t('login_login_title')}} </v-btn>
      </router-link>
      <router-link
        :to="ic ? { path: '/register', query: { ic } } : '/register'"
      >
        <v-btn text class="sign-up-btn white--text"> {{$t('register_register_title')}} </v-btn>
      </router-link>
    </v-app-bar>

    <!-- Main Content -->
    <div class="homeCard mx-4 pt-4">
      <v-row>
        <v-col cols="12" sm="12" md="6" class="mx-auto">
          <!-- Profile Card -->
          <profile-card v-if="user" />

          <!-- Why Get Help Here -->
          <p class="des mb-2">{{$t("home_home_whygethelp")}}</p>
          <v-row class="mb-5">
            <v-col cols="12" class="pb-1">
              <v-row align="center" no-gutters>
                <v-col cols="5" class="text-center pr-3">
                  <v-card
                    class="helpCards d-flex align-center justify-center rounded-lg"
                  >
                    <v-img
                      :src="require('@/assets/currency.png')"
                      class="helpIcon white-icon"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col cols="7" class="d-flex align-center">
                  <div>
                    <h5 class="mb-2 fw-700 fs-16">{{$t("home_home_t1")}}</h5>
                    <p class="mb-0 des">{{$t("home_home_d1")}}</p>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="pb-1">
              <v-row align="center" no-gutters>
                <v-col cols="5" class="text-center pr-3">
                  <v-card
                    class="helpCards d-flex align-center justify-center pa-6 mr-2 rounded-lg"
                  >
                    <v-img
                      :src="require('@/assets/honesty.png')"
                      class="helpIcon white-icon"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col cols="7" class="d-flex align-center">
                  <div>
                    <h5 class="mb-2 fw-700 fs-16">{{$t("home_home_t2")}}</h5>
                    <p class="mb-0 des">{{$t("home_home_d2")}}</p>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="pb-1">
              <v-row align="center" no-gutters>
                <v-col cols="5" class="text-center pr-3">
                  <v-card
                    class="helpCards d-flex align-center justify-center pa-6 mr-2 rounded-lg"
                  >
                    <v-img
                      :src="require('@/assets/balance.png')"
                      class="helpIcon white-icon"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col cols="7" class="d-flex align-center">
                  <div>
                    <h5 class="mb-2 fw-700 fs-16">{{$t("home_home_t3")}}</h5>
                    <p class="mb-0 des">{{$t("home_home_d3")}}</p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Profit Table -->
          <v-card class="rounded-lg">
            <div class="table-wrapper">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="tbl-head">
                    <tr>
                      <th>
                        <div class="text-left white--text fs-14 fw-700 ml-8">
                          {{$t("home_home_tid")}}
                        </div>
                      </th>
                      <th>
                        <div class="text-left white--text fs-14 fw-700 ml-1">
                          {{$t("home_home_tprofit")}}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in profitData"
                      :key="index"
                      :class="{ 'even-row': index % 2 !== 0 }"
                    >
                      <td style="padding: 15px">
                        <div class="ptre avatarbox">
                          <v-avatar size="40" class="mr-2 ml-4 py-4">
                            <v-img
                              :src="item.avatar"
                              v-if="item.avatar"
                            ></v-img>
                            <div v-else>
                              <v-icon color="#fff" class="nouserimg"
                                >mdi-account</v-icon
                              >
                            </div>
                          </v-avatar>
                          <span
                            class="pointgre"
                            :class="!item.active ? 'gery' : ''"
                          ></span>
                        </div>
                        {{ item.id }}
                      </td>
                      <td style="padding: 15px">
                        {{ item.profit }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div class="spcr"></div>
    </div>
  </v-app>
</template>

<script>
import profileCard from "@/components/ProfileCard.vue";
import t1 from "@/assets/t1.jpg";
import t2 from "@/assets/t2.jpg";
import { getRandomInt } from "@/utils/index";
import { mapMutations, mapGetters } from "vuex";
import userApi from "@/api/user";

export default {
  components: {
    profileCard,
  },
  inject: ["unReadCount"],
  data() {
    return {
      hideAppBar: false,
      lastScrollTop: 0,
      ic: "",
      profitData: [
        { id: "12***2562", profit: "$190,250", avatar: t1 },
        { id: "12***5862", profit: "$180,240", avatar: t2 },
        { id: "34***2562", profit: "$160,230", avatar: t1 },
        { id: "82***2565", profit: "$150,250", avatar: t2 },
        { id: "12***2585", profit: "$120,250" },
      ],
      isLoggedIn: false,
      userEmail: "",
      language: 'en',
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      settingGlobal: "settingGlobal",
    }),
    ...mapGetters(["invitationCode"]),
    unRead() {
      return this.unReadCount();
    },
    appBarPosition() {
      return this.hideAppBar ? "-50px" : "0";
    },
  },
  created() {
    const invitationCode = this.$route.query.ic;
     
    if (invitationCode && !this.user) {
      this.$store.commit("setInvitationCode", invitationCode);
      window.localStorage.setItem('invitationCode',invitationCode)
      this.ic = invitationCode;
    } 
  },
  async mounted() {
    const strLang = localStorage.getItem('inveslocale');
    if (strLang) {
      this.language = strLang;
    }
    window.addEventListener("scroll", this.handleScroll);
    this.getRank();

    this.timer = setInterval(() => {
      this.getRank();
    }, 10000)
  },
  methods: {
    ...mapMutations(["setLanguage"]),

      async changeLang(lan) {
      if(this.user){
          let lres = await userApi.changeLang({
          lang: lan,
        });
        if (lres) {
          console.log('lang set');
        }
      }
      console.log(`Changing language to: ${lan}`);
      localStorage.setItem("inveslocale", lan);
      this.$i18n.locale = localStorage.getItem("inveslocale");
      this.setLanguage(lan);
      this.language = lan;
  },
    async getRank() {
      let userImgAry = await userApi.interestRanking();
      let data = Array(5).fill("");
      data = data.map(() => getRandomInt(150000, 250000)).sort((a, b) => b - a);
      this.profitData = this.profitData.map((item, index) => {
        item.id = getRandomInt(10, 99) + "***" + getRandomInt(1000, 9999);
        item.profit = this.$formatUS(data[index],2);
        item.avatar = userImgAry.data[index];
        item.active = Math.random() < 0.5 ? true : false;

        return item;
      });
    },
    handleScroll() {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > this.lastScrollTop) {
        this.hideAppBar = true;
      } else {
        this.hideAppBar = false;
      }

      this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>


<style lang="scss" scoped>
.app-bar-hidden {
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
  opacity: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
}
/*
.brand-name{
  margin-left: 20%
}*/

.switchlan {
  background-color: $primary;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  padding: 3px;
  span {
    display: inline-block;
    padding: 0 5px;
    &.active {
      height: 24px;
      width: 24px;
      text-align: center;
      background-color: #fff;
      color: $primary;
      border-radius: 100%;
    }
  }
}

.homeCard {
  .helpCards {
    background-color: #4069e5;
    width: 100%;
    height: 98px;
    border-radius: 8px;
    border: 1px solid #bcc1ca2b;
  }
}

.sign-up-btn {
  border: 1px solid #fff;
}

.helpIcon {
  max-height: 64px;
  max-width: 64px;
}
.nouserimg {
  padding: 5px;
  background-color: #cbcbcb;
  border-radius: 100%;
}

.mcbt{
  position: relative;
  .num{
    position: absolute;
    top: -7px;
    right: 3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: $primary;
    border-radius: 20px;
    text-align: center;
    font-size: 10px;
    line-height:20px;
  }
}
</style>
