<template>
  <div class="address">
    <top-bars :title="$t('account_address_addrs_title')"></top-bars>
    <div class="main pa-4">
      <v-form v-model="valid" ref="form">
        <p class="mb-2">{{$t('account_address_tsymbol')}}</p>
        <v-select
          :disabled="!!user?.wallet?.chain"
          size="small"
          filled
          class="filledSelect2 noborder"
          v-model="userWallet.chain"
          :items="items"
          label=""
          item-text="state"
          item-value="abbr"
        ></v-select>
        <p class="mt-6 mb-2">{{$t('account_address_uaddress')}}</p>
        <v-textarea
          :disabled="isSubmit"
          rows="2"
          auto-grow
          class="filledInput2 pt-0"
          label=""
          v-model="userWallet.addr"
          hide-details="auto"
          :rules="rules"
          :placeholder="$t('account_address_addrs_txtf_pholder')"
        ></v-textarea>
        <p></p>
        <div class="spcr"></div>
        <v-btn
          v-if="!isSubmit"
          :loading="loading"
          class="filledBtn"
          size="small"
          @click="submit"
        >
          {{$t('account_address_confirm_btn')}}
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import TopBars from "@/components/TopBars.vue";
import userApi from "@/api/user";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: { TopBars },
  data() {
    return {
      rules: [(v) => !!v || $t('account_address_empty_addrs_warn')],
      valid: false,
      items: [
        { state: "USDT-TRC20", abbr: "TRC-20" },
        { state: "USDT-ERC20", abbr: "ERC-20" },
      ],
      loading: false,
      userWallet: {
        addr: "",
        chain: "TRC-20", //default
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    isSubmit() {
      return this.user && !!this.user?.wallet?.addr;
    },
  },
  created() {
    this.getUserWallet();
  },
  watch: {
    user(newValue) {
      if (newValue && newValue.wallet) {
        this.userWallet.addr = newValue.wallet.addr;
        this.userWallet.chain = newValue.wallet.chain;
      }
    },
    "userWallet.chain": {
      handler() {
        this.uAR();//updateAddressRules
      },
      deep: true,
    },
    "userWallet.addr": {
      handler() {
        this.uAR();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions({
      getInfo: "user/getInfo",
      addWallet: "user/addWallet",
    }),
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.user) {
        this.$router.push({ path: "/login" });
        return;
      }

      this.loading = true;
      let data = {};

      if (this.user.wallet) {
        if (this.user.wallet.chain && !this.user.wallet.addr) {
          data = {
            addr: this.userWallet.addr,
          };
        } else if (this.user.wallet.addr && !this.user.wallet.chain) {
          data = {
            chain: this.userWallet.chain,
          };
        } else {
          data = {
            addr: this.userWallet.addr,
            chain: this.userWallet.chain,
          };
        }
      } else {
        data = this.userWallet;
      }

      try {
        let res = await this.addWallet(data);
        if (res) {
          this.setSnackbar({
            snackbarSuccess: true,
            snackbar: true,
            snackbarMsg: res.data.msg,
          });

          if (res.data && res.data.wallet) {
            this.userWallet.addr = res.data.wallet.addr;
          }
        }
      } catch (error) {
        console.error("Error while adding wallet:", error);
      } finally {
        this.loading = false;
      }
    },
    async getUserWallet() {
      let res = await this.getInfo();
      if (res && res.data && res.data.wallet) {
        this.userWallet.chain = res.data.wallet.chain;
        this.userWallet.addr = res.data.wallet.addr;
      }
    },
    uAR() {
      if (this.userWallet.chain == "TRC-20") {
        this.rules = [
          (v) => !!v || this.$t('account_address_empty_addrs_warn'),
          (v) =>
            /^T.{33}$/.test(v) || this.$t('account_address_trc_warn'),
        ];
      } else if (this.userWallet.chain == "ERC-20") {
        this.rules = [
          (v) => !!v || this.$t('account_address_empty_addrs_warn'),
          (v) =>
            /^0x.{40}$/.test(v) || this.$t('account_address_erc_warn'),
        ];
      }
    },
    contact() {},
  },
};
</script>




<style scoped lang="scss">
.address {
  background-color: $background;
}
</style>
