<template>
  <div class="download">
    <top-bars :light="false"></top-bars>
    <div class="card">
      <div ref="qrcode" class="qr-code-container" @click="downloadQRCode"></div>
      <p class="fs-24 white--text text-center mt-4 mb-5">
        <b>{{ $t("account_download_dtitle") }}</b>
      </p>
      <p class="px-7 white--text" style="max-width: 500px; margin: auto">
        {{ $t("account_download_download_des") }}
      </p>
      <div>
        <div class="dnbtn d-flex" @click="redirect('ps')">
          <div class="pr-3">
            <v-img width="35" :src="require('@/assets/googlep.png')"></v-img>
          </div>
          <div>
            <p class="body-2">{{ $t("account_download_gio") }}</p>
            <p class="fs-18">{{ $t("account_download_gplay") }}</p>
          </div>
        </div>
        <div class="dnbtn d-flex" @click="redirect('as')">
          <div class="pr-3">
            <v-icon color="#fff" size="40">mdi-apple</v-icon>
          </div>
          <div>
            <p class="body-2">{{ $t("account_download_dot") }}</p>
            <p class="fs-18">{{ $t("account_download_astore") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import TopBars from "@/components/TopBars.vue";
import { mapGetters } from "vuex";

export default {
  components: { TopBars },
  data() {
    return {
      iosLink: "",
      androidLink: "",
      cs: "",
    };
  },

  computed: {
    ...mapGetters({
      settingGlobal: "settingGlobal",
    }),
  },
  watch: {
    settingGlobal(newValue) {
      if (newValue.iosLink) {
        console.log("iOS Link:", newValue.iosLink);
        this.iosLink = newValue.iosLink;
      } else {
        console.warn("iOS link not available");
      }

      if (newValue.androidLink) {
        console.log("Android Link:", newValue.androidLink);
        this.androidLink = newValue.androidLink;
      } else {
        console.warn("Android link not available");
      }

      this.generateQRCode();
    },
  },

  mounted() {
    this.iosLink = this.settingGlobal.iosLink || "";
    this.androidLink = this.settingGlobal.androidLink || "";

    if (this.iosLink || this.androidLink) {
      this.generateQRCode();
    }
  },

  methods: {
    redirect(key) {
      const link = key === "ps" ? this.androidLink : this.iosLink;
      if (link) {
        window.open(link, "_blank");
      } else {
        console.log("No redirect link available");
      }
    },
    generateQRCode() {
      const isAndroid = /android/i.test(navigator.userAgent);
      const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);
      let qrData = "";

      if (isAndroid) {
        qrData = this.androidLink;
      } else if (isIOS) {
        qrData = this.iosLink;
      } else {
        qrData = this.androidLink; // Fallback URL
      }

      if (!qrData) {
        console.log("No download link available for QR code");
        return;
      }

      this.$refs.qrcode.innerHTML = ""; // Clear previous QR code

      new QRCode(this.$refs.qrcode, {
        text: qrData,
        width: 180,
        height: 180,
        colorDark: "#ffffff", // White bars
        colorLight: "#00000000", // Transparent background
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    downloadQRCode() {
      const canvas = this.$refs.qrcode.querySelector("canvas");
      if (canvas) {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "qrcode.png";
        link.click();
      } else {
        console.warn("QR code canvas not found");
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.download {
  padding: 0 0 90px !important;
  overflow: hidden;
  height: 100vh;
  background-color: #fff;

  .card {
    background-color: $primary;
    line-height: 22px;
    height: 100vh;
    position: relative;
    padding-bottom: 150px;

    .qr-code-container {
      margin: auto;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 180px;
      background: transparent;
    }

    .dnbtn {
      background: #171a1fff; /* neutral-900 */
      border-radius: 8px;
      width: 235px;
      color: #fff;
      padding: 10px;
      margin: auto;
      margin-top: 20px;
      &:hover{
      cursor: pointer;
      }
      .body-2 {
        color: #a7adb7ff;
      }
      > div {
        &:nth-child(1) {
          flex: 0 0 35px;
        }
      }
    }
  }
}
</style>
