<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-main>
      <router-view class="commonVuetify"></router-view>
    </v-main>
  </v-app>
</template>
  
  <script>
import TopBars from "@/components/TopBars.vue";
export default {
  components: { TopBars },
  data() {
    return {};
  },
};
</script>
  
  <style lang="scss" scoped>
</style>
  