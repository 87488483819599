<template>
  <div class="statement">
    <top-bars :title="$t('account_statement_statement')" :light="false"></top-bars>
    <div class="main mt-4">
      <div class="card pa-4">
        <span class="topicon"></span>

        <div class="transactionmain mt-10" id="scrolldiv">
          <div v-if="dataList.length">
            <div
              class="d-flex box pa-3 align-center mb-4"
              v-for="item in dataList"
              :key="item._id"
            >
              <div class="img stmtbg">
                <v-img class = "white-icon" 
                  width="24" 
                  :src="item._type[0] === 'deposit' 
                    ? require('@/assets/offerhelp.png') 
                    : item._type[0] === 'withdraw' 
                    ? require('@/assets/gethelp.png') 
                    : item._type[0] === 'comm'
                    ? require('@/assets/commission.png')
                    : require('@/assets/perks.png')">
                </v-img>
              </div>
              <div class="pl-2">
                <p class="fw-700">{{ item._type[1] }}</p>
                <p>{{ item.create_time }}</p>
              </div>
              <v-spacer></v-spacer>
              <div>
                <span
                  class="fw-700"
                  :class="item._type[2] ? 'primary--text' : 'error--text'"
                >
                  {{ $formatUS(item.amount,2) }}
                </span>
              </div>
            </div>
          </div>
          <nodata
            :loading="loading"
            :scroll="scroll"
            :data-list="dataList"
          ></nodata>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TopBars from "@/components/TopBars.vue";
import ChartPie from "../../components/ChartPie.vue";
import Nodata from "../../components/Nodata.vue";
import { mapGetters, mapMutations } from "vuex";
import userApi from "@/api/user.js";
import Bus from '@/utils/bus'
export default {
  components: { TopBars, ChartPie, Nodata },
  data() {
    return {
      value1: 60,
      value2: 70,
      dataList: [],
      skip: 0,
      limit: 20,
      loading: false,
      scroll: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  mounted() {

      Bus.$on("querybill",(val)=>{
        if(val){
          this.skip = 0
          this.dataList = []
          this.getList()
        }
      })

    let self = this;
    self.getList();
    const element = document.getElementById("scrolldiv");
    element.addEventListener("scroll", function () {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        if (self.scroll) {
          self.loading = true;
          self.getList();
        }
      }
    });
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    async getList() {
      let data = {
        limit: this.limit,
        skip: this.skip * this.limit,
      };
      this.loading = true;
      let res = await userApi.balanceDetails(data);
      this.loading = false;
      if (res) {
        let datas = res.data.map((item) => {
          item.create_time = this.$DateUS(item.create_time);
          return item;
        });
        this.dataList = this.dataList ? this.dataList.concat(datas) : datas;

        if (res.data.length == this.limit) {
          this.scroll = true;
          this.skip++
        } else {
          this.scroll = false;
        }
      } else {
        this.scroll = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.statement {
  background-color: $primary;
  overflow: hidden;
  #scrolldiv {
    height: calc(100vh - 140px);
  }
  .main {
    .card {
      background: #f2f3f4ff;
      border-radius: 35px 35px 0px 0px;
      box-shadow: 0px 4px 9px #171a1f;
      min-height: calc(100vh - 66px);
      position: relative;
      .topicon {
        width: 50px;
        height: 4px;
        background: #dee1e6ff; /* neutral-300 */
        border-radius: 2px;
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 20px;
      }
      .tagp {
        span {
          display: inline-block;
          padding: 6px 30px;
          font-size: 16px;
          border-radius: 18px;
          &.active {
            font-size: 18px;
            background-color: #dee1e666;
            font-weight: bold;
          }
        }
      }
      .circular {
        width: 150px;
        height: 150px;
        position: relative;
        > * {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .right {
        .font {
          position: relative;
          padding-left: 20px;
          &::before {
            display: inline-block;
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: $primary;
            top: 7px;
            left: 3px;
            position: absolute;
          }
          &::after {
            display: inline-block;
            content: "";
            width: 18px;
            height: 18px;
            border-radius: 18px;
            border: 1px solid $primary;
            top: 3px;
            left: -1px;
            position: absolute;
          }
          &:last-child {
            &::before {
              background-color: rgb(246, 77, 78);
            }
            &::after {
              border-color: rgb(246, 77, 78);
            }
          }
        }
      }
    }
    .transactionmain {
      min-height: calc(100vh - 400px);
      .box {
        background: #4069e503; /* tertiary1-500 */
        border-radius: 8px; /* border-xl */
        border: 1px solid #bcc1caff;
      }
    }
  }
  .stmtbg {
    background-color: $primary;
    border-radius: 5px;
    padding: 10px;
  }
}
</style>
