import { watch } from "vue";
import orderApi from "@/api/order";
import { getLocalTime } from '@/utils/index'
import Bus from '@/utils/bus'
export const mixins = {
  data: () => ({
    dataList: [],
    skip: 0,
    limit: 20,
    scroll: false,
    loading: false,
    tagactive: 0,
    offsetHours: (new Date().getTimezoneOffset()) / 60 * -1

  }),

  mounted() {
    Bus.$on("changeTab",(val)=>{
      if(val){
        this.tagactive = 1
      }
    })

    let self = this;
    self.getList();
    const element = document.getElementById("scrolldiv");
    element.addEventListener("scroll", function () {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        console.log("滚动到底部");
        if (self.scroll) {
          self.loading = true;
          self.getList();
        }
      }
    });
    
  },
  watch: {
    tagactive(n,o) {
      if (n > -1) {
        const element = document.getElementById("scrolldiv");
        element.scrollTo(0, 0);
        this.dataList = []
        this.skip = 0;
        this.getList();
      }
    }
  },
  methods: {
    async getList() {     
      let status = this.tagAry[this.tagactive].key;
      let apistatus = status.indexOf(",") > -1 ? status.split(",") : [status];
      let data = {
        _type: this.orderType,
        status: apistatus,
        skip: this.dataList.length,
        limit: this.limit,
      };

      this.loading = true;
      let res = await orderApi.queryOrders(data)
      if (res) {
        let datas = res.data.map((item) => {
          item.create_time = this.$DateUS(item.create_time)
          item.close_time = this.$DateUS(item.close_time)
          return item
          //
          return this.startCountdown(item);
        });
        this.dataList = this.dataList
          ? this.dataList.concat(datas)
          : datas;
        if (res.data.length == this.limit) {
          this.skip += 1;
          this.scroll = true;
        } else {
          this.scroll = false;
        }
      } else {
        this.dataList = [];
        this.scroll = false;
      }
      this.loading = false;
    },
    startCountdown(item) {
      let itemObj = { ...item };
      let createTime = new Date(item.create_time.replace(/\//g, "-")).getTime();
      let closeTime = new Date(item.close_time.replace(/\//g, "-")).getTime();
      let todayTime = getLocalTime(7).getTime();
      console.log("todayTime", getLocalTime(7));
      let totalTime = Math.floor((closeTime - createTime) / 1000);
      let start = Math.floor((todayTime - createTime) / 1000);
      let restTime = totalTime - start;
      if (todayTime < createTime || todayTime > closeTime) {
        itemObj.countdown = "00:00:00";
        itemObj.progress = 100;
        itemObj.isClose = true;
        return itemObj;
      }
      console.log("totalTime", totalTime);
      console.log("start", start);
      console.log("restTime", restTime);

      let progress = 100 / totalTime;

      let h = "00";
      let m = Math.floor(restTime / 60);
      m = m < 10 ? "0" + m : m;
      let s = restTime % 60;
      s = s < 10 ? "0" + s : s;
      itemObj.countdown = h + ":" + m + ":" + s;
      itemObj.progress = (start / totalTime) * 100;

      itemObj.refreshData = window.setInterval(() => {
        restTime--;
        totalTime--;

        itemObj.progress += progress;
        let m = Math.floor(restTime / 60);
        m = m < 10 ? "0" + m : m;
        let s = restTime % 60;
        s = s < 10 ? "0" + s : s;
        itemObj.countdown = h + ":" + m + ":" + s;

        if (restTime < 0) {
          window.clearInterval(itemObj.refreshData);
          itemObj.countdown = "00:00:00";
          itemObj.progress = 100;
          itemObj.isClose = true;
        }
      }, 1000);
      return itemObj;
    },
  },
}
