<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-app-bar app dense flat color="#3d6beb">
      <v-btn icon @click="moveBack">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-row no-gutters class="align-center justify-center">
        <v-col cols="12" class="d-flex flex-column align-center">
          <div class="containerTri">
            <v-row no-gutters class="align-center justify-center">
              <v-col cols="12" class="d-flex align-center justify-center">
                <div class="combined-section">
                  <div class="text-section">
                    <h1 class="white--text text-center fs-29 fw-700">
                      {{$t('register_register_title')}}
                    </h1>
                    <p class="white--text text-center fs-16 ff-ep fw-400">
                     {{$t('register_register_subtitle')}}
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="triangle">
              <img src="@/assets/triangle.png" alt="Triangle" />
              <h1 class="login-text fs-33 fw-700">{{$t('register_register_title')}}</h1>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="form-wrapper pa-4">
        <v-form v-model="valid" ref="form">
          <!-- Email Login -->
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                :placeholder="$t('register_reg_email')"
                :rules="emailRules"
                class="fs-15 fw-400"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Password Field   -->
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                :placeholder="$t('account_index_password')"
                :type="passwordVisible ? 'text' : 'password'"
                class="fs-15 fw-400"
                color="primary"
                :rules="passwordRules"
                @focus="showPasswordRequirements"
                @input="checkPasswordRequirements"
              >
                <template v-slot:append>
                  <v-icon @click="togglePasswordVisibility">
                    {{ passwordVisible ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- Password Requirements -->
          <v-row
            no-gutters
            v-if="(!meetrequirement1 || !meetrequirement2 || !meetrequirement3) && password.length>0"
          >
            <v-col cols="12">
              <p
                :class="requirementClass(meetrequirement1)"
                class="mt-1 d-flex"
              >
                <v-icon
                  size="20"
                  class="mr-2"
                  left
                  :color="getRequirementIconColor(meetrequirement1)"
                >
                  mdi-check-circle
                </v-icon>
                <span> {{$t('register_reg_met1')}} </span>
              </p>
            </v-col>

            <v-col cols="12">
              <p
                :class="requirementClass(meetrequirement2)"
                class="mt-1 d-flex"
              >
                <v-icon
                  size="20"
                  class="mr-2"
                  left
                  :color="getRequirementIconColor(meetrequirement2)"
                >
                  mdi-check-circle
                </v-icon>
                <span> {{$t('register_reg_met2')}} </span>
              </p>
            </v-col>

            <v-col cols="12">
              <p
                :class="requirementClass(meetrequirement3)"
                class="mt-1 d-flex"
              >
                <v-icon
                  size="20"
                  class="mr-2"
                  left
                  :color="getRequirementIconColor(meetrequirement3)"
                >
                  mdi-check-circle
                </v-icon>
                <span> {{$t('register_reg_met3')}} </span>
              </p>
            </v-col>
          </v-row>

          <!-- Invitation code field -->
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="ic"
                :placeholder="$t('register_reg_ic')"
                class="fs-15 fw-400"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Next Button -->
          <v-row no-gutters class="mt-8 mb-4">
            <v-col cols="12">
              <v-btn
                large
                class="py-6 body-1 w100 rounded-xxl"
                style="color: #fff"
                height="44px"
                color="#4b82ed"
                @click="submit"
                :loading="loading"
              >
                {{$t('register_reg_next_btn')}}
              </v-btn>
            </v-col>
          </v-row>

          <!-- Create Account -->
          <v-row no-gutters class="mt-1 align-center">
            <v-col cols="12" class="d-flex justify-center">
              <p class="text-forgpass mb-0 fs-15 fw-400 mt-7">
                {{$t('register_reg_alreadyaccount')}}
                <router-link
                  :to="ic ? { path: '/login', query: { ic } } : '/login'"
                  class="grey-text ml-2 fw-700 fs-15"
                >
                  {{$t('register_reg_login')}}
                </router-link>
              </p>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-main>
    <transition :name="transitionName" mode="in-out" appear>
      <verify-email
        @backFun="backFun"
        v-show="verifyShow"
        :userinfo="{ email: email, password: password, ic: ic,tz:timeZone||'' }"
      ></verify-email>
    </transition>
  </v-app>
</template>


<script>
import { mapGetters, mapMutations } from "vuex";
import authApi from "@/api/auth";
import VerifyEmail from "./VerifyEmail.vue";

export default {
  components: { VerifyEmail },
  data() {
    return {
      timeZone:'',
      email: "",
      password: "",
      ic: "",
      passwordVisible: false,
      valid: false,
      loading: false,
      showRequirements: false,
      emailRules: [
        (v) => !!v || this.$t('register_reg_email_required'),
        (v) => /.+@.+\..+/.test(v) || this.$t('register_reg_email_valid'),
      ],
      passwordRules: [
        (v) => v.length >=8,
        (v) => /[0-9]/.test(v),
        (v) => /[a-z]/.test(v) && /[A-Z]/.test(v)
      ],
      meetrequirement1: false,
      meetrequirement2: false,
      meetrequirement3: false,
      verifyShow: false,
      transitionName: "left",
    };
  },
  computed: {
    ...mapGetters(["invitationCode", "settingGlobal"]),
  },
  watch: {
    verifyShow() {
      this.transitionName = this.verifyShow ? "left" : "right";
    },
  },
  created() {
    this.getUserTime();
    if (this.invitationCode) {
      this.ic = this.invitationCode;
    } else {
       let ic =  window.localStorage.getItem('invitationCode')
       if(ic){
        this.ic = ic;
       }else{
         this.ic = "";
       }
    }
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    backFun() {
      this.verifyShow = false;
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    showPasswordRequirements() {
      this.showRequirements = true;
    },
    checkPasswordRequirements() {
      const minLength = 8;
      this.meetrequirement1 = this.password.length >= minLength;
      this.meetrequirement2 = /[0-9]/.test(this.password);
      this.meetrequirement3 =
        /[a-z]/.test(this.password) && /[A-Z]/.test(this.password);
    },
    getRequirementIconColor(isMet) {
      return isMet ? "green" : "#171A1FFF";
    },
    requirementClass(isMet) {
      return isMet ? "met" : "unmet";
    },
    async submit() {
      this.loading = true;
      this.checkPasswordRequirements();

      // Validate password requirements and form
      if (
        this.meetrequirement1 &&
        this.meetrequirement2 &&
        this.meetrequirement3 &&
        this.$refs.form.validate()
      ) {
        let hasInvalidInvitationCode = false;

        // Check for invitation code if it exists
        if (this.ic) {
          let icres = await authApi.icVerification({ ic: this.ic });
          this.loading = false;
          if (icres && icres.data && icres.data.error) {
            this.notiFy(false, icres.data.error);
            hasInvalidInvitationCode = true;
          }
        }
        let res = await authApi.verifyEmail({
          email: this.email.replace(/^([^@]+)/, match => match.toLowerCase()),
          _type: "register",
        });
        this.loading = false;

        if (res) {
          if (res.data && res.data.error) {
            this.notiFy(false, res.data.error);
          } else {
            this.notiFy(true, res.data.msg);
            this.verifyShow = true;
            if (hasInvalidInvitationCode) {
              console.log("Invitation code was invalid, but verification email sent.");
            }
          }
        }
      } else {
        this.loading = false;
      }
    },
    moveBack() {
      this.$router.go(-1);
    },
    getUserTime() {
      this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const offsetMinutes = new Date().getTimezoneOffset();
      const offsetHours = Math.abs(offsetMinutes / 60);
      console.log(this.timeZone);
    },
  },
};
</script>


<style scoped lang="scss">
.containerTri {
  position: relative;
  width: 100%;
  background-color: #3d6beb;
  overflow: hidden;
}

.triangle {
  position: relative;
  width: 100%;
  height: auto;
  display: block;
}

.triangle img {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 20%;
}

.combined-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 54px;
  width: 54px;
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10px;
}

.login-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #171a1fff;
}

.form-wrapper {
  background: #f2f3f4ff;
  height: 50vh;
}

.met {
  color: green !important;
}

.unmet {
  color: $main-font-color !important;
}

.v-text-field__slot {
  border-bottom: 2px solid #4b82ed !important;
}
</style>
