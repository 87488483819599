import { render, staticRenderFns } from "./OfferHelp.vue?vue&type=template&id=c48af312&scoped=true"
import script from "./OfferHelp.vue?vue&type=script&lang=js"
export * from "./OfferHelp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c48af312",
  null
  
)

export default component.exports