import axios from 'axios';
import { tansParams } from '@/utils/index';
import cache from '@/plugins/cache';
import store from '@/store/index';
import router from '../router';
import Cookies from 'js-cookie';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers['Content-Language'] = 'zh_CN';

const isProd = process.env.NODE_ENV === 'production';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000000
});

service.interceptors.request.use(config => {
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }

  if (config.method === 'post' || config.method === 'put') {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    };
    const sessionObj = cache.session.getJSON('sessionObj');
    if (!sessionObj) {
      cache.session.setJSON('sessionObj', requestObj);
     
    } else {
      const { url: s_url, data: s_data, time: s_time } = sessionObj;
      const interval = 3000;
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        // const message = 'Data is being processed, please do not submit again.';
        console.warn(`[${s_url}]: ` + s_data);
      } else {
        cache.session.setJSON('sessionObj', requestObj);
      }
    }
  
  }

  return config;
}, error => {
  console.log(error);
  return Promise.reject(error);
});

service.interceptors.response.use(
  (res) => {
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data;
    }

    if (res.status === 200 && res.data.error) {
      let errorMessage = res.data.error;

      if (errorMessage.includes(':')) {
        errorMessage = errorMessage.split(':')[1].split('[')[1].split(']')[0].replace(/'/g, '');
      }

      console.log("Parsed error message:", errorMessage);

      store.commit('setSnackbar', {
        snackbarSuccess: false,
        snackbarMsg: errorMessage,
        snackbar: true,
      });

//      return Promise.reject(new Error(errorMessage));
    } else {
      return Promise.resolve(res.data);
    }

  }, error => {
  console.log(error);
  if (error.response) {
    const message = error.response.data.error || error.message;
    const code = error.response.status;
    // const currentPath = window.location.pathname;
    console.log(code)
    if (code === 500 || code === 504) {
    } else
      if (code === 403) {
        const currentPath = window.location.pathname;
        const allowedRoutes = ["/home", "/login", "/register", "/account", "/download", "/policy"];
        if (!allowedRoutes.includes(currentPath)) {
          router.replace({ path: "/login" });
          console.log("Session expired, need to login again.");
        }
        store.commit('user/setUser', null)

      }
    return Promise.reject(message);
  }
});

const request = (config, options) => {
  if (typeof config === 'string') {
    return service.request({ url: config, ...options });
  } else {
    return service.request(config);
  }
};

export function post(config, options) {
  return request({ ...config, method: 'POST' }, options);
}

export default request;
