<template>
  <div class="faq">
    <top-bars :light="false"> </top-bars>
    <div class="card pa-4">
      <span class="topicon"></span>
      <p class="title">{{$t('policy_faq_faq_title')}}</p>

      <div class="tagslidegroup mb-4">
        <v-slide-group v-model="tagactive">
          <v-slide-item
            v-for="(tag, index) in tagAry"
            :key="index"
            v-slot="{ active, toggle }"
          >
            <v-btn depressed @click="toggle" :class="active ? 'active' : ''">
              {{ tag.label }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </div>

      <div class="listbox" id="scrolldiv">
        <v-list>
          <v-list-group
            v-for="(item, index) in currentFaqList"
            :key="index"
            v-model="item.active"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content class="primary-text body-1">
                {{ item.question }}
              </v-list-item-content>
            </template>
            <v-list-item>
              <v-list-item-content>
                <div v-html="item.answer"></div>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import TopBars from "@/components/TopBars.vue";
import settingApi from "@/api/setting";

export default {
  components: { TopBars },
  data() {
    return {
      tagAry: [],
      dataList: {},
      tagactive: 0,
    };
  },
  computed: {
    currentFaqList() {
      const activeTag = this.tagAry[this.tagactive]?.key;
      return this.dataList[activeTag] || [];
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
  async getList() {
 const params = {
   skip: 0,
   limit: 0,
 };

 try {
   let res = await settingApi.textFaq(params);

   if (res) {
     this.processFaqData(res.data);
   }
 } catch (error) {
   console.error("Error fetching FAQ data:", error);
 }
},
    processFaqData(data) {
      this.dataList = data;

      this.tagAry = Object.keys(data).map((key) => ({
        label: key,
        key: key,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.faq {
  min-height: calc(100vh - 50px);
  background-color: $primary;
  .card {
    background: $background;
    border-radius: 30px 30px 0px 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: calc(100vh - 60px);
    overflow: auto;
    .topicon {
      width: 50px;
      height: 4px;
      background: #dee1e6ff; /* neutral-300 */
      border-radius: 2px;
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 20px;
    }
    .title {
      font-weight: 700;
      font-size: 32px !important;
      text-align: center;
      padding: 40px 0 20px;
    }
    .btoBtn {
      margin-top: 30px;
      span {
        flex: 1;
        a {
          display: inline-block;
          padding: 15px 10px;
        }
        &:nth-child(1) {
          border-top-right-radius: 6px;
        }
      }
    }
    .listbox {
      overflow: auto;
    }
  }
}
#scrolldiv {
  max-height: calc(100vh - 155px);
  height: auto;
  min-height: 60vh;
}
</style>
