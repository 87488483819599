<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <top-bars :title="$t('gethelp_help_gethelp_title')" :showBack="false"> </top-bars>
    <v-main>
      <div class="records">
        <div class="tagslidegroup mb-0 pa-4">
          <v-slide-group v-model="tagactive">
            <v-slide-item
              v-for="(item, index) in tagAry"
              :key="index"
              v-slot="{ active, toggle }"
            >
              <v-btn depressed @click="toggle" :class="active ? 'active' : ''">
                {{ item.label }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </div>

        <div id="scrolldiv" class="px-4">
          <div v-for="(item, index) in dataList" :key="index"  
          :class="[
          'cardprimary mb-4 pa-3',
          (item.status === 'pending' || item.status === 'passed' || item.status === 'matched')
            ? (index % 2 === 0 ? 'bluebg' : 'whitebg')
            : 'greybg'
        ]">
            <ul>
              <li class="d-flex">
                <span>{{$t('gethelp_record_gethelp_record_amount')}}</span>
                <v-spacer></v-spacer>
                <span>{{ $formatUS(item.amount,2) }}</span>
              </li>
              <li v-if=" ['matched', 'done', 'settled'].includes(item.status) && item.d_info && item.d_info.length" class="d-flex">
                <span>Due</span>
                <v-spacer></v-spacer>
                <span>{{ $formatUS(item.r_amount,2) }}</span>
              </li>
              <v-divider v-if="['pending', 'revoked'].includes(item.status)" style="border-color: #bcc1ca8a; border-width: 1px" class="my-1"></v-divider>
              <li class="d-flex">
                <span>{{$t('gethelp_record_gethelp_record_timestamp')}}</span>
                <v-spacer></v-spacer>
                <span>{{ $DateUS(item.create_time) }}</span>
              </li>
                <li class="remark d-flex" v-if="item.remark !== undefined">
                  <span class="text" :class="item.open ? '' : 'text-hidden'">
                    {{ item.remark || "No remark available" }}
                  </span>
                  <span
                    class="icon"
                    v-show="item.remark && item.remark.length > 100"
                    @click="openClick(item, index)"
                  >
                    <v-icon>{{
                      item.open ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </span>
                </li>
              <v-divider v-if="['matched', 'done', 'settled'].includes(item.status) && item.d_info && item.d_info.length" style="border-color: #bcc1ca8a; border-width: 1px" class="my-1"></v-divider>

              <!-- Only display subOrders if main item status is 'pending' -->
              <div v-if="['matched', 'done', 'settled'].includes(item.status) && item.d_info && item.d_info.length" v-for="(d_info, subIndex) in item.d_info" :key="subIndex">
                <ul>
                  <li class="d-flex mt-5">
                    <span v-if= "item.d_info && item.d_info.length > 1" class="black--text rounded-circle fs-12 mb-2 d-flex align-center justify-center" 
                    style="background-color: white; width: 23px; height: 23px; display: inline-flex; padding: 0;">
                {{ subIndex + 1 }}
              </span>

                    <v-spacer></v-spacer>
                    <span>ID</span>
                    <span class = "ml-1">{{ d_info.d_uid }}</span>
                  </li>
                  <li class="d-flex">
                    <span>{{$t('gethelp_record_gethelp_record_amount')}}</span>
                    <span class = "ml-1">{{ $formatUS(d_info.amount,2) }}</span>
                    <v-spacer></v-spacer>
                    <span>Network </span>
                    <span class = "ml-1">{{ item.chain }}</span>
                  </li>
                  <li class="d-flex">
                    <span>Address</span>
                    <v-spacer></v-spacer>
                    <span class="text-ellipsis">{{  d_info.d_addr }}</span>
                      <v-icon
                        class="ml-1"
                        size="16"
                        :color="index % 2 === 0 ? 'white' : 'grey'"
                        @click="copyText($event, d_info.d_addr)"
                        >mdi-content-copy</v-icon
                      >
                  </li>
                  <li class="d-flex">
                    <span>Email</span>
                    <v-spacer></v-spacer>
                    <span class="text-ellipsis">{{  d_info.d_email }}</span>
                      <v-icon
                        class="ml-1"
                        size="16"
                        :color="index % 2 === 0 ? 'white' : 'grey'"
                        @click="copyText($event, d_info.d_email)"
                        >mdi-content-copy</v-icon
                      >
                  </li>
                <li 
                v-if="['matched', 'passed', 'matched'].includes(item.status)" class="d-flex">
                <div class="mt-7 countdown-container">
                  <count-down 

                    :status="d_info.d_status" 
                    v-if="d_info.confirm_expire_time&&d_info.w_status!='done'" 
                    :endTime="d_info.count_down">
                  </count-down>
                </div>
              </li>
                  <!--img below-->
                  <li
                  v-if="d_info.img" 
                  class="imgwrap d-flex align-center justify-center">
                    <div class="card w100 mt-5" @click="zoomImg(d_info)">
                      <div class="text-center uploadbox">
                        <div
                          class="imgwrap d-flex align-center justify-center"
                          @click="zoomImg(d_info)"
                        >
                          <auth-img max-width="190px" max-height="208px"  :src="d_info.img" />
                      </div>
                      </div>
                    </div>
                  </li>
                  <li class="d-flex">
                    <v-btn
                    v-if="d_info.d_status == 'matched'"
                       :loading="loadingStates[`${index}-${subIndex}`]"
                      @click="opentip"
                      :class="['cardprimary mt-5 mb-2 w100 pa-3', (item.status === 'pending' || item.status === 'passed' || item.status === 'matched') ? (index % 2 === 0 ? 'whitebg black--text' : 'primary white--text') : 'primary white--text' ]"
                    
                    >
                      {{ d_info.w_status === 'done' ? 'Verified' : $t('gethelp_record_gethelp_record_confirm_button') }}
                    </v-btn>

                       <v-btn
                       v-else
                       :loading="loadingStates[`${index}-${subIndex}`]"
                      @click="d_info.w_status !== 'done' && d_info.d_status ==='paid' && confirmFun(item, index, d_info, subIndex)"
                      :class="['cardprimary mt-5 mb-2 w100 pa-3', (item.status === 'pending' || item.status === 'passed' || item.status === 'matched') ? (index % 2 === 0 ? 'whitebg black--text' : 'primary white--text') : 'primary white--text' ]"
                    
                    >
                      {{ d_info.w_status === 'done' ? 'Verified' : $t('gethelp_record_gethelp_record_confirm_button') }}
                    </v-btn>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
          <nodata :loading="loading" :scroll="scroll" :dataList="dataList" bottom="80"></nodata>
        </div>


        <div class="zoom" v-if="showZoomOverlay" @click="toggleCloseOverlay">
          <div class="cancelbox" @click.stop.prevent>
            <div class="cardprimary cancelOverlay">
              <v-container fluid fill-height>
                <v-row align="center" justify="center" class="text-center">
                  <v-col cols="12">
                    <div v-if="zmdImg">
                      <auth-img :src="zmdImg" height="auto" :style="{ maxHeight: '80vh', borderRadius: '5px' }" />
                    </div>
                    <!--<div v-else>
                      <p>Payment not done</p>
                    </div>-->
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>

      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mixins } from "@/mixin/scroll.js";
import Nodata from "../../components/Nodata.vue";
import TopBars from "../../components/TopBars.vue";
import CountDown from "@/components/GetHelp/CountDown.vue";
import { mapMutations ,mapGetters} from "vuex";
import orderApi from "@/api/order";
import { copyText } from "@/utils/index.js";

import Bus from '@/utils/bus'
import AuthImg from '@/components/AccountCont/authImg.vue';
export default {
  components: { Nodata, TopBars, CountDown, AuthImg },
  mixins: [mixins],
  data() {
    return {
      orderType: "withdraw",
      // Mock data
      mock_info: {
        d_status: "matched", // or "paid"
        pay_time: ["2024-11-29T10:00:00", "2024-11-29T12:00:00"] // Mock end time array
      },
      mockEndTime: ["2024-11-28T10:00:00", "2024-11-29T22:00:00"],
      tagAry: [
        { label: this.$t('gethelp_record_gethelp_record_tab1'), key: "pending,passed,matched" },
        { label: this.$t('gethelp_record_gethelp_record_tab2'), key: "done,settled" },
        { label: this.$t('gethelp_record_gethelp_record_tab3'), key: "revoked" },
      ],
      //lIndex: null,
      loadingStates: {},
      dataList: [],
      showZoomOverlay: false,
      zmdImg: '',
    };
  },
  mounted() {
      Bus.$on("queryList",(val)=>{
        if(val){
          this.skip = 0
          this.dataList = []
          this.getList()
        }
      })
      
    this.initializeVerificationStatus();
  },
  watch: {
    dataList: {
      handler(newDataList) {
        this.updateVerificationStatus(newDataList);
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations(["setSnackbar"]),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    openClick(item, index) {
      let items = item;
      items.open = !items.open;
      this.$set(this.dataList, index, items);
    },
    zoomImg(d_info) {
    console.log("d_info received:", d_info);
    if (d_info && d_info.img) {
      this.zmdImg = d_info.img;
      this.showZoomOverlay = true;
      console.log("Zoom image set to:", this.zmdImg); 
    } else {
      console.log("No image data or invalid image data");
    }
  },
    toggleCloseOverlay() {
      this.showZoomOverlay = !this.showZoomOverlay;
    },
    initializeVerificationStatus() {
      this.dataList.forEach(item => {
        if (item.d_info && Array.isArray(item.d_status)) {
          item.d_info.forEach(d_info => {
            d_info.verified = item.d_status.includes(d_info._id);
          });
        } else if (item.d_info) {
          // Fallback if d_status is not an array
          item.d_info.forEach(d_info => {
            d_info.verified = false;
          });
        }
      });
    },

    updateVerificationStatus(newDataList) {
      newDataList.forEach(item => {
        if (item.d_info && Array.isArray(item.d_status)) {
          item.d_info.forEach(d_info => {
            d_info.verified = item.d_status.includes(d_info._id);
          });
        } else if (item.d_info) {
          // Fallback if d_status is not an array
          item.d_info.forEach(d_info => {
            d_info.verified = false;
          });
        }
      });
    },

opentip(){
this.notiFy(false, 'Your offer is currently being processed');
},
  async confirmFun(item, index, d_info, subIndex) {
    if (item.status === 'matched' && d_info.d_id) {
      const data = { _id: item._id, d_id: d_info.d_id };
      const key = `${index}-${subIndex}`;

      try {
        const occres = await orderApi.orderCanConfirm(data);
        const goahead = occres.data.can_confirm;
        if (!goahead) {
          console.log('orderCanConfirm is', goahead);
          return;
        }
        this.$set(this.loadingStates, key, true);
        const res = await orderApi.withdrawConfirm(data);
        if (res && res.data && !res.data.error) {
          this.notiFy(true, res.data.msg);
          if(res.data.done){
             Bus.$emit("changeTab",'2')
          }else{
           this.skip = 0
          this.dataList = []
           this.getList()
          }
         
          
          

        }
      } catch (error) {
        const errorMsg = error.response?.data?.message || this.$t('gethelp_record_gethelp_order_confirm_failed');
        this.notiFy(false, errorMsg);
      } finally {
        this.$set(this.loadingStates, key, false);
      }
    }
  },
    copyText(e, text) {
      copyText(e, text);
    },
  }
};
</script>

<style scoped lang="scss">
.records {
  background-color: $background;
  min-height: 100%;
  .cardprimary {
    ul {
    }
  }
  .bluebg {
    background-color: #4069e5;
    color: #fff;
    i {
      color: #fff;
    }
  }
  .whitebg {
    background-color: $background;
    color: #000;
    border: 1px solid #bcc1caff;
    i {
      color: $main-font-color;
    }
  }
}

.dialog-title {
  font-weight: bold;
  font-size: 18px;
  color: $main-font-color;
  margin-bottom: 10px;
  display: inline-block;
}

.progress-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: $main-font-color;
}

.info-icon {
  color: #4069e5;
}


.progress-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.progress-completed {
  color: $main-font-color;
  span {
  }
}

.progress-percentage {
  color: #171a1f80;
  font-size: 14px;
}

#scrolldiv {
  height: calc(100vh - 185px);
}
.remark {
  padding-top: 5px;
  .text {
    flex: 1;
    line-height: 20px;
  }
  .icon {
    flex: 0 0 20px;
  }
}
.text-ellipsis {
  display: inline-block; /* or block if necessary */
  max-width: 200px; /* Set the maximum width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card {
    border-radius: 6px;
    border: 2px solid #bcc1ca;
    padding: 10px;
    text-align: center;
  }
    .imgwrap {
    justify-content: center;
  }

.zoom {
    width: 100vw;
    height: 100vh;
    background-color: #171a1fd1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    p {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .text {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      line-height: 30px;
      .cancel {
        color: $grey-icon-color;
        margin-top: 100px;
      }
    }
    .cancelbox {
      width: 85%;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      .cancelOverlay {
        background-color: transparent;
      }
      p {
        padding-bottom: 5px;
      }
    }
  }

  .countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.countdown-container .count-down {
  width: 100%;
}
</style>
