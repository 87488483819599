import { post } from '@/utils/request.js';

const apiUrl = '/setting';

const textFaq = async (data) => post({
    url: apiUrl, data: {
        cmd: 'text_faq',
        params: data
    }
});

const textSingle = async (data) => post({
  url: apiUrl, data: {
      cmd: 'text_single',
      params: data
  }
});

const settingGlobal = async (data) => post({
  url: apiUrl, data: {
      cmd: 'setting_global',
      params: data
  }
});


export default {textFaq,textSingle,settingGlobal}
