var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statement"},[_c('top-bars',{attrs:{"title":_vm.$t('account_statement_statement'),"light":false}}),_c('div',{staticClass:"main mt-4"},[_c('div',{staticClass:"card pa-4"},[_c('span',{staticClass:"topicon"}),_c('div',{staticClass:"transactionmain mt-10",attrs:{"id":"scrolldiv"}},[(_vm.dataList.length)?_c('div',_vm._l((_vm.dataList),function(item){return _c('div',{key:item._id,staticClass:"d-flex box pa-3 align-center mb-4"},[_c('div',{staticClass:"img stmtbg"},[_c('v-img',{staticClass:"white-icon",attrs:{"width":"24","src":item._type[0] === 'deposit' 
                  ? require('@/assets/offerhelp.png') 
                  : item._type[0] === 'withdraw' 
                  ? require('@/assets/gethelp.png') 
                  : item._type[0] === 'comm'
                  ? require('@/assets/commission.png')
                  : require('@/assets/perks.png')}})],1),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"fw-700"},[_vm._v(_vm._s(item._type[1]))]),_c('p',[_vm._v(_vm._s(item.create_time))])]),_c('v-spacer'),_c('div',[_c('span',{staticClass:"fw-700",class:item._type[2] ? 'primary--text' : 'error--text'},[_vm._v(" "+_vm._s(_vm.$formatUS(item.amount,2))+" ")])])],1)}),0):_vm._e(),_c('nodata',{attrs:{"loading":_vm.loading,"scroll":_vm.scroll,"data-list":_vm.dataList}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }