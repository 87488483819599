<template>
  <v-container
      class="chartBox pa-4"
      :class="
      !user || JSON.stringify(user.levels) === '{}' ? 'chartBoxShade' : ''
    "
      fluid
  >
    <v-row>
      <v-col cols="7">
        <div id="affiliateProgramChart"></div>
      </v-col>
      <v-col cols="5">
        <div class="legend">
          <div v-for="(item, index) in options.series[0].data" :key="index">
            <v-icon class="legend-icon" :color="options.series[0].color[index]"
            >mdi-circle
            </v-icon
            >
            <p>{{ item.name }}:{{ item.value }}</p>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="mr-4 dataCont d-flex text-left">
      <div class="dataBlock mr-2 pa-4 pb-0 pt-0">
        <v-divider
            style="border-color: #00bdd6ff; border-width: 2px; width: 30%"
        ></v-divider>
        <p>{{ $t("account_index_lweek") }}</p>
        <span
        >$
          {{
            user?.last_week?.amount !== undefined ? user?.last_week?.amount : 0
          }}</span
        >
        <p class="percentage">
          <!-- <v-img
            v-if="
              user?.last_week?.percent != '--' && user?.last_week?.increase != 0
            "
            :src="
              user?.last_week?.increase > 0
                ? require('@/assets/trendUp.png')
                : require('@/assets/trendDown.png')
            "
            alt="Logo"
            max-height="18"
            max-width="18"
            class="icon blue-icon"
          ></v-img> -->
          <!-- <span
            :class="
              user?.last_week?.percent == '--'
                ? 'grey--text'
                : user?.last_week?.increase > 0
                ? 'blue--text'
                : 'red--text'
            "
            >{{ user?.last_week?.percent }}</span
          > -->
        </p>
      </div>

      <div class="dataBlock pa-4 pb-0 pt-0">
        <v-divider
            style="border-color: #8353e2ff; border-width: 2px; width: 30%"
        ></v-divider>
        <p>{{ $t("account_index_tweek") }}</p>
        <span
        >$
          {{
            user?.this_week?.amount !== undefined ? user?.this_week?.amount : 0
          }}</span
        >
        <p class="percentage">
          <!-- <v-img
            v-if="
              user?.this_week?.percent != '--' && user?.this_week?.increase != 0
            "
            :src="
              user?.this_week?.increase > 0
                ? require('@/assets/trendUp.png')
                : require('@/assets/trendDown.png')
            "
            alt="Logo"
            max-height="18"
            max-width="18"
            class="icon red-icon"
          ></v-img> -->
          <!-- <span
            :class="
              user?.this_week?.percent == '--'
                ? 'grey--text'
                : user?.this_week?.increase > 0
                ? 'blue--text'
                : 'red--text'
            "
            >{{ user?.this_week?.percent }}</span
          > -->
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import * as echarts from "echarts";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      chart: null,
      options: {
        tooltip: {
          trigger: "item",
        },
        title: {
          text: this.$t("account_index_affprogram"),
          textStyle: {
            fontSize: 18,
          },
        },
        grid: {
          top: 30,
        },
        toolbox: {
          show: true,
        },
        series: [
          {
            name: this.$t("account_index_affprogram"),
            type: "pie",
            radius: ["65%", "90%"],
            center: ["50%", "55%"],
            startAngle: 90,
            label: {
              show: false,
            },
            color: [
              "#4F90FE",
              "#4CD7A5",
              "#9FCD72",
              "#FF6652",
              "#FFCF52",
              "#67D5FF",
            ],
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  mounted() {
    const that = this
    that.$nextTick(() => {
      that.initData();
    });
  },
  watch: {
    user: {
      handler() {
        this.initData();
      },
      deep: true,
    },
  },
  methods: {
    initChart() {
      this.chart = echarts.init(
          document.getElementById("affiliateProgramChart")
      );
      this.chart.setOption(this.options);
    },
    defaultData() {
      this.options.series[0].data = [
        {value: 12, name: "1-tier"},
        {value: 25, name: "2-tier"},
        {value: 10, name: "3-tier"},
        {value: 6, name: "4-tier"},
        {value: 34, name: "5-tier"},
        {value: 9, name: "6-tier"},
      ]
      this.initChart();
    },
    initData() {
      if (this.user && JSON.stringify(this.user.levels) !== "{}") {
        this.options.series[0].data = [];
        for (let key in this.user.levels) {
          this.options.series[0].data.push({
            value: this.user.levels[key],
            name: key + "-tier",
          });
        }
        this.initChart();
      } else {
        this.defaultData()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chartBox {
  position: relative;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;

  #affiliateProgramChart {
    width: 100%;
    height: 260px;
  }

  .legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-top: 17px;

    > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .legend-icon {
        font-size: 14px;
        margin-right: 8px;
      }

      p {
        font-size: 14px;
        color: #333;
        margin: 0 8px 0 0;
        flex-grow: 1;
      }

      span {
        font-size: 14px;
        margin-left: auto;
      }
    }
  }

  .dataCont {
    margin-top: 15px;

    .dataBlock {
      text-align: left;
      flex: 0 0 150px;
      background: #f3f4f6;
      border-radius: 6px;
      border-width: 1px solid #bcc1ca;

      p {
        font-size: 14px;
        color: #333;
        margin: 6px 0 3px;
      }

      span {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }

      .percentage {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666;
        margin-top: 0;

        .icon {
          margin-right: 4px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
