import { post } from '@/utils/request.js';

const apiUrl = '/auth';


const login = async (data) => post({
  url: apiUrl, data: {
    cmd: 'user_login',
    params: data
  }
});
const register = async (data) => post({
  url: apiUrl, data: {
    cmd: 'user_register',
    params: data
  }
});
const userInfo = async () => post({
  url: apiUrl, data: {
    cmd: 'user_info'
  }
});
const logout = async (data) => post({
  url: apiUrl, data: {
    cmd: 'user_logout',
    params: data
  }
});
const changepsw = async (data) => post({
  url: apiUrl, data: {
    cmd: 'change_password',
    params: data
  }
});
const resetpsw = async (data) => post({
  url: apiUrl, data: {
    cmd: 'reset_password',
    params: data
  }
});
const getCookiesApi = async () => post( {
  url: '/auth/cookies'
});

const verifyEmail = async (data) => post({
    url: apiUrl, data: {
        cmd: 'vc_email',
        params: data
    }
});

const vcEmailVerify = async (data) => post({
  url: apiUrl, data: {
      cmd: 'vc_email_verify',
      params: data
  }
});

const icVerification = async (data) => post({
  url: apiUrl, data: {
      cmd: 'ic_verify',
      params: data
  }
});

export default {login,register,userInfo,logout,changepsw,resetpsw,getCookiesApi,verifyEmail,vcEmailVerify,icVerification}
