<template>
  <div class="account">
    <top-bars :title="$t('account_index_account_title')" :toHome="true">
    </top-bars>
    <div class="pa-4">
      <profile-card></profile-card>
       <chart></chart>
      <div class="bottomBox">
        <v-row justify="start">
          <v-col
            v-for="button in buttons"
            :key="button.name"
            cols="4"
            class="text-center"
          >
            <v-btn
              icon
              large
              class="mx-auto mb-2"
              color="#fff"
              @click="btnClick(button)"
            >
              <v-icon>{{ button.icon }}</v-icon>
            </v-btn>
            <div>{{ button.name }}</div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-navigation-drawer v-model="drawerbs" fixed left temporary>
      <div class="policy">
        <div class="top d-flex align-center">
          <div class="primary-text pl-4">
            <p class="fs-29 fw-700 lh-26">{{settingGlobal.title}}</p>
            <span class="body-1">{{settingGlobal.subtitle}}</span>
          </div>
        </div>
        <div class="link">
          <router-link
            class="d-flex align-center"
            :to="aICL(item.path)"
            v-for="item in policylink"
            :key="item.path"
          >
            <div class="mr-4">
              <v-img width="25" :src="item.img"></v-img>
            </div>
            <span>{{ item.name }}</span>
          </router-link>
        </div>
      </div>
    </v-navigation-drawer>
    <div class="ftrs" style="margin-top:80px;">
    <p v-if="user" class="text-center signout grey--text ptr">
      <span @click="signout">{{$t('account_index_sout')}}</span>
    </p>
    <p class="text-center version black--text">
      <span>{{$t('account_index_version')}} {{ settingGlobal.version }}</span>
    </p>

    </div>
  </div>
</template>

<script>
import ProfileCard from "@/components/ProfileCard.vue";
import TopBars from "@/components/TopBars.vue";
import Chart from "@/components/AccountCont/Chart.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import authApi from "@/api/auth.js";

export default {
  components: { TopBars, ProfileCard, Chart },
  inject: ["unReadCount"],
  data() {
    return {
      drawerbs: false,
      loading: false,
      inputValue: "",
      inputAdd: "",
      duration: "fifteen",
      select: "TRC-20",
      items: [
        { state: "USDT-TRC20", abbr: "TRC-20" },
        { state: "USDT-ERC20", abbr: "ERC-20" },
      ],
      buttons: [
        {
          key: "ad",
          name: this.$t('account_index_addrs'),
          icon: "mdi-home-outline",
          path: "address",
          restrict: true,
        },
        {
          key: "stmt",
          name: this.$t('account_index_stmt'),
          icon: "mdi-file-document-outline",
          path: "statement",
          restrict: true,
        },
        {
          key: "inv",
          name: this.$t('account_index_invite'),
          icon: "mdi-account-plus",
          path: "invite",
          restrict: true,
        },
        {
         key: "psw",
          name: this.$t('account_index_password'),
          icon: "mdi-lock-outline",
          path: "changepassword",
          restrict: true,
        },
        {
         key: "dwld",
          name: this.$t('account_index_dload'),
          icon: "mdi-download",
          path: "download",
        },
        { key: "pol", name: this.$t('account_index_policy'), icon: "mdi-city-variant-outline" },
      ],
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value % 10 == 0) || "",
      ],
      policylink: [
        {
          name: this.$t('account_index_intro'),
          path: "/policy/intro",
          img: require("@/assets/p1.jpg"),
        },
        {
          name: this.$t('account_index_fq'),
          path: "/policy/faq",
          img: require("@/assets/p2.jpg"),
        },
        {
          name: this.$t('account_index_privacy'),
          path: "/policy/privacy",
          img: require("@/assets/p3.jpg"),
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: "user/user",
      language: "language",
      settingGlobal: "settingGlobal",
    }),
    ...mapGetters(["invitationCode"]),
    unRead() {
      return this.unReadCount();
    },
  },

  mounted() {
    this.select = this.items[0].abbr;
  },
  methods: {
    ...mapActions({
      logOut: "user/logOut",
    }),
    ...mapMutations(["setSnackbar", "setLanguage"]),
    selectChange(v) {
      console.log(v);
    },
    changeLang(lan) {
      this.$i18n.locale = lan;
      this.setLanguage(lan);
    },

    //aICL = add invitation code to path || link
    async btnClick(item) {
      if (item.path) {
        let path = (!this.user && item.restrict)?'/login':item.path;

        this.$router.push({
            path: path,
            query: this.invitationCode ? { ic: this.invitationCode } : {},
          });

      } else if (item.key === "pol") {
        this.drawerbs = true;
      }
    },

    aICL(path) {
      return this.user || !this.invitationCode
        ? path
        : { path, query: { ic: this.invitationCode } };
    },

    async signout() {
      let res = await this.logOut();
      if (res) {
        this.$router.push("/home");
        this.setSnackbar({
          snackbarSuccess: true,
          snackbarMsg: "Sign out",
          snackbar: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  position: relative;
  min-height: calc(100vh + 50px);
  background-color: $background;

  a.active {
    position: relative;
  }
  .bottomBox {
    padding: 30px 0;
    p {
      &:nth-child(1) {
        border-bottom: 1px solid #1d2128ff;
        padding-bottom: 2px;
        margin-bottom: 5px;
      }
    }
    .v-btn {
      border-radius: 50%;
      height: 70px;
      width: 70px;
      background: $primary;
    }
  }
}
.policy {
  background-color: $background;
  padding-top: 35px;
  height: 100%;
  background-position-y: bottom;
  background-size: contain;
  .link {
    width: 100%;
    margin-top: 40%;
    a {
      color: $main-font-color;
      font-size: 24px;
      border-bottom: 1px solid #e5e5e5ff;
      padding: 9px 15px;
    }
  }
  .rectangle {
    padding: 30px 30px 30px 15px;
    background: #4069e5ff; /* tertiary1-500 */
    border-radius: 0px 89px 89px 0px;
    color: #fff;
    width: calc(100% - 40px);
    margin-top: 20%;
  }
  .cancle {
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: center;
    color: #dee1e6ff;
  }
}
.switchlan {
  background-color: $primary;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  padding: 3px;
  span {
    display: inline-block;
    padding: 0 5px;
    &.active {
      height: 24px;
      width: 24px;
      text-align: center;
      background-color: #fff;
      color: $primary;
      border-radius: 100%;
    }
  }
}
.ftrs {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

.signout, .version {
  margin-bottom: 20px;
}

</style>
