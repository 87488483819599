<template>
  <div class="bottomNavigation" :class="type">
    <span
      :to="`/${item.name}`"
      @click="change(item)"
      v-for="(item, index) in navItems"
      :key="index"
      class="rounded-sm ptr"
      :class="{ 'active-tab': activeTab === item.name }"
    >
      <span>
        <v-icon size="24">{{ item.icon }}</v-icon>
      </span>

      <span class="pagename fs-14">{{ item.label }}</span>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import orderApi from "@/api/order.js";
import Bus from '@/utils/bus'
export default {
  name: "bottomBar",
  props: {
    type: {
      default: "wihte",
      type: String,
    },
  },
  data() {
    return {
      orderDetail:null,
      activeTab: 0,
      ic: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    ...mapGetters(["invitationCode", "language"]),

    navItems() {
      return [
        { name: "home", label: this.$t('home_home'), icon: "mdi-home-outline" },
        { name: "offerhelp", label: this.$t('home_offer_help'), icon: "mdi-piggy-bank", restrict: true },
        { name: "gethelp", label: this.$t('home_get_help'), icon: "mdi-cube-outline", restrict: true },
        { name: "account", label: this.$t('home_account'), icon: "mdi-cog" },
      ];
    }
  },
  mounted() {
    let path1 = this.$route.path.split("/")[1];
    this.activeTab = path1.split("/")[0];
  },
  watch: {
    $route(to, from) {
      let path1 = to.path.split("/")[1];
      this.activeTab = path1.split("/")[0];
    },
    language(newLang) {
      console.log("Language changed to:", newLang);
    },
  },
  methods: {
    ...mapMutations(["setSnackbar"]),

       async qOrderApi() {
      let resOD = await orderApi.orderOngoing();
      if (resOD && resOD?.data) {
        this.orderDetail = resOD.data;
        console.log("Order details:", this.orderDetail);
      
    if (this.orderDetail.status === "matched") {
          this.$router.push({
            name: "deposit",
            query: { ...this.orderDetail },
          });
        } else if (this.orderDetail.status === "pending" || this.orderDetail.status === "passed") {
         if (this.$route.name !== 'offerhelpindex') {
            this.$router.push({
              name: "offerhelpindex"
            });
          }
        } else {
          console.log("No order found or status does not match.");
        }
      }else{
          this.$router.push({
              name: "offerhelpindex"
            });
      }
    },

    async change(item) {
      const targetPath = `/${item.name}`;
      const query = this.invitationCode ? { ic: this.invitationCode } : {};

      const newPath = item.restrict && !this.user ? "/login" : targetPath;

      if (item.name === "offerhelp") {
      if(!this.user){
        this.$router.push({name: 'login'});
        return;
      }else{
        //登录
       this.qOrderApi()
        return
      }
          
      }
      if (this.$route.path !== newPath || JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.activeTab = item.name;

        this.$router.replace({ path: newPath, query })
          .catch(err => {
            if (err.name !== "NavigationDuplicated") {
              console.error(err);
            }
          });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.bottomNavigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: $background;
  > span {
    color: $main-font-color;
    background-color: transparent !important;
    border: none !important;
    flex: 1;
    flex-direction: column;
    display: flex;
    text-align: center;
    padding: 10px 0;
    &.active-tab {
      color: $primary;
      i {
        color: $primary;
      }
    }
    span {
      flex: 1;
    }
  }
  &.light {
    background-color: transparent;
    > span {
      color: #fff;
      i{
        color: #fff;
      }
      &.active-tab {
        color: $primary;
        i {
          color: $primary;
        }
      }
    }
  }
}
</style>
