<template>
  <div class="codeBox">
    <input v-model="code" id="modeInput" type="text" style="opacity:0;"/>
    <div class="d-flex">
      <div
        @click="focusFun(index)"
        v-for="(num, index) in codeAry"
        :key="index"
        :class="{
          'code-input': true,
          'correct-border': (code?.length || 0) == index,
          'wrong-border': isWrong,
          focus: focusInput == index && isFocus,
        }"
      >
        <span>{{ num }}</span>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  props: ["isWrong", "resend"],
  data() {
    return {
      code: "",
      focusInput: 7,
      isFocus: false,
    };
  },
  computed: {
    codeAry() {
      let len = this.code?.length || 0;
      let ary = this.code ? this.code.split("") : [];
      ary = ary.concat(Array(6 - len).fill(""));
      return ary;
    },
  },
  mounted() {
    let self = this;
    let inputdom = document.getElementById("modeInput");
    inputdom.addEventListener("blur", () => {
      self.focusInput = 7;
      self.isFocus = false;
    });
    inputdom.addEventListener("focus", () => {
      self.isFocus = true;
    });
  },
  watch: {
    code() {
      if (this.code?.length == 6) {
        document.getElementById("modeInput").blur();
        this.focusInput = 7;
        this.$emit("codeFun", this.code);
      } else {
        this.focusInput = this.code?.length || 0;
      }
    },
    resend: {
      handler() {
        if (this.resend) {
          this.code = "";
          this.focusInput = 7;
          this.$emit("codeFun", this.code);
          
        }
      },
      deep: true,
    },
  },
  methods: {
    focusFun(index) {
      if (this.code?.length == 6 && this.isWrong) {
        document.getElementById("modeInput").focus();
        this.focusInput = 0;
        this.code = "";
        this.$emit("codeFun", this.code);
      } else if (index == (this.code?.length || 0)) {
        this.focusInput = index;
        document.getElementById("modeInput").focus();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.code-input {
  margin: 0 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #eee;
  overflow: hidden;
  position: relative;
  &.focus::before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 7px;
    left: 19px;
    height: 24px;
    width: 1px;
    background-color: #333;
    animation: focusani 1s infinite;
  }
}
@keyframes focusani {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.correct-border {
  border-color: #4069e5 !important;
  box-shadow: 0 0 6px #c1c1c1;
}

.wrong-border {
  border-color: #c12126ff !important;
}
</style>