<template>
  <div class="deposit">
    <div class="cardborder cardprimary pa-4 text-center">
      <div>
        <p class="d-flex">
        <span>{{$t('offerhelp_prin_txt')}} {{ $formatUS(orderDetail.amount,2) }}</span>
        <v-spacer></v-spacer>
        <span>Due {{ orderDetail.r_amount }}</span>
      </p>
      <p class="d-flex">
        <span>Total interest {{ orderDetail?.er && !isNaN(Number(orderDetail.er)) ? $formatUS(orderDetail.er) : "" }}</span>
        <v-spacer></v-spacer>
        <span>Daily interest {{ (orderDetail.rate * 100) }} %</span>
        <span></span>
      </p>
      <p class="d-flex">
        <span>{{$t('offerhelp_record_init_timestamp')}}</span>
        <v-spacer></v-spacer>
        <span>{{ $DateUS(orderDetail.create_time) }}</span>
      </p>
      <p class="d-flex">
        <span>{{$t('offerhelp_record_final_timestamp')}}</span>
        <v-spacer></v-spacer>
        <span>{{ $DateUS(orderDetail.close_time) }}</span>
      </p>
      <p class="d-flex">
        <span>Order Ref.</span>
        <v-spacer></v-spacer>
        <span class="text-ellipsis">{{ orderDetail._id }}</span>
        <v-icon
          v-if="orderDetail._id"
          class="ml-1"
          size="16"
          color="#fff"
          @click="copyText($event, orderDetail._id)"
        >
          mdi-content-copy
        </v-icon>
      </p>

        <v-divider
          v-if="orderDetail.status === 'matched' && orderDetail.w_info && orderDetail.w_info.length"
          style="border-color: #fff; border-width: 1px"
          class="my-1 mb-5"
        ></v-divider>

        <div v-for="(info, wIndex) in orderDetail.w_info" :key="wIndex" class="suborder">
          <p class="d-flex">
            <v-icon
              v-if="orderDetail.w_info && orderDetail.w_info.length > 1"
              class="mr-1"
              size="16"
              color="#fff"
            >
              mdi-radiobox-marked
            </v-icon>
            <span> 
            <span v-if="orderDetail.w_info && orderDetail.w_info.length > 1">{{ getOrdinalSuffix(wIndex + 1) }}
            </span> 
            payee info
            </span>
          </p>
          <p class="d-flex">
            <span>{{$t('offerhelp_deposit_id_txt')}} {{ info.w_uid }}</span>
            <v-spacer></v-spacer>
            <span>{{$t('offerhelp_deposit_amnt_txt')}} {{ $formatUS(info.amount,2) }}</span>
          </p>
          <p class="d-flex mb-2">
            <span>{{$t('offerhelp_deposit_email_txt')}}</span>
            <v-spacer></v-spacer>
            <span>{{ info.w_email }}</span>
            <v-icon
              class="ml-1"
              size="16"
              color="#fff"
              @click="copyText($event, info.w_email)"
              >mdi-content-copy</v-icon>
          </p>
          <p class="d-flex mb-2">
            <span>{{$t('offerhelp_deposit_net_txt')}}</span>
            <v-spacer></v-spacer>
            <span>{{ orderDetail.chain }}</span>
          </p>
          <p class="d-flex mb-2">
            <span>{{$t('offerhelp_deposit_pairsess')}}</span>
            <v-spacer></v-spacer>
            <span>{{ $DateUS(info.match_time) }}</span>
          </p>
          <p class="d-flex mb-2">
            <span>{{$t('offerhelp_deposit_addrs_txt')}}</span>
            <v-spacer></v-spacer>
            <span class="text-ellipsis" style="width: 180px">{{ info.w_addr }}</span>
            <v-icon
              class="ml-1"
              size="16"
              color="#fff"
              @click="copyText($event, info.w_addr)"
              >mdi-content-copy</v-icon>
          </p>
          <p class="d-flex">
            <span>Order ref.</span>
            <v-spacer></v-spacer>
            <span class="text-ellipsis">{{ info.w_id }}</span>
            <v-icon
              v-if="info.w_id"
              class="ml-1"
              size="16"
              color="#fff"
              @click="copyText($event, info.w_id)"
            >
              mdi-content-copy
            </v-icon>
          </p>

          <p class = "d-flex">
          <!-- Linear Progress -->
          <div class="pa-7">
            <count-down :status="info.d_status" v-if="info.count_down" :endTime="info.count_down" :linear="true"></count-down>
          </div>
          </p>

          <!-- Upload area with drag-and-drop and click-to-upload functionality -->
          <P>
            <div
              ref="dropZone"
              class="card ptre mb-2"
              @click="triggerFileInput(info.w_id, wIndex)"
              @drop.prevent="handleDrop($event, wIndex)"
              @dragover.prevent
            >
              <v-file-input
                v-model="info.uploadImg"
                accept="image/*"
                class="d-none"
                ref="fileInputs"
                @change="fileChange(wIndex)"
                show-size
              ></v-file-input>
                  <p class="body-1 black--text">Please upload an image of your transaction.</p>

              <div class="text-center uploadbox" :class="{ 'uploaded': (info.showImg || info.img) }">
                <div v-if="!(info.showImg || info.img)" class="upload-placeholder py-3">
                  <v-img class="mgu" width="80" :src="require('@/assets/upload.png')" />
                  <p class="fs-24 black--text">{{$t('offerhelp_upload_drp_img')}}</p>
                  <p class="body-1 black--text">{{$t('offerhelp_upload_supformat')}}</p>
                </div>
                <div v-else class="imgwrap d-flex align-center justify-center">
                  <auth-img max-width="350" max-height="184" :src="(info.showImg || info.img)" />
                </div>
              </div>
            </div>
          </p>
        <p>
      <v-btn 
        outlined 
        class="my-4 rounded-xxl w100 upload-button pa-4" 
        size="small"
        :loading="loadingStates[wIndex]"
        @click="submit(info.w_id, wIndex)" 
      > 
        {{ info.d_status === 'paid' ? 'Verified' : $t('gethelp_record_gethelp_record_confirm_button') }}
      </v-btn>
        </p>
        </div>

      </div>
    </div>
    <div class="txt mt-10">
      <p class="mb-6">
        {{$t('offerhelp_deposit_helptxt1')}}
      </p>
      <p>
        {{$t('offerhelp_deposit_helptxt2')}}
      </p>
    </div>
    <!--<p>
      <v-btn class="filledBtn" size="small" @click="submit"> {{$t('offerhelp_deposit_nxt_btn')}} </v-btn>
    </p>
    <div class="spcr"></div>-->
  </div>
</template>

<script>
import { copyText } from "@/utils/index.js";
import CountDown from "@/components/OfferHelp/CountDown.vue";
import { mapGetters, mapMutations } from "vuex";
import miscApi from "@/api/misc";
import orderApi from "@/api/order";
import Bus from '@/utils/bus'
import AuthImg from '@/components/AccountCont/authImg.vue';
export default {
  components: {
    CountDown,
    AuthImg,
  },
  data() {
    return {
      orderType: "deposit",
      uploadImg: [],
      loading: false,
      loadingStates: {},
      orderId: "",
      pay_time:"",
      orderDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      settingGlobal: "settingGlobal",
    }),
  },
   created() {
    // Retrieve orderId from sessionStorage when the component loads
    const savedOrderId = sessionStorage.getItem('orderId');
    if (savedOrderId) {
      this.orderId = savedOrderId;
    }
  },
  mounted() {
         Bus.$on("queryPage",(val)=>{
        if(val){
         this.checkOrderStatus()
        }
      })

   if (this.orderId) {
    this.checkOrderStatus();
  }
    this.orderDetail = { ...this.$route.query };
    this.orderId = this.orderDetail._id;
    this.setOrderId(this.orderId);
    this.pay_time = this.$route.query.pay_time;

    // Add event listeners for drag-and-drop functionality
    const dropZones = this.$refs.dropZone;

    if (dropZones && Array.isArray(dropZones)) {
      dropZones.forEach(dropZone => {
        dropZone.addEventListener("dragover", (e) => {
          e.stopPropagation();
          e.preventDefault();
          e.dataTransfer.dropEffect = "copy";
        });

        dropZone.addEventListener("drop", (e) => {
          e.stopPropagation();
          e.preventDefault();
          const files = e.dataTransfer.files;
          if (files.length > 0) {
            Array.from(files).forEach((file) => {
              this.uploadImg.push({
                file,
                url: this.getFileURL(file), // Store the file and its URL
              });
            });
          }
        });
      });
    }
  },
  watch: {},
  methods: {
    ...mapMutations(["setSnackbar"]),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    setOrderId(newOrderId) {
      this.orderId = newOrderId;
      sessionStorage.setItem('orderId', newOrderId);
    },
    clearOrderId() {
      this.orderId = null;
      sessionStorage.removeItem('orderId');
    },

  //刷新当前页面
      async checkOrderStatus() {
      let resOD = await orderApi.orderOngoing();
      
      if (resOD && resOD?.data) {
        this.orderDetail = resOD.data;
      }
    },

    copyText(e, text) {
      copyText(e, text);
    },
    getFileURL(file) {
      return URL.createObjectURL(file);
    },
    triggerFileInput(w_id, wIndex) {
      const selectedInfo = this.orderDetail.w_info.find(info => info.w_id === w_id);

      if (selectedInfo.d_status === "paid") {
        this.notiFy(false, "This image of your transaction has been submitted already");
        return;
      } else {
        this.$refs.fileInputs[wIndex].$el.querySelector("input[type='file']").click();
      }
    },
    handleDrop(event, wIndex) {
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith("image/")) {
        this.$set(this.orderDetail.w_info[wIndex], "uploadImg", file);
        this.$set(this.orderDetail.w_info[wIndex], "showImg", this.getFileURL(file));
      }
    },

    fileChange(wIndex) {
      const fileInput = this.$refs.fileInputs[wIndex].$el.querySelector("input[type='file']");
      const file = fileInput.files[0];

      if (file && file.type.startsWith("image/")) {
        this.$set(this.orderDetail.w_info[wIndex], "uploadImg", file);
        this.$set(this.orderDetail.w_info[wIndex], "showImg", this.getFileURL(file));
      }

      // Reset the file input value to allow re-selection of the same file
      fileInput.value = "";
    },

    // Method to check if all sub-orders have been paid
    areAllSubOrdersPaid() {
      return this.orderDetail.w_info.every(info => info.d_status === "paid");
    },

    getOrdinalSuffix(number) {
      const remainder10 = number % 10;
      const remainder100 = number % 100;

      if (remainder10 === 1 && remainder100 !== 11) {
        return `${number}st`;
      } else if (remainder10 === 2 && remainder100 !== 12) {
        return `${number}nd`;
      } else if (remainder10 === 3 && remainder100 !== 13) {
        return `${number}rd`;
      } else {
        return `${number}th`;
      }
    },
    async submit(w_id, wIndex) {
      // Check if all sub-orders have an uploaded image
      const incompleteUploads = this.orderDetail.w_info.some(info => info.d_status !== "paid");
      let remAmt = this.orderDetail.r_amount;

      const selectedInfo = this.orderDetail.w_info.find(info => info.w_id === w_id);

      if (!selectedInfo || !selectedInfo.uploadImg && selectedInfo.d_status !== "paid") {
        this.notiFy(false, this.$t("offerhelp_img_proofwarn"));
        return;
      }
      if (selectedInfo.d_status === "paid") {
        this.notiFy(false, "This order is already verified.");
        return;
      }

      this.$set(this.loadingStates, wIndex, true);

      let formdata = new FormData();
      formdata.append("_id", this.orderId);
      formdata.append("w_id", w_id);
      formdata.append("cmd", "deposit_pay");
      formdata.append("img", selectedInfo.uploadImg);

      try {
        let res = await miscApi.depositPay(formdata);
        this.$set(this.loadingStates, wIndex, false);

        if (res) {
          const updatedInfo = this.orderDetail.w_info.find(info => info.w_id === w_id);
          if (updatedInfo) {
            updatedInfo.d_status = "paid"; // Mark as verified
          }
          this.notiFy(true, this.$t("offerhelp_depo_success"));
          this.checkOrderStatus()

          // Check if all sub-orders are confirmed
          if (this.areAllSubOrdersPaid() && remAmt === 0) {
            this.$router.push({ path: "record" });
            this.clearOrderId();
          } else {
            console.log("There are incomplete orders or pending amount.");
          }
        }
      } catch (err) {
        console.error("Upload failed", err);
        this.$set(this.loadingStates, wIndex, false);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.deposit {
  background-color: $background;  
  min-height: calc(100vh + 50px);
    .cardprimary {
    div {
      p {
        margin-bottom: 8px;
        .card {
    border-radius: 6px;
    background-color: #fff;
    padding: 10px;
    text-align: center;
  }

  .upload-placeholder {
    .mgu {
      margin-bottom: 20px;
    }
  }

  .imgwrap {
    justify-content: center;
  }

  .button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .upload-button {
    background-color: transparent !important;
    color: white;
  }
  .confbtn {
    margin-top: 55px;
  }

  .des {
    margin-top: 40px;
  }
      }
    }
  }


.text-ellipsis {
  display: inline-block; 
  max-width: 150px; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
}
</style>
