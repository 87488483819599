import io from 'socket.io-client';
import config from './config';
import store from '../store';

const SocketPlugin = {
    install(Vue) {
        Vue.prototype.$socket = null;
    },
    init(socketCookie) {
        if (!socketCookie) {
            console.warn("No socket cookie provided. Socket connection not initialized.");
            return null;  // Returning null if no socket cookie
        }

        const socket = io(config.wsURL, {
            autoConnect: true,
            transports: ['websocket'], // 仅使用 WebSocket
            query: {
                session: socketCookie
            },
        });

        socket.on('connect', () => {
            console.log('Socket.IO connected');

            // const engine = socket.io.engine;
            // console.log(engine.transport.name); // in most cases, prints "polling"
            //
            // engine.once("upgrade", () => {
            //     // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
            //     console.log(engine.transport.name); // in most cases, prints "websocket"
            // });
            //
            // engine.on("packet", ({ type, data }) => {
            //     // called for each packet received
            //     console.log("type: ", type, "data: ", data);
            // });
            //
            // engine.on("packetCreate", ({ type, data }) => {
            //     // called for each packet sent
            //     console.log("type: ", type, "data: ", data);
            // });
            //
            // engine.on("drain", () => {
            //     // called when the write buffer is drained
            //     console.log('drain');
            // });
            //
            // engine.on("close", (reason) => {
            //     // called when the underlying connection is closed
            //     console.log("type: ", reason);
            // });
        });

        socket.on('message', (data) => {
            console.log('Message from server:', data);
        });

        socket.on('refresh', (data) => {
            console.log('refresh:', data.data);
            store.commit('setRefreshEvent', data.data);
        });

        socket.on('error', (error) => {
            console.error('Socket.IO Error:', error);
        });

        socket.on('disconnect', (reason) => {
            console.log('Socket.IO disconnected:', reason);
        });

        return socket;
    }
};

export default SocketPlugin;
