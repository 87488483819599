<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <top-bars :title="$t('reset_chk_email')"></top-bars>
    <v-main>
      <v-container>
        <!-- Top Section with Lock Icon -->
        <v-row no-gutters class="align-center justify-center">
          <v-col cols="12" class="d-flex flex-column align-center">
            <div class="circle mb-4 mt-5">
              <v-row no-gutters class="align-center justify-center">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <div>
                    <v-img
                      width="90"
                      :src="require('@/assets/email.png')"
                      class="lock white-icon"
                      contain
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <!-- Verification Code Input Fields -->
        <div class="form-wrapper pa-4">
          <v-form ref="form">
            <v-row no-gutters class="d-flex align-center justify-center">
              <input-code :isWrong="isWrong" @codeFun="codeFun" :resend="resend"></input-code>
            </v-row>
            <div class="spcr"></div>
            <!-- Resend Button -->
            <v-row no-gutters class="mt-8 mb-4">
              <v-col cols="12">
                <v-btn
                  large
                  class="py-6 body-1 w100 white--text rounded-xxl"
                  height="44px"
                  color="#4b82ed"
                  :loading="loading"
                  @click="resendClick"
                >
                  {{$t('reset_resend_code_btn')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import TopBars from "../components/TopBars.vue";
import authApi from "@/api/auth";
import { mapMutations, mapActions } from "vuex";
import InputCode from "@/components/InputCode.vue";
export default {
  components: { TopBars, InputCode },
  data() {
    return {
      loading: false,
      isWrong: false,
      resend:false
    };
  },

  mounted() {},
  methods: {
    ...mapMutations(["setSnackbar"]),
    codeFun(code) {
      if(code){
        this.submit(code);
      }else{
        this.isWrong = false;
        this.resend = false;
      }

    },

    async resendClick() {
      this.loading = true;
      this.resend = true;
      let res = await authApi.verifyEmail({
        email: this.$route.query.email,
        _type: "password",
      });
      this.loading = false;
      if (res) {
        this.setSnackbar({
          snackbarSuccess: true,
          snackbarMsg: "Verification code is sent successfully",
          snackbar: true,
        });
      }
    },
    async submit(code) {
      this.resend = false;
      let res = await authApi.vcEmailVerify({
        vc: code,
        email: this.$route.query.email,
        _type: "password",
      });

      if (res) {
        this.isWrong = false;
        this.$router.push({
          path: "/reset/createNewPassword",
          query: { vc: code, email: this.$route.query.email },
        });
      } else {
        this.isWrong = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.circle {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #3d6beb;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.lock {
  height: 110px;
  width: 110px;
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10px;
  color: #171a1fff;

  .inmail {
    color: #4b82ed;
  }
}

.form-wrapper {
  background: #f2f3f4ff;
  height: 50vh;
}

.code-input {
  margin: 0 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #eee;
  overflow: hidden;
}

.v-text-field {
  padding: 0;
  margin: 0;
  border: none;
  transform: translateY(3px);
}

.v-text-field__slot {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

:deep(.v-text-field__slot input) {
  text-align: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-bottom: none;
  outline: none;
}

.correct-border {
  border-color: #4069e5 !important;
  box-shadow: 0 0 6px #c1c1c1;
}

.wrong-border {
  border-color: #c12126ff !important;
}

.error-text {
  color: #c12126ff;
  display: flex;
  align-items: center;
}
</style>
