<template>
  <v-app :style="{ background: $vuetify.theme.themes.dark.background }">
    <v-main>
      <router-view class="global-router"></router-view>
      <BottomBars />
    </v-main>

  </v-app>
</template>

<script>
import BottomBars from "@/components/BottomBars.vue";
export default {
  name: "Layout",
  components: {BottomBars},
  data:() => ({
  }),
  methods:{},
  watch: {},
};
</script>

<style>


</style>
