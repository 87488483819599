<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <top-bars :title="$t('reset_forg_psw_title')"></top-bars>
    <v-main>
      <v-container>
        <v-row no-gutters class="align-center justify-center">
          <v-col cols="12" class="d-flex flex-column align-center">
            <div class="circle mb-4 mt-5">
              <v-row no-gutters class="align-center justify-center">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <div>
                    <v-img width="70" :src="require('@/assets/lock.png')" class="white-icon" contain></v-img>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <!-- Form section -->
        <div class="form-wrapper pa-4">
           <v-form v-model="valid" ref="form">
             <!-- Email Login -->
             <v-row no-gutters>
               <v-col cols="12">
                 <v-text-field
                   v-model="email"
                   :placeholder="$t('reset_forg_email')"
                   :rules="emailRules"
                   class="fs-15 fw-400"
                 ></v-text-field>
               </v-col>
             </v-row>

             <div class="spcr"></div>
             <!-- Send Code Button -->
             <v-row no-gutters class="mt-8 mb-4">
               <v-col cols="12">
                 <v-btn
                   large
                   class="py-6 body-1 w100 white--text rounded-xxl"
                   height="44px"
                   color="#4b82ed"
                   @click="submit"
                   :loading="loading"
                 >
                   {{$t('reset_forg_sendcode')}}
                 </v-btn>
               </v-col>
             </v-row>
          </v-form>
       </div>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopBars from '../components/TopBars.vue';
import authApi from "@/api/auth";
import { mapMutations, mapActions } from "vuex";
export default {
  components: { TopBars },
  data() {
    return {
      email: '',
      valid: false,
      loading: false,
      emailRules: [
        (v) => !!v || this.$t('reset_forg_email_required'),
        (v) => /.+@.+\..+/.test(v) || this.$t('reset_forg_email_valid'),
      ],
    };
  },
  async mounted() {
  if (this.$route.query.email) {
    this.email = this.$route.query.email;
    console.log(this.email);
  }
},
  methods: {
    ...mapMutations(["setSnackbar"]),
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let res = await authApi.verifyEmail({
          email: this.email,
          _type:'password'
        });
        this.loading = false;
        if(res){
          this.setSnackbar({
            snackbarSuccess: true,
            snackbarMsg: "Verification code is sent successfully",
            snackbar: true,
          });
          this.$router.push({path:'/reset/checkmail',query:{email:this.email}});
        }


      }
    }
  },
};
</script>

<style scoped lang="scss">
.circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #3d6beb;
  display: flex;
  align-items: center;
  justify-content: center;
}


.text-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10px;
  color: #171A1FFF;
}

.form-wrapper {
  background: #f2f3f4ff;
  height: 50vh;
}

:deep(.v-text-field .v-input__control .v-input__slot::before) {
  border-bottom: 2px solid transparent !important;
}

:deep(.v-text-field.v-input--is-focused .v-input__control .v-input__slot::before) {
  border-bottom: 2px solid #000 !important;
}
</style>
