import Vue from "vue";

const config = {
    // apiURL: '/web/api',
    // wsURL: 'http://192.168.8.107:1337',
    // wsURL: 'http://43.242.202.240:11338',
    wsURL: 'wss://mlmc-vip.com/socket.io',

};

Vue.prototype.$config = config;

export default config;
