<template>
  <div class="invite-page">
    <top-bars :title="$t('account_invite_invite_title')" :light="false"></top-bars>

    <div class="invite-content">
      <!-- Card Content -->
      <div class="invite-card">
        <v-img
          :src="require('@/assets/invFrnd.png')"
          alt="Logo"
          height="135px"
          class="invite-image"
        ></v-img>
        <p class="invite-code">{{ user ? user.ic : "" }}</p>
        <p class="fs-14 fw-400 mt-5">
          {{$t('account_invite_invite_des')}}
        </p>
        <v-btn
          outlined
          color="primary"
          class="copy-btn rounded-xl"
          @click="copyCode('code')"
        >
          {{$t('account_invite_share')}}
        </v-btn>
      </div>

      <div class="text-left">
        <p class="mt-4 mb-3">{{$t('account_invite_copy_link')}}</p>
        <p class="link">
          <v-icon size="20">mdi-link-variant</v-icon>
          <span>{{ invitationLink }}</span>
        </p>
      </div>
      <v-btn
        color="primary"
        size="small"
        class="share-btn filledBtn"
        @click="copyCode('link')"
      >
        {{$t('account_invite_copy')}}
      </v-btn>
    </div>
    <div class="shadebox" v-show="copySuccess" @click="copySuccess = false">
      <v-btn outlined color="#fff" class="copy2-btn rounded-xl">
        {{$t('account_invite_copy_overlay')}}
      </v-btn>
    </div>

  </div>
</template>

<script>
import TopBars from "@/components/TopBars.vue";
import { mapMutations, mapGetters } from "vuex";

export default {
  components: { TopBars },
  data() {
    return {
      copySuccess: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    invitationLink() {
      const main_domain = process.env.VUE_APP_BASE_URL;
      const code = this.user ? this.user.ic : '';
      const domain = main_domain.endsWith('/') ? main_domain.slice(0, -1) : main_domain;
      return `${domain}/home?ic=${code}`;
    },
  },
  methods: {
    ...mapMutations(["setSnackbar"]),

    copyCode(type) {
      let textToCopy = '';
      if (type === 'code') {
        textToCopy = this.user ? this.user.ic : '';
      } else if (type === 'link') {
        textToCopy = this.invitationLink;
      }

      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          this.copySuccess = true;
          setTimeout(() => {
            this.copySuccess = false;
          }, 1000);
        } else {
          throw new Error("Copy failed");
        }
      } catch (err) {
        console.error("Failed to copy:", err);
        this.setSnackbar({
          snackbarSuccess: false,
          snackbar: true,
          snackbarMsg: "Failed to copy.",
        });
      } finally {
        document.body.removeChild(textarea);
      }
    },
  },
};
</script>




<style lang="scss" scoped>
.invite-page {
  background-color: $background;
  padding: 0 !important;
  overflow: hidden;
  height: calc(100vh - 50px);

  .invite-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $background;
    padding-top: 16px;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 50%;
      background-color: $primary;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .invite-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: $background;
      border-radius: 12px;
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
      width: 80%;
      max-width: 400px;
      text-align: center;
      padding: 24px;
      z-index: 1;
      position: relative;
      padding-bottom: 40px;
      .invite-image {
        max-width: 150px;
        margin: 0 auto;
      }

      .invite-code {
        font-size: 24px;
        font-weight: bold;
        color: #4069e5;
        margin-top: 16px;
        border: 1px dashed #6c757d;
        padding: 8px;
        border-radius: 8px;
        width: 153px;
        height: 53px;
      }

      .copy-btn {
        width: 173px;
        margin-top: 16px;
      }
    }

    .share-btn {
      width: 80%;
      max-width: 400px;
      margin-top: 70px;
      border-radius: 25px;
      z-index: 1;
      position: relative;
    }

    .copy2-btn {
      width: 170px;
      margin-top: 70px;
      border-radius: 25px;
      z-index: 1;
      position: relative;
    }
  }
}
.text-left {
  width: 80%;
  max-width: 400px;
  .link {
    display: inline-block;
    border: 1px solid $primary;
    border-radius: 8px;
    width: 100%;
    padding: 7px 5px;
    color: $primary;
    i {
      margin-right: 5px;
      color: $primary;
    }
  }
}
.shadebox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
