<template>
  <v-app>
    <v-overlay v-if = "startLoading" value="true" absolute z-index="9999" class="loading-overlay">
      <v-progress-circular indeterminate size="40" color="primary"></v-progress-circular>
    </v-overlay>

    <transition :name="transitionName" mode="in-out" appear>
      <router-view class="global-router"></router-view>
    </transition>
    <snackbar ref="comsnackbar"></snackbar>
    <fab-button :bottom="fabBottom" :right="fabRight" v-if="fabPosition" />
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";
import FabButton from "@/components/FAB.vue";
import authApi from "@/api/auth";
import userApi from "@/api/user.js";
import orderApi from "@/api/order.js";
import settingApi from "@/api/setting";
import { mapActions, mapGetters, mapMutations } from "vuex";

import Bus from '@/utils/bus'

export default {
  name: "App",
  components: { Snackbar, FabButton },
  data() {
    return {
      transitionName: "fade",
      unReadCount: 0,
      startLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "fabPosition",
      "fabVisibility",
      "refreshEvent",
      "settingGlobal",
    ]),
    ...mapGetters({
      user: "user/user",
    }),
    fabBottom() {
      return this.fabPosition ? this.fabPosition.bottom : "16px";
    },
    fabRight() {
      return this.fabPosition ? this.fabPosition.right : "16px";
    },
  },

  created() {
    // redirect
    const sessionUser = sessionStorage.getItem('user');
    if (sessionUser) {
          if (this.$route.path === "/login") {
            this.$router.replace('/home');
          }
    }
  },
  async mounted() {

    let res = await settingApi.settingGlobal();
    if (res) {
      this.setSettingGlobal(res.data);
      this.setLanguage('en');
    }

    document.title = "MLMC";
    document.title = this.settingGlobal.title || "MLMC";
    let link = document.querySelector("#dynamic-favicon");
    link.href = this.settingGlobal.logo;

    this.updateFabSettings(this.$route);
    if (!this.$route.meta.white) {
      await this.getInit();
      await this.getUnreadApi();
    }
    Bus.$on("unreadmsg", async (val) => {
      if (val) {
        console.log("Unread Msg Event received with value:", val);
          await this.getUnreadApi();
      }
    });

  },
  watch: {
    async $route(to, from) {
      if (!from.meta.pageNum) {
        this.transitionName = "fade";
      } else {
        this.transitionName =
          to.meta.pageNum > from.meta.pageNum
            ? "left"
            : to.meta.pageNum < from.meta.pageNum
            ? "right"
            : "fade";
      }

      this.updateFabSettings(to);
    },
    async refreshEvent() {
      console.log(this.refreshEvent);
      if (this.refreshEvent && this.refreshEvent.length) {
        if (this.refreshEvent.indexOf("message_unread_count") > -1) {
          await this.getUnreadApi();
        }else if(this.refreshEvent.indexOf("stat_deposit") > -1){
          //存款页刷新图表数据
           if (this.$route.name === "offerhelpindex") {
              //存款页面监听
               Bus.$emit("stat_deposit",'12')
            }
        } else if (this.refreshEvent.indexOf("user_info") > -1) {
          await this.getInfo();
        } else if (this.refreshEvent.indexOf("balance_details") > -1) {
            //资金明细页面实时监听
          
            if(this.$route.name === "statement"){
               Bus.$emit("querybill",'10')
            }
            
        }
         else if (this.refreshEvent.indexOf("user_offline") > -1) {
          let res = await this.getInfo();
        } else if (this.refreshEvent.indexOf("query_orders") > -1) {
   
            if (this.$route.name === "gethelprecord") {
              //取款watting页面监听
                Bus.$emit("queryList",'1')
            }

            if (this.$route.name === "offerhelpindex") {
              //存款页面监听
              await this.qOrderApi();
            }
               if (this.$route.name === "deposit") {
              //上传存款图片页面监听
                Bus.$emit("queryPage",'11');
            }
          }
        this.setRefreshEvent(null);
      }
    },
    settingGlobal() {
      document.title = this.settingGlobal.title || "INVES";
      let link = document.querySelector("#dynamic-favicon");
      link.href = this.settingGlobal.logo;
    },
    async user() {
      if (this.user) {
        await this.getUnreadApi();
        // Store user session in sessionStorage when authenticated
        sessionStorage.setItem('user', JSON.stringify(this.user));
      } else {
        // Clear user session from sessionStorage if logged out
        sessionStorage.removeItem('user');
      }
    },
  },
  methods: {
    ...mapMutations([
      "setFabPosition",
      "setSettingGlobal",
      "setSnackbar",
      "setRefreshEvent",
      "setLanguage"
    ]),
    ...mapActions({
      getInfo: "user/getInfo",
    }),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    async getInit() {
      let res = await this.getInfo();
    },
    async getUnreadApi() {
      let res = await userApi.messageUnreadCount();
      if (res.data) {
        this.unReadCount = res.data.unread;
        console.log("Unread msg:", this.unReadCount);
      }
    },
    async qOrderApi() {
      let resOD = await orderApi.orderOngoing();
      if (resOD && resOD?.data) {
        this.orderDetail = resOD.data;
        console.log("Order details:", this.orderDetail);
        this.startLoading=false;
    if (this.orderDetail.status === "matched") {
          this.$router.push({
            name: "deposit",
            query: { ...this.orderDetail },
          });
        } else if (this.orderDetail.status === "pending" || this.orderDetail.status === "passed") {
         if (this.$route.name !== 'offerhelpindex') {
            this.$router.push({
              name: "offerhelpindex"
            });
          }
        } else {
          console.log("No order found or status does not match.");
        }
      }else if(resOD?.data === null){
        this.startLoading=false
         if (this.$route.name !== 'offerhelpindex') {
          this.startLoading = false; 
          this.$router.push({
            name: 'offerhelpindex'
          });
          console.log('No order found.');
        }
      }
    },
    updateFabSettings(to) {
      if (
        to.name == "home" ||
        to.name == "offerhelpindex" ||
        to.name == "offerHelpRecord" ||
        to.name == "gethelprecord"
      ) {
        this.setFabPosition({ bottom: "65px", right: "16px" });
      } else {
        this.setFabPosition({ bottom: "16px", right: "16px" });
      }
    },
  },
  provide() {
    return {
      unReadCount: () => this.unReadCount,
    };
  },
};
</script>

<style scoped>
.loading-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: all;
}

.loading-overlay > .v-progress-circular {
  pointer-events: none;
}
::v-deep .ql-align-center{
  text-align: center !important;
}
</style>
