<template>
  <div class="countDown">
    <!-- Custom SVG Progress Bar -->
    <svg width="100%" height="10">
      <rect
        width="100%"
        height="100%"
        rx="5"
        fill="#e0e0e0"
      ></rect>
      <rect
        :width="progress + '%'"
        height="100%" 
        rx="5" 
        fill="#76c7c0"
      ></rect>
    </svg>
    <div class="timebox no-wrap">
      <p class="fs-16" v-if="countDownH != '00'">{{ countDownH }}h {{ countDownM }}m {{ countDownS }}s left</p>
      <p class="fs-16" v-if="countDownH == '00' && countDownM != '00'">{{ countDownM }}m {{ countDownS }}s left</p>
      <p class="fs-16" v-if="countDownH == '00' && countDownM == '00'">{{ countDownS }}s left</p>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
export default {
  props: {
    status:{
      type:String,
      require:false,
      default:''
    },
    endTime: {
      type: Array,
      required: false,
      default:[],
    },
    linear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      progress: 0,
      countDownH: "00",
      countDownM: "00",
      countDownS: "00",
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      const totalTime = 72 * 60 * 60; // total time in seconds
      let remaining = totalTime;

      if (this.endTime.length) {
        remaining =
          (new Date(this.endTime[1]).getTime() - new Date(this.endTime[0]).getTime()) / 1000;
      }

      const updateTime = () => {
        const h = String(Math.floor(remaining / 3600)).padStart(2, "0");
        const m = String(Math.floor((remaining % 3600) / 60)).padStart(2, "0");
        const s = String(Math.floor((remaining % 3600) % 60)).padStart(2, "0");

        this.countDownH = h;
        this.countDownM = m;
        this.countDownS = s;
        this.progress = ((totalTime - remaining) / totalTime) * 100;
      };

      updateTime();
   

      this.refreshData = setInterval(() => {
        remaining--;
        updateTime();

        if (remaining <= 0) {
          clearInterval(this.refreshData);
          this.countDownH = "00";
          this.countDownM = "00";
          this.countDownS = "00";
          this.progress = 100;
     
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.countDown {
  position: relative;

  .timebox {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -130%);
    span {
      margin: 0 10px;
    }
  }
}
</style>
