<template>
  <v-card
    :color="$vuetify.theme.themes.light.primary"
    class="pa-4 mb-5 rounded-lg"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center mr-0 d-flex mb-0 pb-3 align-center"
        v-if="userInfoShow > 0"
      >
        <div>
          <v-avatar size="40">
            <span class="userIcon">
              <v-icon color="#fff" size="30">mdi-account</v-icon>
            </span>
          </v-avatar>
        </div>

        <div class="text-left ml-3 white--text">
          <p class="body-2">ID {{ user?._id }}</p>
          <p class="body-2" v-if="user">{{ user?.email }}</p>
        </div>
      </v-col>
      <v-col cols="6" class="text-left white--text" v-if="userInfoShow < 2">
        <p class="body-2 mb-1">{{$t('account_index_tcommission')}}</p>
        <p class="fs-20">
          {{
            user?.t_commission !== undefined
              ? $formatUS(user?.t_commission, 2)
              : "0.00"
          }}
        </p>
      </v-col>
      <v-col cols="6" class="text-left white--text" v-if="userInfoShow < 3">
        <p class="body-2 mb-1">{{$t('account_index_ybalance')}}</p>
        <p class="fs-20">
          {{
            user?.balance !== undefined ? $formatUS(user?.balance, 2) : "0.00"
          }}
        </p>
      </v-col>
      <v-col cols="6" class="text-left white--text" v-if="userInfoShow == 3">
        <p class="body-2 mb-1">{{$t("offerhelp_toffer")}}</p>
        <p class="fs-20">
          {{
            userOffer?.offer !== undefined
              ? $formatUS(userOffer?.offer, 2)
              : "0.00"
          }}
        </p>
      </v-col>
      <v-col cols="6" class="text-left white--text" v-if="userInfoShow == 3">
        <p class="body-2 mb-1">{{$t("offerhelp_tearnings")}}</p>
        <p class="fs-20">
          {{
            userOffer?.earning !== undefined ? $formatUS(userOffer?.earning, 2) : "0.00"
          }}
        </p>
      </v-col>
      <v-col
        cols="6"
        class="text-left white--text pb-1"
        v-if="userInfoShow == 0"
      >
        <p class="body-2 mb-1">{{$t('account_index_hcount')}}</p>
        <p class="fs-20">{{ user ? user?.headCount : 0 }}</p>
      </v-col>
      <v-col
        cols="6"
        class="text-left white--text pb-1"
        v-if="userInfoShow == 0"
      >
        <p class="body-2 mb-1">{{$t('account_index_ftier')}}</p>
        <p class="fs-20">
          {{
            user && JSON.stringify(user.levels) !== "{}" && user.levels["1"]
              ? user.levels["1"]
              : 0
          }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "profileCard",
  data() {
    return {
      userInfoShow: 1,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      userOffer: "user/userOffer",
    }),
  },
  mounted() {
    if (this.$route.name == "offerhelpindex") {
        this.userInfoShow = 3;
      } else if (this.$route.name == "accoumtindex") {
        this.userInfoShow = 0;
      } else if (this.$route.name == "gethelpindex") {
        this.userInfoShow = 2;
      } else if (this.$route.name == "home") {
        this.userInfoShow = 1;
      }
  },
  watch: {
    $route() {
      if (this.$route.name == "offerhelpindex") {
        this.userInfoShow = 3;
      } else if (this.$route.name == "accoumtindex") {
        this.userInfoShow = 0;
      } else if (this.$route.name == "gethelpindex") {
        this.userInfoShow = 2;
      } else if (this.$route.name == "home") {
        this.userInfoShow = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.userIcon {
  i {
    padding: 2px;
    border: 2px solid #fff;
    border-radius: 100%;
  }
}
</style>
