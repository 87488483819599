import Vue from "vue";
import VueI18n from "vue-i18n";
import Messages from '@/i18n/messages.json'
import store from "@/store";

Vue.use(VueI18n);
console.log(store.state.language)
export const i18n = new VueI18n({
  locale: store.state.language || 'en',
  messages: Messages
});

export default i18n;
