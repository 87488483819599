<template>
  <div class="uploadPage">
    <!-- SubTitle -->
    <div class="mx-4 mb-4">
      <p class="text-center subtitle-2">
        {{$t('offerhelp_upload_upld_subtitle')}}
      </p>
    </div>
    <div class="card ptre" @click="triggerFileInput">
      <!-- Hidden file input triggerable by the Upload button -->
      <v-file-input
        v-model="uploadImg"
        accept="image/*"
        class="d-none"
        ref="fileInput"
        @change="fileChange"
        show-size
      ></v-file-input>

      <div class="text-center uploadbox" id="dropZone">
        <!-- Placeholder content hidden when image is uploaded -->
        <div
          v-if="!showImg"
          class="upload-placeholder py-3"
        >
          <v-img class="mgu" width="80" :src="require('@/assets/upload.jpg')" />
          <p class="fs-24">{{$t('offerhelp_upload_drp_img')}}</p>
          <p class="body-1">{{$t('offerhelp_upload_supformat')}}</p>
        </div>

        <!-- IMG here -->
        <div
          v-if="showImg"
          class="imgwrap d-flex align-center justify-center"
        >
          <auth-img max-width="350" max-height="184" :src="showImg" />
        </div>
      </div>
    </div>

    <p class="mt-8">
      {{$t('offerhelp_upload_upld_helptxt1')}}
    </p>

    <!-- confirm Button -->
    <v-btn
      class="filledBtn mt-10"
      size="small"
      :loading="loading"
      @click="submit"
    >
      {{$t('account_address_confirm_btn')}}
    </v-btn>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import miscApi from "@/api/misc";
import authImg from '@/components/AccountCont/authImg.vue';
export default {
  components: { authImg },
  data() {
    return {
      uploadImg: null,
      showImg: "",
      loading: false,
      orderId: "",
    };
  },
  mounted() {
    let self = this;
    this.orderId = this.$route.query.orderId;
    document
      .getElementById("dropZone")
      .addEventListener("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
      });

    document.getElementById("dropZone").addEventListener("drop", function (e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      self.uploadImg = files[0];
      self.showImg = self.getFileURL(self.uploadImg);
    });



  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    fileChange() {
      if (this.uploadImg) {
        this.showImg = this.getFileURL(this.uploadImg);

      }
    },
    getFileURL(file) {
      let getUrl = null;
      if (window.createObjectURL !== undefined) {
        getUrl = window.createObjectURL(file);
      } else if (window.URL !== undefined) {
        getUrl = window.URL.createObjectURL(file);
      } else if (window.webkitURL !== undefined) {
        getUrl = window.webkitURL.createObjectURL(file);
      }
      return getUrl;
    },
    cancelUpload() {
      this.uploadImg = null;
      this.showImg = "";
      this.$refs.fileInput.reset();
    },
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    async submit() {
      if (!this.uploadImg) {
        this.notiFy(false, this.$t('offerhelp_img_proofwarn'));
        return;
      }
      let data = {
        o_id: this.orderId,
        cmd: "deposit_pay",
        img: this.uploadImg,
      };
      let formdata = new FormData();
      for (let key in data) {
        formdata.append(key, data[key]);
      }
      this.loading = true;
      let res = await miscApi.depositPay(formdata);
      this.loading = false;
      if (res) {
        this.notiFy(true, this.$t('offerhelp_depo_success'));
        this.$router.push({ path: "record" });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.uploadPage {
  background-color: $background;
  .card {
    border-radius: 6px;
    border: 2px dashed #bcc1ca;
    padding: 10px;
    text-align: center;
  }

  .upload-placeholder {
    .mgu {
      margin-bottom: 20px;
    }
  }

  .imgwrap {
    justify-content: center;
  }

  .button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .upload-button {
    background-color: #2962ff;
    color: white;
  }
  .confbtn {
    margin-top: 55px;
  }

  .des {
    margin-top: 40px;
  }
}
</style>
