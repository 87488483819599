<template>
  <div class="verfyEmail">
    <top-bars title="" :backClick="true" @backFun="backFun"></top-bars>
    <v-main>
      <v-container>
        <!-- Top Section with Lock Icon -->
        <v-row no-gutters class="align-center justify-center">
          <v-col cols="12" class="d-flex flex-column align-center">
            <v-row no-gutters class="align-center justify-center">
              <v-col cols="12" class="d-flex align-center justify-center">
                <div>
                  <v-img
                    :src="require('@/assets/jet.png')"
                    class="jet"
                    contain
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Title -->
        <div class="text-section mx-4">
          <p class="text-center fs-32 lh-48 ff-ep fw-700">Verify your email</p>
        </div>


        <!-- Verification Code Input Fields -->
        <div class="form-wrapper pa-4 mt-5">
          <v-form ref="form">
            <v-row no-gutters class="align-center justify-center">
              <input-code
                :isWrong="isWrong"
                @codeFun="codeFun"
                :resend="resend"
              ></input-code>
            </v-row>


            <div class="spcr"></div>
            <!-- Resend Button -->
            <v-row no-gutters class="mt-8 mb-4">
              <v-col cols="12">
                <v-btn
                  large
                  class="py-6 body-1 w100 white--text rounded-xxl"
                  height="44px"
                  color="#4b82ed"
                  :loading="loading"
                  @click="resendClick"
                >
                  Resend the Code
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import TopBars from "../components/TopBars.vue";
import authApi from "@/api/auth";
import userApi from "@/api/user";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { watch } from "vue";
import InputCode from "@/components/InputCode.vue";
export default {
  components: { TopBars, InputCode },
  data() {
    return {
      isWrong: false,
      resend: false,
      loading: false,
    };
  },
  props: ["userinfo"],
  computed: {
    ...mapGetters({
      user: "user/user",
      settingGlobal: "settingGlobal",
    }),
    isFullyEntered() {
      return this.verificationCode.every((digit) => digit !== "");
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(["setSnackbar", "setUser"]),
    ...mapActions({
      register: "user/register",
    }),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    codeFun(code) {
      if (code) {
        this.submit(code);
      } else {
        this.isWrong = false;
        this.resend = false;
      }
    },
    backFun() {
      this.$emit("backFun");
    },

    async resendClick() {
      console.log(this.userinfo);
      this.resend = true;
      this.loading = true;
      
      let email = this.userinfo.email.replace(/^([^@]+)/, match => match.toLowerCase());
      console.log(email);
      
      let res = await authApi.verifyEmail({
        email,
        _type: "register",
      });
      
      this.loading = false;
      
      if (res) {
        if (res.data.msg) {
          this.notiFy(true, res.data.msg);    
        } else if (res.data.error) {
          this.notiFy(true, res.data.error);    
        }
      }
    },
    async submit(code) {
      this.resend = false;
      this.isWrong = false;

      try {
        let email = this.userinfo.email.replace(/^([^@]+)/, match => match.toLowerCase());

        let res = await this.register({
          ...this.userinfo,
          email, //email with lowercase username
          vc: code,
        });

        console.log("Response object:", res);

        if (res && res.data) {
          console.log(res.data);

          if (res.data.msg) {
            this.notiFy(true, res.data.msg);
            console.log("User Info:", res.data.info);

            this.$router.push("/login");
          } else if (res.data.error) {
            this.notiFy(true, res.data.error); 
            this.isWrong = true;   
        }
        } else {
          this.isWrong = true;
        }
      } catch (error) {
        this.isWrong = true;
        console.log(error);
      }
    },
},
};
</script>
<style scoped lang="scss">
.verfyEmail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $background;
  z-index: 999;
}
.circle {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #3d6beb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px;
}

.jet {
  height: 160px;
  width: 160px;
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10px;
  color: #171a1fff;

  .inmail {
    color: #4b82ed;
  }
}

.form-wrapper {
  background: #f2f3f4ff;
  height: 50vh;
}

.code-input {
  margin: 0 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid $primary;
}

.v-text-field {
  padding: 0;
  margin: 0;
  border: none;
  transform: translateY(3px);
}

.v-text-field__slot {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

:deep(.v-text-field__slot input) {
  text-align: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-bottom: none;
  outline: none;
}

.correct-border {
  border-color: #4069e5 !important;
}

.wrong-border {
  border-color: #c12126ff !important;
}

.error-text {
  color: #c12126ff;
  display: flex;
  align-items: center;
}
</style>
