<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <top-bars :title="$t('offerhelp_offerhelp_title')" :showBack="false"> </top-bars>
    <v-main>
      <div class="record pa-4">
        <div class="tagslidegroup mb-4">
          <v-slide-group v-model="tagactive">
            <v-slide-item
              v-for="(item, index) in tagAry"
              :key="index"
              v-slot="{ active, toggle }"
            >
              <v-btn depressed @click="toggle" :class="active ? 'active' : ''">
                {{ item.label }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </div>
        <div id="scrolldiv">
          <div>
            <div
              v-for="(item, index) in dataList"
              :key="index"
              :class="[
                'cardprimary mb-4 pa-3',
                (item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid')
                  ? (index % 2 === 0 ? 'bluebg' : 'whitebg')
                  : 'greybg'
              ]"
            >
              <ul>
                <li class="d-flex">
                  <span>{{$t('offerhelp_prin_txt')}} {{ $formatUS(item.amount,2) }}</span>
                  <v-spacer></v-spacer>
                  <span>{{$t('offerhelp_dr_txt')}}:{{ item.rate * 100 }}%</span>
                </li>
                <li class="d-flex">
                  <span>Total interest {{item?.er && !isNaN(Number(item?.er)) ? $formatUS(item?.er) : "" }}</span>
                  <v-spacer></v-spacer>
                  <span></span>
                </li>
                <li class="d-flex">
                  <span>{{$t('offerhelp_ord_ref')}}.</span>
                  <v-spacer></v-spacer>
                  <span class="text-ellipsis">
                  {{ item._id }}
                  </span>
                  <v-icon
                    v-if="item._id"
                    class="ml-1"
                    size="16"
                    :color="(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                              ? (index % 2 === 0 ? '#fff' : '#ccc') 
                              : '#ccc'"
                    @click="copyText($event, item._id)"
                  >
                    mdi-content-copy
                  </v-icon>
                </li>
                <li class="d-flex">
                  <span>{{$t('offerhelp_record_init_timestamp')}}</span>
                  <v-spacer></v-spacer>
                  <span>{{ item.create_time }}</span>
                </li>
                <li class="d-flex">
                  <span>{{$t('offerhelp_record_final_timestamp')}}</span>
                  <v-spacer></v-spacer>
                  <span>{{ item.close_time }}</span>
                </li>
         
                <v-divider 
                  v-if="['paid', 'settled', 'revoked'].includes(item.status) && item.w_info && item.w_info.length" 
                  :style="{borderColor: item.status === 'revoked' ? '#ccc' : '#fff', borderWidth: '1px'}" 
                  class="my-1">
                </v-divider>

                <!-- Check if w_info exists for the item and render it if it does -->
                <li v-if="['paid', 'settled', 'revoked'].includes(item.status) && item.w_info && item.w_info.length" class="mb-2">
                  <div v-for="(info, wIndex) in item.w_info" :key="wIndex" class="suborder">
                    <ul>
                      <li class="d-flex">
                      <v-icon
                        v-if="item.w_info && item.w_info.length > 1"
                        class="mr-1"
                        size="16"
                        :color="(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                                  ? (index % 2 === 0 ? '#fff' : '#ccc') 
                                  : '#ccc'"
                      >
                        mdi-radiobox-marked
                      </v-icon>
                        <span>
                        <span v-if="item.w_info && item.w_info.length > 1">
                        {{ getOrdinalSuffix(wIndex + 1) }}
                        </span> 
                        payee info
                        </span>
                      </li>
                      <li class="d-flex">
                        <span class = "mr-1">ID</span>
                        <span>{{info.w_uid}}</span>
                        <v-spacer></v-spacer>
                        <span>Amount {{ $formatUS(info.amount,2)}}</span>
                      </li>
                      <li class="d-flex">
                        <span>Email</span>
                        <v-spacer></v-spacer>
                        <span>{{ info.w_email }}</span>
                        <v-icon
                          v-if="info.w_email"
                          class="ml-1"
                          size="16"
                          :color="(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                                    ? (index % 2 === 0 ? '#fff' : '#ccc') 
                                    : '#ccc'"
                          @click="copyText($event, info.w_email)"
                        >
                          mdi-content-copy
                        </v-icon>
                      </li>
                      <li class="d-flex">
                        <span>Network</span>
                        <v-spacer></v-spacer>
                        <span>{{ item.chain }}</span>
                      </li>
                      <li class="d-flex">
                        <span>Pairing session</span>
                        <v-spacer></v-spacer>
                        <span>{{ $DateUS(info.match_time) }}</span>
                      </li>
                      <li class="d-flex">
                        <span>Address</span>
                        <v-spacer></v-spacer>
                        <span class="text-ellipsis">{{ info.w_addr }}</span>
                        <v-icon
                          v-if="info.w_addr"
                          class="ml-1"
                          size="16"
                          :color="(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                                    ? (index % 2 === 0 ? '#fff' : '#ccc') 
                                    : '#ccc'"
                          @click="copyText($event, info.w_addr)"
                        >
                          mdi-content-copy
                        </v-icon>
                      </li>
                      <li class="d-flex">
                        <span>Order ref.</span>
                        <v-spacer></v-spacer>
                        <span class="text-ellipsis">{{ info.w_id }}</span>
                        <v-icon
                          v-if="info.w_id"
                          class="ml-1"
                          size="16"
                          :color="(item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') 
                                    ? (index % 2 === 0 ? '#fff' : '#ccc') 
                                    : '#ccc'"
                          @click="copyText($event, info.w_id)"
                        >
                          mdi-content-copy
                        </v-icon>
                      </li>
                      <!--img below-->
                      <li
                      v-if="info.img" 
                      class="imgwrap d-flex align-center justify-center">
                        <div class="card w100 mt-5" @click="zoomImg(info)">
                          <div class="text-center uploadbox">
                            <div
                              class="imgwrap d-flex align-center justify-center"
                            >
                              <auth-img max-width="190px" max-height="208px" :src="info.img" />
                          </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <v-btn  size="small"
                      :class="['rounded-xxl my-4 w100 pa-4', (item.status === 'pending' || item.status === 'passed' || item.status === 'matched' || item.status === 'paid') ? (index % 2 === 0 ? 'blbg white--text' : 'whtbg primary--text') : 'whtbg primary--text' ]"
                      > 
                        {{ (info.d_status === 'paid' || info.img)  ? 'Verified' : $t('gethelp_record_gethelp_record_confirm_button') }}
                         </v-btn>
                      </li>

                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <nodata
            :loading="loading"
            :scroll="scroll"
            :dataList="dataList"
            bottom="80"
          ></nodata>

        <div class="zoom" v-if="showZoomOverlay" @click="toggleCloseOverlay">
          <div class="cancelbox" @click.stop.prevent>
            <div class="cardprimary cancelOverlay">
              <v-container fluid fill-height>
                <v-row align="center" justify="center" class="text-center">
                  <v-col cols="12">
                    <div v-if="zmdImg">
                      <auth-img :src="zmdImg" height="auto" :style="{ maxHeight: '80vh', borderRadius: '5px' }" />
                    </div>
                    <!--<div v-else>
                      <p>Payment not done</p>
                    </div>-->
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>

        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mixins } from "@/mixin/scroll.js";
import Nodata from "../../components/Nodata.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import TopBars from "@/components/TopBars.vue";
import orderApi from "@/api/order";
import { copyText } from "@/utils/index.js";
import transproof1 from "@/assets/profile.png";
import transproof2 from "@/assets/perks.png";
import transproof3 from "@/assets/pep.jpeg";
import AuthImg from '@/components/AccountCont/authImg.vue';

export default {
  components: { Nodata, TopBars, AuthImg },
  mixins: [mixins],
  data() {
    return {
      orderType: "deposit",
      tagAry: [
        { label: this.$t('offerhelp_record_offerhelp_tab1'), key: "paid" },
        { label: this.$t('offerhelp_record_offerhelp_tab2'), key: "settled" },
        { label: this.$t('offerhelp_record_offerhelp_tab3'), key: "revoked" },
      ],
      lIndex: null,
      loading: false,
      zmdImg: '',
      showZoomOverlay: false,
      dataList: [],
    };
  },
  computed: {
    ...mapGetters({
      settingGlobal: "settingGlobal",
      user: "user/user",
    }),
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },
    getOrdinalSuffix(number) {
      const remainder10 = number % 10;
      const remainder100 = number % 100;

      if (remainder10 === 1 && remainder100 !== 11) {
        return `${number}st`;
      } else if (remainder10 === 2 && remainder100 !== 12) {
        return `${number}nd`;
      } else if (remainder10 === 3 && remainder100 !== 13) {
        return `${number}rd`;
      } else {
        return `${number}th`;
      }
  },
    toggleCloseOverlay() {
      this.showZoomOverlay = !this.showZoomOverlay;
    },
    zoomImg(info) {
    console.log("info received:", info);
    if (info && info.img) {
      this.zmdImg = info.img;
      this.showZoomOverlay = true;
      console.log("Zoom image set to:", this.zmdImg); 
    } else {
      console.log("No image data or invalid image data");
    }
  },
    copyText(e, text) {
      copyText(e, text);
    },
    async cancle(item, index) {
      const getId = item._id;
      let data = {
        _id: getId,
      };
      this.lIndex = index;
      try {
        let res = await orderApi.depositRevoke(data);
        if (res) {
          this.notiFy(true, "Order Cancelled.");
          this.dataList = this.dataList.filter((order) => order._id !== getId);
          if (this.dataList.length == this.limit - 1) {
            this.getList();
          }
        }
      } catch (error) {
        this.notiFy(false, "Failed to cancel the order.");
      } finally {
        this.lIndex = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.record {
  min-height: 100%;
  background-color: $background;
  #scrolldiv {
    height: calc(100vh - 185px);
  }
  .cardprimary {
    ul {
      li {
        margin-bottom: 8px;
      }
    }
  }

  .bluebg {
    background-color: #4069e5;
    color: #fff;
    i {
      color: #fff;
    }
  }
  .whitebg {
    background-color: $background;
    color: #000;
    border: 1px solid #bcc1caff;
    i {
      color: $main-font-color;
    }
  }
  .whtbg {
    background-color: transparent !important;
    border: 1px solid $primary
  }
  .blbg {
    background-color: transparent !important;
    border: 1px solid $background
  }
.text-ellipsis {
  display: inline-block; 
  max-width: 150px; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card {
    border-radius: 6px;
    border: 2px solid #bcc1ca;
    padding: 10px;
    text-align: center;
  }

 .imgwrap {
    justify-content: center;
  }
 .zoom {
    width: 100vw;
    height: 100vh;
    background-color: #171a1fd1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    p {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .text {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      line-height: 30px;
      .cancel {
        color: $grey-icon-color;
        margin-top: 100px;
      }
    }
    .cancelbox {
      width: 85%;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      .cancelOverlay {
        background-color: transparent;
      }
      p {
        padding-bottom: 5px;
      }
    }
  }
}
</style>
