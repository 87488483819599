<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-app-bar app dense flat color="#3d6beb">
      <v-btn icon @click="moveBack">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main :style="{ background: $vuetify.theme.themes.light.background }">
      <v-row no-gutters class="align-center justify-center">
        <v-col cols="12" class="d-flex flex-column align-center">
          <div class="containerTri">
            <v-row no-gutters class="align-center justify-center">
              <v-col cols="12" class="d-flex align-center justify-center">
                <div class="combined-section">
                  <div class="text-section">
                    <h1 class="white--text text-center fs-29 fw-700">
                      {{ $t("login_login_title") }}
                    </h1>
                    <p class="white--text text-center fs-16 ff-ep fw-400" style="height:24px;">
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="triangle">
              <img src="@/assets/triangle.png" alt="Triangle" />
              <h1 class="login-text fs-33 fw-700">
                {{ $t("login_login_title") }}
              </h1>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Form section  -->
      <div class="form-wrapper pa-4">
        <v-form v-model="valid" ref="form">
          <!-- Email Login -->
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                :rules="emailRules"
                v-model="email"
                :placeholder="$t('login_log_email')"
                class="fs-15 fw-400"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Password Field -->
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                :placeholder="$t('account_index_password')"
                :rules="passwordRules"
                :type="passwordVisible ? 'text' : 'password'"
                class="fs-15 fw-400"
                color="primary"
              >
                <template v-slot:append>
                  <v-icon @click="togglePasswordVisibility">
                    {{ passwordVisible ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- forget password -->
          <v-row no-gutters class="mt-1">
            <v-col cols="12">
              <p class="fw-700 fs-15">
               <router-link :to="{ path: '/reset', query: { email: email } }" class="grey-text float-left"> 
                  {{ $t("login_log_forgot_psw") }}
                </router-link>

              </p>
            </v-col>
          </v-row>

          <!-- Login Button -->
          <v-row no-gutters class="mt-8 mb-4">
            <v-col cols="12">
              <v-btn
                large
                class="py-6 body-1 w100 rounded-xxl"
                style="color: #fff"
                height="44px"
                color="#4b82ed"
                @click="submit"
                :loading="loading"
              >
                {{ $t("login_login_btn") }}
              </v-btn>
            </v-col>
          </v-row>

          <!-- create account -->
          <v-row no-gutters class="mt-1 align-center">
            <v-col cols="12" class="d-flex justify-center">
              <p class="text-forgpass mb-0 fs-15 fw-400">
                {{ $t("login_log_noaccount") }}
                <router-link
                  :to="
                    invitationCode
                      ? { path: '/register', query: { ic: invitationCode } }
                      : '/register'
                  "
                  class="grey-text ml-2 fw-700 fs-15"
                >
                  {{ $t("login_log_create") }}
                </router-link>
              </p>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import orderApi from "@/api/order";
import userApi from "@/api/user";

export default {
  data() {
    return {
      email: "",
      password: "",
      ic: "",
      passwordVisible: false,
      valid: false,
      loading: false,
      emailRules: [
        (v) => !!v || this.$t("login_log_email_required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("login_log_email_valid"),
      ],
      passwordRules: [
        (v) => !!v || this.$t("login_log_psw_required"),
        (v) => v.length >= 8 || this.$t("login_log_psw_rule"),
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      settingGlobal: "settingGlobal",
    }),
    ...mapGetters(["invitationCode"]),
  },
  async mounted() {
  if (this.$route.query.mul === "1") {
    console.log("Multiple devices trying to log in");
  } else if (this.user) {
    this.$router.replace("/home");
    console.log("Logged in: Redirecting...");
  }
},

  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions({
      login: "user/login",
      getInfo: "user/getInfo",
      logOut: "user/logOut",
    }),
    notiFy(status, message) {
      this.setSnackbar({
        snackbarSuccess: status,
        snackbar: true,
        snackbarMsg: message,
      });
    },

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    //extMsg = extractMessage
    extMsg(errorMessage) {
      const regex = /[\/:{}]/;
      const index = errorMessage.search(regex);
      return index === -1 ? errorMessage : errorMessage.substring(index + 1).trim();
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          let res = await this.login({
            email: this.email,
            password: this.password,
          });


          if (res) {
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            let vTz = this.settingGlobal.timezones && this.settingGlobal.timezones[timeZone]
              ? timeZone
              : '';
              console.log(vTz);

            if (vTz) {
              await userApi.changeTz({ tz: vTz });
            }
            const locale = localStorage.getItem("inveslocale");
              if (locale) {
                this.$i18n.locale = locale;
              }
            this.$router.push("/home");
          }
        } catch (error) {
          const message = this.extMsg(error.message);
          console.log(message);
          //this.notiFy(false, message);
        } finally {
          this.loading = false;
        }
      }
    },

    moveBack() {
      this.$router.push('/home');
    },
  },
};
</script>

<style scoped lang="scss">
.containerTri {
  position: relative;
  width: 100%;
  background-color: #3d6beb;
  overflow: hidden;
}

.triangle {
  position: relative;
  width: 100%;
  height: auto;
  display: block;
}

.triangle img {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 20%;
}

.combined-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 54px;
  width: 54px;
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 10px;
}

.login-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #171a1fff;
}

.form-wrapper {
  background: #f2f3f4ff;
  height: 50vh;
}

:deep(.v-text-field .v-input__control .v-input__slot::before) {
  border-bottom: 2px solid transparent !important;
}

:deep(
    .v-text-field.v-input--is-focused .v-input__control .v-input__slot::before
  ) {
  border-bottom: 2px solid #000 !important;
}
</style>
