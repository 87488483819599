<template>
  <div class="chartBox pa-4" :class="shade ? 'chartBoxShade' : ''">
    <div id="youroffer"></div>
   
      <span class="chartSelect noneborder mt-4">This month</span>
   
    <!-- <v-select
      class="chartSelect noneborder mt-3"
      v-model="select"
      :items="items"
      label=""
      item-text="state"
      item-value="key"
      @change="selectFun"
    ></v-select> -->
    <div class="centerText">
      <p class="tl">{{$t('offerhelp_prgrsstxt')}}</p>
      <p class="num">
        {{ chartdata[0] }}/
        {{ chartdata[0] + chartdata[1] }}
      </p>
    </div>
    <div class="lenged">
    <div>
      <p>{{$t('offerhelp_cmplt_txt')}}</p>
      <span>{{ completedPer }}</span>
    </div>
    <div>
      <p>{{$t('offerhelp_inprog_txt')}}</p>
      <span>{{ inProgressPer }}</span>
    </div>

    </div>
  </div>
</template>

  <script >
import * as echarts from "echarts";
const color = ["#4069E5", "#00bdd6"];
import orderApi from "@/api/order";
import { mapMutations } from "vuex";
import Bus from '@/utils/bus'
export default {
  data() {
    return {
      chartdata: [0,0],
      chart: null,
      shade: true,
      select: "last_m0",
      completedPer: "0%",
      inProgressPer: "0%",
      items: [
        { state: "This month", key: "last_m0" },
        // { state: "Last 1 month", key: "last_m1" },
        // { state: "Last 2 months", key: "last_m2" },
        // { state: "Last 3 months", key: "last_m3" },
      ],

      options: {
        // tooltip: {
        //   trigger: "item",
        // },
        title: {
          text: this.$t('offerhelp_yoffer'),
          textStyle: {
            fontSize: 18,
          },
        },
        legend: {
          bottom: "5%",
          left: "center",
          itemWidth: 12,
          itemHeight: 12,
          show: false,
          data: [
            { icon: "circle", name: "Completed" },
            { icon: "circle", name: "In Progress" },
          ],
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["75%", "100%"],
            center: ["50%", "70%"],
            startAngle: 180,
            endAngle: 360,
            label: {
              show: false,
              position: "center",
            },
            color: [color[0], color[1]],

            data: [
              { value: 1048, name: "Completed" },
              { value: 735, name: "In Progress" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.initChart();
    this.getData();
        Bus.$on("stat_deposit",(val)=>{
        if(val){
           this.getData();
        }
      })
  },
  methods: {
    ...mapMutations({
      setUserOffer: "user/setUserOffer",
    }),
    initChart() {
      this.chart = echarts.init(document.getElementById("youroffer"));
      this.chart.setOption(this.options);
    },
    async getData() {
      let res = await orderApi.statDeposit({ period: this.select });
      if (res) {
        console.log(res);
        const completed = res.data.completed || 0;
        const in_progress = res.data.in_progress || 0;

        // If both values are zero, set default values for display purposes
        if (completed === 0 && in_progress === 0) {
          this.chartdata = [1, 1];
          this.shade = true; 
        } else {
          this.shade = false;
          this.chartdata = [completed, in_progress];
        }

        this.completedPer = res.data.completed_per || "0%";
        this.inProgressPer = res.data.in_progress_per || "0%";

        this.setUserOffer({
          earning: res.data.earning || 0,
          offer: res.data.offer || 0,
        });
        this.options.series[0].data = this.chartdata;
        this.chart.setOption(this.options);
      }
    },
    selectFun() {
      console.log(this.select);
      this.getData();
    },
  },
};
</script>

  <style lang="scss" scoped>
#youroffer {
  width: 100%;
  height: 270px;
}
.chartBox {
  position: relative;
  border: 1px solid #bcc1caff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px #171a17;
  .lenged {
    display: flex;
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    padding: 0 30px;
    > div {
      flex: 1;
      text-align: center;
      p {
        font-size: 14px;
        &::before {
          display: inline-block;
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 100%;
          margin-right: 10px;
        }
      }
      &:nth-child(1) {
        p::before {
          background-color: $primary;
        }
      }
      &:nth-child(2) {
        p {
          color: $img-blue;
          &::before {
            background-color: $img-blue;
          }
        }
      }
      span {
        font-size: 20px;
        margin-left: 30px;
        font-weight: bold;
      }
    }
  }
  .chartSelect {
    position: absolute;
    top: 0;
    right: 10px;
    margin-top: 0;
    padding-top: 0;
    width: 120px;
    font-size: 14px;
  }
  .centerText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .tl {
      font-size: 14px;
      color: $grey-icon-color;
      margin-bottom: 5px;
    }
    .num {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
